import React from "react";

const SpecializationsIcon = (props) => (
  <svg {...props} className={"icon"} width="92" height="99" viewBox="0 0 92 99" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M37.974 92V92C29.9049 92 23.3636 85.4587 23.3636 77.3896V12.4675C23.3636 8.34333 20.0203 5 15.8961 5V5H12H59.3117C68.1482 5 75.3117 12.1634 75.3117 21V81.0444M37.974 92H76.2826C82.2017 92 87 87.2017 87 81.2826V81.2826C87 81.1511 86.8934 81.0444 86.7618 81.0444H75.3117M37.974 92V92C41.2017 92 43.8182 89.3835 43.8182 86.1558V81.224C43.8182 81.1249 43.8986 81.0444 43.9978 81.0444H75.3117M34.4026 19.5H45.7662M34.4026 28.2H45.7662M34.4026 36.5778H45.7662M34.4026 44.6333H64.2727M34.4026 53.6556H64.2727M34.4026 62.0333H64.2727M34.4026 70.4111H64.2727" stroke="var(--svgColor)" strokeWidth="3" strokeLinecap="round" />
    <path d="M12.8478 5V5C8.51359 5 5 8.51359 5 12.8478V15H24" stroke="var(--svgColor)" strokeWidth="3" />
    <path d="M54.1623 33.5562L56.5335 28.5705C56.6993 28.222 57.0507 28 57.4366 28H61.105C61.5131 28 61.8803 28.248 62.0327 28.6267L64.0527 33.6461C64.3916 34.4882 63.5237 35.3098 62.7015 34.9253L59.5598 33.4564C59.2972 33.3337 58.9942 33.3309 58.7294 33.4488L55.4722 34.8992C54.6237 35.277 53.7634 34.3949 54.1623 33.5562Z" fill="#FF4040" />
    <path d="M65.5568 23.0381C65.5568 26.5961 62.6725 29.4803 59.1146 29.4803C55.5566 29.4803 52.6724 26.5961 52.6724 23.0381C52.6724 19.4802 55.5566 16.5959 59.1146 16.5959C62.6725 16.5959 65.5568 19.4802 65.5568 23.0381Z" fill="#FF4040" />
  </svg>
);
export default SpecializationsIcon;
