import React from "react";

const UnionIcon = (props) => (
    <svg {...props} className={"icon"} width="94" height="106" viewBox="0 0 94 106" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.60088 13.8965H37.3991C38.3037 13.8965 39.037 14.6299 39.037 15.5345C39.037 16.4391 38.3037 17.1724 37.3991 17.1724H9.60088C8.69628 17.1724 7.96295 16.4391 7.96295 15.5345C7.96295 14.6299 8.69628 13.8965 9.60088 13.8965Z" fill="#FF592C" stroke="#E5E5E5" strokeWidth="2" />
        <path d="M34.2385 17.6421C34.1809 18.8966 33.9079 20.1326 33.4306 21.2948C32.8897 22.6117 32.0971 23.8077 31.0984 24.8148C30.0998 25.8218 28.9147 26.6202 27.6111 27.1648C26.3075 27.7093 24.9105 27.9895 23.5 27.9895C22.0895 27.9895 20.6925 27.7093 19.3889 27.1648C18.0853 26.6202 16.9002 25.8218 15.9016 24.8148C14.9029 23.8077 14.1103 22.6117 13.5694 21.2948C13.0921 20.1326 12.8191 18.8966 12.7615 17.6421H34.2385Z" stroke="#E5E5E5" strokeWidth="2" />
        <path d="M23.4996 1H23.5C26.633 1 29.6386 2.24971 31.8553 4.47556C34.0721 6.70154 35.3182 9.7215 35.3182 12.8713V13.4947H11.6818L11.6818 12.8713L11.6818 12.8709C11.6812 11.3112 11.9867 9.76686 12.5807 8.32603C13.1747 6.88521 14.0456 5.57632 15.1433 4.47407C16.241 3.37184 17.544 2.49784 18.9778 1.90182C20.4115 1.3058 21.9481 0.999388 23.4996 1Z" fill="#FF592C" stroke="#E5E5E5" strokeWidth="2" />
        <path d="M23.5 31.4827C28.053 31.4827 32.4391 33.4542 35.6873 36.9963C38.7228 40.3063 40.5278 44.7326 40.7537 49.4138H6.24633C6.47218 44.7326 8.27717 40.3063 11.3127 36.9963C14.5609 33.4542 18.947 31.4827 23.5 31.4827Z" stroke="#E5E5E5" strokeWidth="2" />
        <rect x="19.5833" y="5.71106" width="0.87037" height="4.9917" rx="0.435185" fill="#747474" stroke="#E5E5E5" strokeWidth="0.87037" />
        <rect x="23.0648" y="5.71106" width="0.870368" height="4.9917" rx="0.435184" fill="#747474" stroke="#E5E5E5" strokeWidth="0.870368" />
        <rect x="26.5463" y="5.71106" width="0.87037" height="4.9917" rx="0.435185" fill="#747474" stroke="#E5E5E5" strokeWidth="0.87037" />
        <path d="M23.5 86.4827C28.053 86.4827 32.4391 88.4542 35.6873 91.9963C38.7228 95.3063 40.5278 99.7326 40.7537 104.414H6.24633C6.47218 99.7326 8.27717 95.3063 11.3127 91.9963C14.5609 88.4542 18.947 86.4827 23.5 86.4827Z" fill="#FF4040" stroke="#E5E5E5" strokeWidth="2" />
        <circle cx="23.5" cy="71.5" r="11.5" fill="#FF4040" stroke="#E5E5E5" strokeWidth="2" />
        <path d="M70.5 31.4827C75.053 31.4827 79.4391 33.4542 82.6873 36.9963C85.7228 40.3063 87.5278 44.7326 87.7537 49.4138H53.2463C53.4722 44.7326 55.2772 40.3063 58.3127 36.9963C61.5609 33.4542 65.947 31.4827 70.5 31.4827Z" stroke="#E5E5E5" strokeWidth="2" />
        <circle cx="70.5" cy="16.5" r="11.5" stroke="#E5E5E5" strokeWidth="2" />
        <path d="M56.6009 68.8965H84.3991C85.3037 68.8965 86.037 69.6299 86.037 70.5345C86.037 71.4391 85.3037 72.1724 84.3991 72.1724H56.6009C55.6963 72.1724 54.963 71.4391 54.963 70.5345C54.963 69.6299 55.6963 68.8965 56.6009 68.8965Z" fill="#F5A506" stroke="#E5E5E5" strokeWidth="2" />
        <path d="M81.2385 73C81.1809 74.2545 80.9079 75.4905 80.4306 76.6527C79.8897 77.9696 79.0971 79.1656 78.0984 80.1727C77.0998 81.1797 75.9147 81.9781 74.6111 82.5227C73.3075 83.0672 71.9105 83.3474 70.5 83.3474C69.0895 83.3474 67.6925 83.0672 66.3889 82.5227C65.0853 81.9781 63.9002 81.1797 62.9016 80.1727C61.9029 79.1656 61.1103 77.9696 60.5694 76.6527C60.0921 75.4905 59.8191 74.2545 59.7615 73H81.2385Z" stroke="#E5E5E5" strokeWidth="2" />
        <path d="M70.4996 56H70.5C73.633 56 76.6386 57.2497 78.8553 59.4756C81.0721 61.7015 82.3182 64.7215 82.3182 67.8713V68.4947H58.6818L58.6818 67.8713L58.6818 67.8709C58.6812 66.3112 58.9867 64.7669 59.5807 63.326C60.1747 61.8852 61.0456 60.5763 62.1433 59.4741C63.241 58.3718 64.544 57.4978 65.9778 56.9018C67.4115 56.3058 68.9481 55.9994 70.4996 56Z" fill="#F5A506" stroke="#E5E5E5" strokeWidth="2" />
        <path d="M70.5 86.4827C75.053 86.4827 79.4391 88.4542 82.6873 91.9963C85.7228 95.3063 87.5278 99.7326 87.7537 104.414H53.2463C53.4722 99.7326 55.2772 95.3063 58.3127 91.9963C61.5609 88.4542 65.947 86.4827 70.5 86.4827Z" stroke="#E5E5E5" strokeWidth="2" />
        <rect x="66.5833" y="60.7111" width="0.87037" height="4.9917" rx="0.435185" fill="#747474" stroke="#E5E5E5" strokeWidth="0.87037" />
        <rect x="70.0648" y="60.7111" width="0.870368" height="4.9917" rx="0.435184" fill="#747474" stroke="#E5E5E5" strokeWidth="0.870368" />
        <rect x="73.5463" y="60.7111" width="0.87037" height="4.9917" rx="0.435185" fill="#747474" stroke="#E5E5E5" strokeWidth="0.87037" />
    </svg>


);

export default UnionIcon;
