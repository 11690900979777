import React from "react";

const HighlightEvaluationGroupIcon = (props) => (
    <svg {...props} className={"icon"} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M29.1429 20.5714H28V17.7143C28 16.1429 26.7143 14.8572 25.1429 14.8572H16.5714V11.4286H17.7143C19.2857 11.4286 20.5714 10.1429 20.5714 8.57145V6.28573C20.5714 4.7143 19.2857 3.42859 17.7143 3.42859H14.2857C12.7143 3.42859 11.4286 4.7143 11.4286 6.28573V8.57145C11.4286 10.1429 12.7143 11.4286 14.2857 11.4286H15.4286V14.8572H6.85714C5.28571 14.8572 4 16.1429 4 17.7143V20.5714H2.85714C1.28571 20.5714 0 21.8572 0 23.4286V25.7143C0 27.2857 1.28571 28.5714 2.85714 28.5714H6.28571C7.85714 28.5714 9.14286 27.2857 9.14286 25.7143V23.4286C9.14286 21.8572 7.85714 20.5714 6.28571 20.5714H5.14286V17.7143C5.14286 16.7714 5.91429 16 6.85714 16H15.4286V20.5714H14.2857C12.7143 20.5714 11.4286 21.8572 11.4286 23.4286V25.7143C11.4286 27.2857 12.7143 28.5714 14.2857 28.5714H17.7143C19.2857 28.5714 20.5714 27.2857 20.5714 25.7143V23.4286C20.5714 21.8572 19.2857 20.5714 17.7143 20.5714H16.5714V16H25.1429C26.0857 16 26.8571 16.7714 26.8571 17.7143V20.5714H25.7143C24.1429 20.5714 22.8571 21.8572 22.8571 23.4286V25.7143C22.8571 27.2857 24.1429 28.5714 25.7143 28.5714H29.1429C30.7143 28.5714 32 27.2857 32 25.7143V23.4286C32 21.8572 30.7143 20.5714 29.1429 20.5714ZM17.7143 21.7143C18.6571 21.7143 19.4286 22.4857 19.4286 23.4286V25.7143C19.4286 26.6572 18.6571 27.4286 17.7143 27.4286H14.2857C13.3429 27.4286 12.5714 26.6572 12.5714 25.7143V23.4286C12.5714 22.4857 13.3429 21.7143 14.2857 21.7143H17.7143ZM14.2857 10.2857C13.3429 10.2857 12.5714 9.5143 12.5714 8.57145V6.28573C12.5714 5.34287 13.3429 4.57145 14.2857 4.57145H17.7143C18.6571 4.57145 19.4286 5.34287 19.4286 6.28573V8.57145C19.4286 9.5143 18.6571 10.2857 17.7143 10.2857H14.2857Z" fill={props.htmlColor} />
    </svg>

);

export default HighlightEvaluationGroupIcon;
