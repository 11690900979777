import React from "react";

const SilverShieldIcon = (props) => (
  <svg {...props} className={"icon"} width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5678_21071)">
      <path d="M32.2031 64.7246C25.7722 62.2229 20.283 57.9201 15.9616 52.1358C12.6395 47.6954 10.0737 42.4665 8.29511 36.7116C5.71533 28.367 5.84182 9.91878 5.84055 8.80422C7.87601 8.57876 23.5911 6.66936 32.2031 0.724609C40.8152 6.66936 56.5302 8.57876 58.5657 8.80422C58.5657 9.91878 58.6935 28.3734 56.1086 36.7205C54.3287 42.4729 51.7642 47.7018 48.4421 52.1333C44.1194 57.9188 38.6328 62.2203 32.2031 64.7246Z" fill="url(#paint0_linear_5678_21071)" />
      <path d="M32.2013 64.2787C25.9403 61.8126 20.5929 57.6436 16.2907 51.8861C13.035 47.5284 10.4795 42.3823 8.69068 36.5866C6.42651 29.2598 6.2489 13.9999 6.25401 9.16716C9.21712 8.81432 23.8474 6.83486 32.2039 1.21875C40.5604 6.83486 55.1906 8.81432 58.1537 9.16716C58.1537 14.0075 57.98 29.2675 55.7145 36.5955C53.9257 42.3913 51.3702 47.5361 48.1106 51.8899C43.8071 57.6448 38.4623 61.8126 32.2013 64.2787Z" fill="url(#paint1_linear_5678_21071)" />
      <path d="M32.2016 62.0554C26.6319 59.6976 21.839 55.8661 17.947 50.6563C14.8344 46.4898 12.3824 41.5513 10.6638 35.9811C8.70754 29.6529 8.3715 16.8463 8.32422 10.9678C13.1797 10.2697 24.5823 8.23041 32.2041 3.65625C39.8259 8.23041 51.2235 10.2697 56.084 10.9678C56.0368 16.8476 55.7007 29.658 53.7419 35.9887C52.0195 41.5602 49.5675 46.4962 46.4587 50.6602C42.5616 55.8687 37.77 59.6989 32.2016 62.0554Z" fill="url(#paint2_linear_5678_21071)" />
      <path d="M27.0378 16.2299C27.0977 16.0457 27.3584 16.0457 27.4182 16.2299L27.9649 17.9123C27.9916 17.9947 28.0684 18.0505 28.1551 18.0505H29.924C30.1178 18.0505 30.1983 18.2984 30.0416 18.4123L28.6105 19.452C28.5404 19.503 28.511 19.5932 28.5378 19.6756L29.0844 21.358C29.1443 21.5423 28.9334 21.6955 28.7767 21.5816L27.3456 20.5419C27.2755 20.4909 27.1806 20.4909 27.1105 20.5419L25.6794 21.5816C25.5226 21.6955 25.3117 21.5423 25.3716 21.358L25.9182 19.6756C25.945 19.5932 25.9157 19.503 25.8456 19.452L24.4145 18.4123C24.2577 18.2984 24.3383 18.0505 24.532 18.0505H26.301C26.3876 18.0505 26.4644 17.9947 26.4912 17.9123L27.0378 16.2299Z" fill="#C1BFBF" />
      <path d="M36.323 16.2299C36.3828 16.0457 36.6435 16.0457 36.7034 16.2299L37.25 17.9123C37.2768 17.9947 37.3536 18.0505 37.4402 18.0505H39.2092C39.4029 18.0505 39.4835 18.2984 39.3267 18.4123L37.8956 19.452C37.8255 19.503 37.7962 19.5932 37.823 19.6756L38.3696 21.358C38.4295 21.5423 38.2186 21.6955 38.0618 21.5816L36.6307 20.5419C36.5606 20.4909 36.4657 20.4909 36.3956 20.5419L34.9645 21.5816C34.8078 21.6955 34.5969 21.5423 34.6568 21.358L35.2034 19.6756C35.2302 19.5932 35.2008 19.503 35.1307 19.452L33.6996 18.4123C33.5429 18.2984 33.6235 18.0505 33.8172 18.0505H35.5861C35.6728 18.0505 35.7496 17.9947 35.7763 17.9123L36.323 16.2299Z" fill="#C1BFBF" />
      <path d="M31.3947 28.3815C31.5443 27.9208 32.196 27.9208 32.3457 28.3815L33.6565 32.4155C33.7234 32.6215 33.9154 32.761 34.132 32.761L38.3736 32.761C38.858 32.761 39.0594 33.3808 38.6675 33.6655L35.236 36.1587C35.0607 36.286 34.9874 36.5117 35.0543 36.7177L36.365 40.7517C36.5147 41.2124 35.9875 41.5954 35.5956 41.3107L32.1641 38.8176C31.9888 38.6903 31.7515 38.6903 31.5763 38.8176L28.1448 41.3107C27.7529 41.5954 27.2257 41.2124 27.3753 40.7517L28.6861 36.7177C28.753 36.5117 28.6797 36.286 28.5044 36.1587L25.0729 33.6655C24.681 33.3808 24.8824 32.761 25.3668 32.761L29.6084 32.761C29.825 32.761 30.017 32.6215 30.0839 32.4155L31.3947 28.3815Z" fill="#C1BFBF" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_5678_21071" x1="17.2919" y1="59.1553" x2="38.8554" y2="1.42887" gradientUnits="userSpaceOnUse">
        <stop stopColor="#C5C5C5" />
        <stop offset="0.107292" stopColor="#AEAEAE" />
        <stop offset="0.201042" stopColor="#C1BFBF" />
        <stop offset="0.33" stopColor="#878787" />
        <stop offset="0.42" stopColor="#9B9A9A" />
        <stop offset="0.4875" stopColor="#EFEFEF" />
        <stop offset="0.62" stopColor="white" />
        <stop offset="0.66" stopColor="#C1BFBF" />
        <stop offset="0.763542" stopColor="#C1BFBF" />
        <stop offset="1" stopColor="#FAFAFA" />
      </linearGradient>
      <linearGradient id="paint1_linear_5678_21071" x1="17.5263" y1="58.7912" x2="38.7894" y2="1.92679" gradientUnits="userSpaceOnUse">
        <stop stopColor="#C5C5C5" />
        <stop offset="0.107292" stopColor="#AEAEAE" />
        <stop offset="0.201042" stopColor="#C1BFBF" />
        <stop offset="0.33" stopColor="#878787" />
        <stop offset="0.42" stopColor="#9B9A9A" />
        <stop offset="0.4875" stopColor="#EFEFEF" />
        <stop offset="0.62" stopColor="white" />
        <stop offset="0.66" stopColor="#C1BFBF" />
        <stop offset="0.763542" stopColor="#C1BFBF" />
        <stop offset="1" stopColor="#FAFAFA" />
      </linearGradient>
      <linearGradient id="paint2_linear_5678_21071" x1="8.32166" y1="32.8552" x2="56.0815" y2="32.8552" gradientUnits="userSpaceOnUse">
        <stop />
        <stop offset="0.02" stopColor="#060606" />
        <stop offset="0.1" stopColor="#111111" />
        <stop offset="0.21" stopColor="#181818" />
        <stop offset="0.5" stopColor="#1A1A1A" />
        <stop offset="0.79" stopColor="#181818" />
        <stop offset="0.9" stopColor="#111111" />
        <stop offset="0.98" stopColor="#060606" />
        <stop offset="1" />
      </linearGradient>
      <clipPath id="clip0_5678_21071">
        <rect width="64" height="64" fill="white" transform="translate(0.203125 0.724609)" />
      </clipPath>
    </defs>
  </svg>
);

export default SilverShieldIcon;
