import React from "react";

const PayrollTypeIcon = (props) => (
  <svg {...props} className={"icon"}  width="122" height="118" viewBox="0 0 122 118" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M36.531 3.46561H6.33984C5.23527 3.46561 4.33984 4.36104 4.33984 5.46561V112.785C4.33984 113.89 5.23527 114.785 6.33984 114.785H36.531C37.6355 114.785 38.531 113.89 38.531 112.785V5.46561C38.531 4.36104 37.6355 3.46561 36.531 3.46561Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M28.6283 10.0054H14.2383C13.1337 10.0054 12.2383 10.9009 12.2383 12.0054V46.7797C12.2383 47.8843 13.1337 48.7797 14.2383 48.7797H28.6283C29.7328 48.7797 30.6283 47.8843 30.6283 46.7797V12.0054C30.6283 10.9009 29.7329 10.0054 28.6283 10.0054Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M21.4341 103.528C25.4622 103.528 28.7276 100.26 28.7276 96.2291C28.7276 92.1978 25.4622 88.9299 21.4341 88.9299C17.406 88.9299 14.1406 92.1978 14.1406 96.2291C14.1406 100.26 17.406 103.528 21.4341 103.528Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M76.5935 3.46561H46.4023C45.2978 3.46561 44.4023 4.36104 44.4023 5.46561V112.785C44.4023 113.89 45.2978 114.785 46.4023 114.785H76.5935C77.698 114.785 78.5935 113.89 78.5935 112.785V5.46561C78.5935 4.36104 77.698 3.46561 76.5935 3.46561Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M68.6947 10.0054H54.3047C53.2001 10.0054 52.3047 10.9009 52.3047 12.0054V46.7797C52.3047 47.8843 53.2001 48.7797 54.3047 48.7797H68.6947C69.7993 48.7797 70.6947 47.8843 70.6947 46.7797V12.0054C70.6947 10.9009 69.7993 10.0054 68.6947 10.0054Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M61.5005 103.528C65.5286 103.528 68.7941 100.26 68.7941 96.2291C68.7941 92.1978 65.5286 88.9299 61.5005 88.9299C57.4724 88.9299 54.207 92.1978 54.207 96.2291C54.207 100.26 57.4724 103.528 61.5005 103.528Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M116.66 3.46561H86.4688C85.3642 3.46561 84.4688 4.36104 84.4688 5.46561V112.785C84.4688 113.89 85.3642 114.785 86.4687 114.785H116.66C117.764 114.785 118.66 113.89 118.66 112.785V5.46561C118.66 4.36104 117.764 3.46561 116.66 3.46561Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M108.761 10.0054H94.3711C93.2665 10.0054 92.3711 10.9009 92.3711 12.0054V46.7797C92.3711 47.8843 93.2665 48.7797 94.3711 48.7797H108.761C109.866 48.7797 110.761 47.8843 110.761 46.7797V12.0054C110.761 10.9009 109.866 10.0054 108.761 10.0054Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M100.567 103.528C104.595 103.528 107.86 100.26 107.86 96.2291C107.86 92.1978 104.595 88.9299 100.567 88.9299C96.5389 88.9299 93.2734 92.1978 93.2734 96.2291C93.2734 100.26 96.5389 103.528 100.567 103.528Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
  </svg>
);

export default PayrollTypeIcon;
