import React from "react";

const PerformanceAnalyticsEvaluationsIcon = (props) => (
    <svg {...props} className={"icon"} width="112" height="95" viewBox="0 0 112 95" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M88.5076 88.0512C88.5078 89.1559 87.6123 90.0516 86.5076 90.0516H24.4883C23.3837 90.0516 22.4883 89.1561 22.4883 88.0516V6.01514C22.4883 4.91057 23.3837 4.01514 24.4883 4.01514H65.4918C66.0128 4.01514 66.5132 4.21843 66.8866 4.58177L87.8899 25.0198C88.2767 25.3962 88.495 25.913 88.4951 26.4528L88.5076 88.0512Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
        <path d="M66.3047 4.01514V23.9405C66.3047 24.4928 66.7524 24.9405 67.3047 24.9405H88.0446" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
        <path d="M40.6193 47.3656C38.6132 49.3837 37.0219 51.7796 35.9361 54.4164C34.8504 57.0532 34.2916 59.8794 34.2916 62.7335L46.1739 62.7335C46.1739 61.4491 46.4254 60.1774 46.914 58.9908C47.4025 57.8042 48.1186 56.7261 49.0214 55.8179L40.6193 47.3656Z" fill="#FF4040" />
        <path d="M55.8958 41C58.7329 41 61.5422 41.5622 64.1634 42.6544C66.7845 43.7466 69.1661 45.3474 71.1723 47.3656C73.1784 49.3837 74.7698 51.7796 75.8555 54.4164C76.9412 57.0532 77.5 59.8794 77.5 62.7335L65.6177 62.7335C65.6177 61.4491 65.3662 60.1774 64.8777 58.9908C64.3891 57.8042 63.673 56.7261 62.7702 55.8179C61.8675 54.9097 60.7957 54.1894 59.6162 53.6979C58.4367 53.2064 57.1725 52.9534 55.8958 52.9534V41Z" fill="#F5A506" />
        <path d="M40.6193 47.3656C44.6709 43.2898 50.166 41 55.8958 41C61.6256 41 67.1207 43.2898 71.1723 47.3656L62.7702 55.8179C60.947 53.9838 58.4742 52.9534 55.8958 52.9534C53.3174 52.9534 50.8446 53.9838 49.0214 55.8179L40.6193 47.3656Z" fill="#FF592C" />
        <path d="M54.6919 61.916C54.3272 62.1125 54.1881 62.5689 54.3811 62.9354C54.574 63.3019 55.0261 63.4397 55.3907 63.2431L54.6919 61.916ZM69.7865 55.2351C69.9079 54.837 69.6866 54.4168 69.2923 54.2966L62.8668 52.3382C62.4725 52.218 62.0544 52.4433 61.933 52.8414C61.8117 53.2395 62.0329 53.6597 62.4272 53.7798L68.1388 55.5207L66.3804 61.2875C66.259 61.6856 66.4803 62.1057 66.8746 62.2259C67.2689 62.3461 67.6869 62.1208 67.8083 61.7227L69.7865 55.2351ZM55.3907 63.2431L69.4219 55.681L68.7231 54.3539L54.6919 61.916L55.3907 63.2431Z" fill="var(--svgColor)" />
    </svg>
);

export default PerformanceAnalyticsEvaluationsIcon;
