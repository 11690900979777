import React from "react";

const IndividualPayrollIcon = (props) => (
    <svg {...props} className={"icon"} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M7.10695 0.0341797C7.32853 0.0341797 7.50815 0.213802 7.50815 0.435378L7.50815 23.4773C7.50815 23.6989 7.32853 23.8785 7.10695 23.8785H0.921827C0.700252 23.8785 0.52063 23.6989 0.52063 23.4773V0.435379C0.52063 0.213802 0.700253 0.0341797 0.921829 0.0341797H7.10695ZM5.42502 1.92902C5.6466 1.92902 5.82622 2.10865 5.82622 2.33022L5.82622 10.9164C5.82622 11.138 5.6466 11.3176 5.42502 11.3176H2.60394C2.38236 11.3176 2.20274 11.138 2.20274 10.9164L2.20274 2.33022C2.20274 2.10865 2.38236 1.92902 2.60394 1.92902L5.42502 1.92902ZM4.01434 21.5419C4.56117 21.5419 5.00447 21.0986 5.00447 20.5517C5.00447 20.0049 4.56117 19.5616 4.01434 19.5616C3.4675 19.5616 3.02421 20.0049 3.02421 20.5517C3.02421 21.0986 3.4675 21.5419 4.01434 21.5419Z" fill={props.htmlColor} />
        <path fillRule="evenodd" clipRule="evenodd" d="M15.0925 0.0341797C15.3141 0.0341797 15.4937 0.213802 15.4937 0.435378L15.4937 23.4773C15.4937 23.6989 15.3141 23.8785 15.0925 23.8785H8.90742C8.68585 23.8785 8.50623 23.6989 8.50623 23.4773V0.435379C8.50623 0.213802 8.68585 0.0341797 8.90742 0.0341797H15.0925ZM13.4106 1.92902C13.6322 1.92902 13.8118 2.10865 13.8118 2.33022L13.8118 10.9164C13.8118 11.138 13.6322 11.3176 13.4106 11.3176H10.5895C10.368 11.3176 10.1883 11.138 10.1883 10.9164L10.1883 2.33022C10.1883 2.10865 10.368 1.92902 10.5895 1.92902L13.4106 1.92902ZM11.9999 21.5419C12.5468 21.5419 12.9901 21.0986 12.9901 20.5517C12.9901 20.0049 12.5468 19.5616 11.9999 19.5616C11.4531 19.5616 11.0098 20.0049 11.0098 20.5517C11.0098 21.0986 11.4531 21.5419 11.9999 21.5419Z" fill={props.htmlColor} />
        <path fillRule="evenodd" clipRule="evenodd" d="M23.0781 0.0341797C23.2997 0.0341797 23.4793 0.213802 23.4793 0.435378L23.4793 23.4773C23.4793 23.6989 23.2997 23.8785 23.0781 23.8785H16.893C16.6714 23.8785 16.4918 23.6989 16.4918 23.4773V0.435379C16.4918 0.213802 16.6714 0.0341797 16.893 0.0341797H23.0781ZM21.3962 1.92902C21.6178 1.92902 21.7974 2.10865 21.7974 2.33022L21.7974 10.9164C21.7974 11.138 21.6178 11.3176 21.3962 11.3176H18.5751C18.3536 11.3176 18.1739 11.138 18.1739 10.9164L18.1739 2.33022C18.1739 2.10865 18.3536 1.92902 18.5751 1.92902L21.3962 1.92902ZM19.9855 21.5419C20.5324 21.5419 20.9757 21.0986 20.9757 20.5517C20.9757 20.0049 20.5324 19.5616 19.9855 19.5616C19.4387 19.5616 18.9954 20.0049 18.9954 20.5517C18.9954 21.0986 19.4387 21.5419 19.9855 21.5419Z" fill={props.htmlColor} />
    </svg>
)

export default IndividualPayrollIcon;
