import React from "react";

const PublicationIconLinks = (props) => (
  <svg {...props} className={"icon"} width="36" height="34" viewBox="0 0 36 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M22.6188 5.11989C21.4602 3.25526 20.0379 1.55609 18.3929 0.0708008C16.748 1.55609 15.3257 3.25526 14.1671 5.11989H22.6188Z" fill="var(--icons)"/>
      <path d="M13.5136 6.25317C12.7278 7.69613 12.1033 9.216 11.6503 10.7865H25.1359C24.6828 9.216 24.0584 7.69613 23.2726 6.25317H13.5136Z" fill="var(--icons)"/>
      <path d="M26.3558 10.7865H34.7722C34.1019 9.14627 33.1711 7.61699 32.0136 6.25317H24.5911C25.335 7.70332 25.9262 9.22209 26.3558 10.7865Z" fill="var(--icons)"/>
      <path d="M24.5911 27.7865H32.0154C33.1728 26.4227 34.1036 24.8931 34.7739 23.2532H26.3558C25.9262 24.8173 25.335 26.3361 24.5911 27.7865Z" fill="var(--icons)"/>
      <path d="M12.1946 6.25317H4.76919C3.61143 7.61672 2.68092 9.14627 2.01062 10.7865H10.4299C10.8595 9.22209 11.4507 7.70332 12.1946 6.25317Z" fill="var(--icons)"/>
      <path d="M30.9598 5.11981C28.0585 2.25521 24.1995 0.476351 20.0685 0.0983887C21.5862 1.6036 22.897 3.29142 23.9685 5.11981H30.9598Z" fill="var(--icons)"/>
      <path d="M5.82422 28.9199C8.72607 31.7845 12.5854 33.5637 16.7172 33.9411C15.1995 32.4359 13.8887 30.7483 12.8172 28.9199H5.82422Z" fill="var(--icons)"/>
      <path d="M20.0685 33.9411C24.1995 33.5634 28.0585 31.7842 30.9598 28.9199H23.9685C22.897 30.7483 21.5862 32.4359 20.0685 33.9411Z" fill="var(--icons)"/>
      <path d="M10.4299 23.2532H2.01294C2.68324 24.8931 3.61403 26.4227 4.77151 27.7865H12.1946C11.4507 26.3361 10.8595 24.8173 10.4299 23.2532Z" fill="var(--icons)"/>
      <path d="M16.7172 0.0983887C12.5854 0.475798 8.72607 2.25493 5.82422 5.11981H12.8172C13.8887 3.29142 15.1995 1.6036 16.7172 0.0983887Z" fill="var(--icons)"/>
      <path d="M23.2726 27.7865C24.0584 26.3433 24.6828 24.8237 25.1359 23.2532H11.6503C12.1033 24.8237 12.7278 26.3433 13.5136 27.7865H23.2726Z" fill="var(--icons)"/>
      <path d="M14.1671 28.9199C15.3257 30.7843 16.748 32.4837 18.3929 33.9687C20.0379 32.4837 21.4602 30.7843 22.6188 28.9199H14.1671Z" fill="var(--icons)"/>
      <path d="M35.1917 11.9199H1.59253C0.515869 15.2402 0.515869 18.7993 1.59253 22.1199H35.1917C36.2683 18.7993 36.2683 15.2402 35.1917 11.9199ZM10.1548 20.0021C10.086 20.2462 9.8582 20.4166 9.59627 20.4199H9.58853C9.32918 20.4199 9.10078 20.2556 9.02627 20.0157L7.82782 16.1586L6.62936 20.0157C6.55485 20.2556 6.32645 20.4199 6.0671 20.4199H6.05937C5.79772 20.4166 5.56961 20.2462 5.50083 20.0021L3.74012 13.7688L4.87266 13.4708L6.09404 17.7944L7.26785 14.0239C7.34236 13.784 7.57076 13.6196 7.83011 13.6196C8.08946 13.6196 8.31815 13.784 8.39237 14.0239L9.56618 17.7944L10.7876 13.4708L11.9204 13.7688L10.1548 20.0021ZM20.7191 20.0021C20.6503 20.2462 20.4225 20.4166 20.1606 20.4199H20.1528C19.8935 20.4199 19.6651 20.2556 19.5906 20.0157L18.3921 16.1586L17.1936 20.0157C17.1191 20.2556 16.8907 20.4199 16.6314 20.4199H16.6237C16.362 20.4166 16.1339 20.2462 16.0651 20.0021L14.3044 13.7688L15.4369 13.4708L16.6583 17.7944L17.8321 14.0239C17.9066 13.784 18.135 13.6196 18.3944 13.6196C18.6537 13.6196 18.8824 13.784 18.9567 14.0239L20.1305 17.7944L21.3518 13.4708L22.4847 13.7688L20.7191 20.0021ZM31.2834 20.0021C31.2146 20.2462 30.9868 20.4166 30.7248 20.4199H30.7171C30.4578 20.4199 30.2294 20.2556 30.1548 20.0157L28.9564 16.1586L27.7579 20.0157C27.6834 20.2556 27.455 20.4199 27.1957 20.4199H27.1879C26.9263 20.4166 26.6982 20.2462 26.6294 20.0021L24.8687 13.7688L26.0012 13.4708L27.2226 17.7944L28.3964 14.0239C28.4709 13.784 28.6993 13.6196 28.9587 13.6196C29.218 13.6196 29.4467 13.784 29.5209 14.0239L30.6947 17.7944L31.9161 13.4708L33.049 13.7688L31.2834 20.0021Z" fill="var(--icons)"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="35.2143" height="34" fill="white" transform="translate(0.785767)"/>
      </clipPath>
    </defs>
  </svg>
);
export default PublicationIconLinks;
