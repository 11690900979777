import React from "react";

const GroupClassificationIcon = (props) => (
    <svg {...props} className={"icon"} width="111" height="95" viewBox="0 0 111 95" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_7220_12789)">
            <circle cx="35.5" cy="38.5" r="31.5" stroke="var(--svgColor)" strokeWidth="2" />
            <circle cx="74.5" cy="56.5" r="31.5" stroke="var(--svgColor)" strokeWidth="2" />
            <g clipPath="url(#clip1_7220_12789)">
                <path d="M32.5024 34.3943C37.9656 34.3943 42.3944 29.9655 42.3944 24.5023C42.3944 19.0391 37.9656 14.6104 32.5024 14.6104C27.0393 14.6104 22.6105 19.0391 22.6105 24.5023C22.6105 29.9655 27.0393 34.3943 32.5024 34.3943Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="1.01163" strokeMiterlimit="10" />
                <path d="M28.2755 22.9639C29.0172 22.9639 29.6185 22.3626 29.6185 21.6209C29.6185 20.8791 29.0172 20.2778 28.2755 20.2778C27.5338 20.2778 26.9325 20.8791 26.9325 21.6209C26.9325 22.3626 27.5338 22.9639 28.2755 22.9639Z" fill="var(--svgColor)" />
                <path d="M36.8978 22.9639C37.6396 22.9639 38.2409 22.3626 38.2409 21.6209C38.2409 20.8791 37.6396 20.2778 36.8978 20.2778C36.1561 20.2778 35.5548 20.8791 35.5548 21.6209C35.5548 22.3626 36.1561 22.9639 36.8978 22.9639Z" fill="var(--svgColor)" />
                <path d="M37.4302 25.3423C37.4302 27.8232 35.0249 30.0795 32.5465 30.0795C30.068 30.0795 27.5675 27.8232 27.5675 25.3423" stroke="var(--svgColor)" strokeWidth="1.01163" strokeMiterlimit="10" />
            </g>
            <g clipPath="url(#clip2_7220_12789)">
                <path d="M26.5024 59.3943C31.9656 59.3943 36.3944 54.9655 36.3944 49.5023C36.3944 44.0391 31.9656 39.6104 26.5024 39.6104C21.0393 39.6104 16.6105 44.0391 16.6105 49.5023C16.6105 54.9655 21.0393 59.3943 26.5024 59.3943Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="1.01163" strokeMiterlimit="10" />
                <path d="M22.2755 47.9639C23.0172 47.9639 23.6185 47.3626 23.6185 46.6209C23.6185 45.8791 23.0172 45.2778 22.2755 45.2778C21.5338 45.2778 20.9325 45.8791 20.9325 46.6209C20.9325 47.3626 21.5338 47.9639 22.2755 47.9639Z" fill="var(--svgColor)" />
                <path d="M30.8978 47.9639C31.6396 47.9639 32.2409 47.3626 32.2409 46.6209C32.2409 45.8791 31.6396 45.2778 30.8978 45.2778C30.1561 45.2778 29.5548 45.8791 29.5548 46.6209C29.5548 47.3626 30.1561 47.9639 30.8978 47.9639Z" fill="var(--svgColor)" />
                <path d="M31.4302 50.3423C31.4302 52.8232 29.0249 55.0795 26.5465 55.0795C24.068 55.0795 21.5675 52.8232 21.5675 50.3423" stroke="var(--svgColor)" strokeWidth="1.01163" strokeMiterlimit="10" />
            </g>
            <g clipPath="url(#clip3_7220_12789)">
                <path d="M82.5024 73.3943C87.9656 73.3943 92.3944 68.9655 92.3944 63.5023C92.3944 58.0391 87.9656 53.6104 82.5024 53.6104C77.0393 53.6104 72.6105 58.0391 72.6105 63.5023C72.6105 68.9655 77.0393 73.3943 82.5024 73.3943Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="1.01163" strokeMiterlimit="10" />
                <path d="M78.2755 61.9639C79.0172 61.9639 79.6185 61.3626 79.6185 60.6209C79.6185 59.8791 79.0172 59.2778 78.2755 59.2778C77.5338 59.2778 76.9325 59.8791 76.9325 60.6209C76.9325 61.3626 77.5338 61.9639 78.2755 61.9639Z" fill="var(--svgColor)" />
                <path d="M86.8978 61.9639C87.6396 61.9639 88.2409 61.3626 88.2409 60.6209C88.2409 59.8791 87.6396 59.2778 86.8978 59.2778C86.1561 59.2778 85.5548 59.8791 85.5548 60.6209C85.5548 61.3626 86.1561 61.9639 86.8978 61.9639Z" fill="var(--svgColor)" />
                <path d="M87.4302 64.3423C87.4302 66.8232 85.0249 69.0795 82.5465 69.0795C80.068 69.0795 77.5675 66.8232 77.5675 64.3423" stroke="var(--svgColor)" strokeWidth="1.01163" strokeMiterlimit="10" />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_7220_12789">
                <rect x="111" width="95" height="111" rx="2" transform="rotate(90 111 0)" fill="var(--svgColor)" />
            </clipPath>
            <clipPath id="clip1_7220_12789">
                <rect width="21" height="21" fill="var(--svgColor)" transform="translate(22 14)" />
            </clipPath>
            <clipPath id="clip2_7220_12789">
                <rect width="21" height="21" fill="var(--svgColor)" transform="translate(16 39)" />
            </clipPath>
            <clipPath id="clip3_7220_12789">
                <rect width="21" height="21" fill="var(--svgColor)" transform="translate(72 53)" />
            </clipPath>
        </defs>
    </svg>
);

export default GroupClassificationIcon;
