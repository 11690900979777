import React from "react";

const ManagerIcon = (props) => (
  <svg {...props} className={"icon"} width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
    <path d="M33.1816 19.683C35.2734 11.0701 29.7875 2.43924 20.9285 0.40548C12.0695 -1.62828 3.19203 3.7052 1.10016 12.3182C-0.991707 20.9311 4.49416 29.562 13.3532 31.5957C22.2122 33.6295 31.0897 28.296 33.1816 19.683Z" fill="#FF592C"/>
    <path d="M26.1357 21.53C26.1357 21.6774 26.0851 21.8249 25.9335 21.9724C25.8324 22.0707 25.6301 22.169 25.4784 22.169H8.74321C8.59153 22.169 8.43985 22.1198 8.28818 21.9724C8.18706 21.874 8.08594 21.6774 8.08594 21.53V12.4854H26.1357V21.53Z" fill="white"/>
    <path d="M26.1357 12.4854C26.1357 13.3702 25.7818 14.3042 25.074 14.9923C24.3661 15.6805 23.456 16.0246 22.4954 16.0246H11.7262C10.8162 16.0246 9.85552 15.6805 9.14769 14.9923C8.43985 14.3042 8.08594 13.4194 8.08594 12.4854V10.4701C8.08594 10.3226 8.1365 10.1751 8.28818 10.0277C8.3893 9.92936 8.59153 9.83105 8.74321 9.83105H25.529C25.6807 9.83105 25.8323 9.88021 25.984 10.0277C26.0851 10.126 26.1863 10.3226 26.1863 10.4701V12.4854H26.1357Z" fill="#D3D3D3"/>
    <path d="M20.2188 9.88019H19.4099V9.09371C19.4099 8.94624 19.3593 8.84793 19.2582 8.74962C19.1571 8.65131 19.056 8.60216 18.9043 8.60216H15.3145C15.1629 8.60216 15.0618 8.65131 14.9606 8.74962C14.8595 8.84793 14.809 8.94624 14.809 9.09371V9.88019H14V9.09371C14 8.74962 14.1517 8.45469 14.4045 8.20892C14.6573 7.96314 14.9606 7.81567 15.3145 7.81567H18.9043C19.2582 7.81567 19.5616 7.96314 19.8144 8.20892C20.0672 8.45469 20.2188 8.74962 20.2188 9.09371V9.88019Z" fill="#343B43"/>
    <path d="M12.1814 9.53613H9.90625V9.88022H12.1814V9.53613Z" fill="#343B43"/>
    <path d="M24.3142 9.53613H22.0391V9.88022H24.3142V9.53613Z" fill="#343B43"/>
    <path d="M18.0968 14.8448H16.125V17.2043H18.0968V14.8448Z" fill="white"/>
    <path d="M18.0968 9.88019H16.125V14.8449H18.0968V9.88019Z" fill="#343B43"/>
    <path d="M18.0968 17.2043H16.125V22.169H18.0968V17.2043Z" fill="#D3D3D3"/>
    </g>
    <defs>
    <clipPath id="clip0">
    <rect width="32.9143" height="32" fill="white" transform="translate(0.65625)"/>
    </clipPath>
    </defs>
  </svg>
);
export default ManagerIcon;
