import React from "react";

const KpiDefinitionIcon = (props) => (
    <svg {...props} className={"icon"} width="149" height="87" viewBox="0 0 149 87" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M143.832 4.19275H8.2937C7.74142 4.19275 7.2937 4.64046 7.2937 5.19275V81.8072C7.2937 82.3595 7.74142 82.8072 8.2937 82.8072H143.832C144.384 82.8072 144.832 82.3595 144.832 81.8072V5.19275C144.832 4.64046 144.384 4.19275 143.832 4.19275Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
        <path d="M84.3986 35.6385H125.035" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"/>
        <path d="M84.3986 46.1205H125.035" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"/>
        <path d="M84.3986 56.6024H125.035" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"/>
        <path d="M50.7167 16.979C45.3708 16.3042 39.9473 17.2804 35.1656 19.778C30.3838 22.2756 26.4699 26.1766 23.9429 30.9635C21.4159 35.7505 20.3952 41.1971 21.0163 46.5811C21.6374 51.965 23.8708 57.0318 27.4204 61.1094C30.97 65.1869 35.6679 68.0825 40.8911 69.4122C46.1143 70.7418 51.616 70.4425 56.6662 68.5542C61.7165 66.6658 66.0767 63.2775 69.1686 58.8387C72.2605 54.3999 73.9379 49.1204 73.9783 43.7005L59.3371 43.59C59.319 46.0232 58.5659 48.3934 57.1778 50.3862C55.7898 52.3789 53.8323 53.9001 51.565 54.7478C49.2977 55.5956 46.8278 55.73 44.4829 55.133C42.138 54.5361 40.0289 53.2361 38.4353 51.4056C36.8418 49.575 35.8391 47.3003 35.5603 44.8832C35.2814 42.4661 35.7397 40.0209 36.8741 37.8718C38.0086 35.7228 39.7657 33.9715 41.9125 32.8502C44.0592 31.7289 46.494 31.2907 48.894 31.5936L50.7167 16.979Z" fill="#F5A506"/>
        <path d="M21.6091 37.1123C20.6163 41.17 20.5832 45.4054 21.5123 49.4784C22.4414 53.5513 24.3069 57.3484 26.959 60.5648C29.611 63.7811 32.9758 66.3271 36.7831 67.9983C40.5904 69.6695 44.7342 70.4194 48.8818 70.1878C53.0294 69.9562 57.0653 68.7495 60.6653 66.6646C64.2654 64.5797 67.3293 61.6747 69.6112 58.1828C71.8931 54.6909 73.3293 50.7094 73.8046 46.558C74.2798 42.4066 73.7809 38.201 72.3478 34.2787L58.6051 39.3602C59.2485 41.121 59.4725 43.0091 59.2591 44.8729C59.0458 46.7366 58.401 48.524 57.3766 50.0917C56.3521 51.6594 54.9766 52.9635 53.3604 53.8995C51.7442 54.8355 49.9323 55.3773 48.0703 55.4813C46.2082 55.5852 44.3479 55.2486 42.6387 54.4983C40.9294 53.748 39.4188 52.605 38.2282 51.1611C37.0376 49.7171 36.2001 48.0125 35.7829 46.1839C35.3658 44.3554 35.3807 42.454 35.8264 40.6323L21.6091 37.1123Z" fill="#FF592C"/>
        <path d="M70.0985 57.4085C72.2083 53.9254 73.4977 50.0028 73.868 45.9411C74.2383 41.8794 73.6796 37.7863 72.2348 33.9752L58.5544 39.2239C59.203 40.9348 59.4538 42.7724 59.2876 44.5959C59.1214 46.4194 58.5425 48.1804 57.5953 49.7441L70.0985 57.4085Z" fill="#F5A506"/>
        <path d="M23.5441 31.7501C20.9023 37.1802 20.1684 43.3509 21.4623 49.2547C22.7561 55.1585 26.0013 60.4466 30.6679 64.2558L39.8933 52.8181C37.7982 51.108 36.3414 48.734 35.7605 46.0835C35.1796 43.433 35.5091 40.6628 36.6951 38.225L23.5441 31.7501Z" fill="#FF592C"/>
        <path d="M72.2397 33.988C70.5231 29.4531 67.6117 25.474 63.8178 22.4772C60.024 19.4805 55.4907 17.5792 50.7043 16.9774L48.8885 31.5929C51.0373 31.8631 53.0724 32.7166 54.7757 34.062C56.4789 35.4073 57.7859 37.1938 58.5566 39.2296L72.2397 33.988Z" fill="#F5A506"/>
    </svg>
);

export default KpiDefinitionIcon;
