import React from "react";

const InstitutionsIcon = (props) => (
    <svg {...props} className={"icon"} width="114" height="95" viewBox="0 0 114 95" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 25.7913H111" stroke="#E5E5E5" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M5.03308 91.0577H108.967" stroke="#E5E5E5" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M100.028 25.7913H13.9906C12.886 25.7913 11.9906 26.6868 11.9906 27.7913V89.0388C11.9906 90.1434 12.886 91.0388 13.9906 91.0388H100.028C101.132 91.0388 102.028 90.1434 102.028 89.0388V27.7913C102.028 26.6868 101.132 25.7913 100.028 25.7913Z" stroke="#E5E5E5" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M88 3H25C23.8954 3 23 3.89543 23 5V24C23 25.1046 23.8954 26 25 26H88C89.1046 26 90 25.1046 90 24V5C90 3.89543 89.1046 3 88 3Z" stroke="#E5E5E5" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M41.3088 52.3653H34.6207C33.5162 52.3653 32.6207 53.2607 32.6207 54.3653V89.0388C32.6207 90.1434 33.5162 91.0388 34.6207 91.0388H41.3088C42.4134 91.0388 43.3088 90.1434 43.3088 89.0388V54.3653C43.3088 53.2607 42.4134 52.3653 41.3088 52.3653Z" fill="#F5A506" stroke="#E5E5E5" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M60.3347 52.3653H53.6466C52.542 52.3653 51.6466 53.2607 51.6466 54.3653V89.0388C51.6466 90.1434 52.542 91.0388 53.6466 91.0388H60.3347C61.4393 91.0388 62.3347 90.1434 62.3347 89.0388V54.3653C62.3347 53.2607 61.4393 52.3653 60.3347 52.3653Z" fill="#F5A506" stroke="#E5E5E5" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M79.3792 52.3653H72.6912C71.5866 52.3653 70.6912 53.2607 70.6912 54.3653V89.0388C70.6912 90.1434 71.5866 91.0388 72.6912 91.0388H79.3792C80.4838 91.0388 81.3792 90.1434 81.3792 89.0388V54.3653C81.3792 53.2607 80.4838 52.3653 79.3792 52.3653Z" fill="#F5A506" stroke="#E5E5E5" strokeWidth="3" strokeMiterlimit="10" />
    </svg>

);

export default InstitutionsIcon;
