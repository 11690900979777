import React from "react";

const FunctionaryTypeIcon = (props) => (
    <svg {...props} className={"icon"} width="94" height="107" viewBox="0 0 94 107" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3406_10060)">
            <path d="M23.5 86.5416C28.053 86.5416 32.4391 88.5131 35.6873 92.0552C38.7228 95.3652 40.5278 99.7915 40.7537 104.473H6.24633C6.47218 99.7915 8.27717 95.3652 11.3127 92.0552C14.5609 88.5131 18.947 86.5416 23.5 86.5416Z" stroke="var(--svgColor)" strokeWidth="2" />
            <circle cx="23.5" cy="71.5588" r="11.5" stroke="var(--svgColor)" strokeWidth="2" />
            <path d="M70.4999 31.5416C75.053 31.5416 79.439 33.5131 82.6873 37.0552C85.7228 40.3652 87.5278 44.7915 87.7536 49.4727H53.2463C53.4721 44.7915 55.2771 40.3652 58.3126 37.0552C61.5608 33.5131 65.9469 31.5416 70.4999 31.5416Z" stroke="var(--svgColor)" strokeWidth="2" />
            <circle cx="70.5" cy="16.5588" r="11.5" stroke="var(--svgColor)" strokeWidth="2" />
            <path d="M23.5 31.5416C28.053 31.5416 32.4391 33.5131 35.6873 37.0552C38.7228 40.3652 40.5278 44.7915 40.7537 49.4727H6.24633C6.47218 44.7915 8.27717 40.3652 11.3127 37.0552C14.5609 33.5131 18.947 31.5416 23.5 31.5416Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="2" />
            <circle cx="23.5" cy="16.5588" r="11.5" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="2" />
            <path d="M70.4999 86.5416C75.053 86.5416 79.439 88.5131 82.6873 92.0552C85.7228 95.3652 87.5278 99.7915 87.7536 104.473H53.2463C53.4721 99.7915 55.2771 95.3652 58.3126 92.0552C61.5608 88.5131 65.9469 86.5416 70.4999 86.5416Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="2" />
            <circle cx="70.5" cy="71.5588" r="11.5" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="2" />
        </g>
        <defs>
            <clipPath id="clip0_3406_10060">
                <rect width="94" height="106" fill="white" transform="translate(0 0.0588379)" />
            </clipPath>
        </defs>
    </svg>

);

export default FunctionaryTypeIcon;
