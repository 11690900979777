import React from "react";

const GeneralValuesIcon = (props) => (
    <svg {...props} className={"icon"} width="126" height="87" viewBox="0 0 126 87" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M121 4H6C5.44772 4 5 4.44772 5 5V82C5 82.5523 5.44772 83 6 83H121C121.552 83 122 82.5523 122 82V5C122 4.44772 121.552 4 121 4Z"
            fill="#FF4040" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M25.043 27.0957H21.7041L21.0693 29H19.0444L22.4849 19.7578H24.2495L27.709 29H25.6841L25.043 27.0957ZM22.2183 25.5532H24.5288L23.3672 
            22.0938L22.2183 25.5532ZM28.5723 29V19.7578H31.8096C32.931 19.7578 33.7816 19.9736 34.3613 20.4053C34.9411 20.8327 35.231 21.4611 
            35.231 22.2905C35.231 22.7433 35.1146 23.1432 34.8818 23.4902C34.6491 23.833 34.3254 24.0848 33.9106 24.2456C34.3846 24.3641 34.757 
            24.6032 35.0278 24.9629C35.3029 25.3226 35.4404 25.7627 35.4404 26.2832C35.4404 27.1719 35.1569 27.8447 34.5898 28.3018C34.0228 
            28.7588 33.2145 28.9915 32.165 29H28.5723ZM30.4766 24.9756V27.4702H32.1079C32.5565 27.4702 32.9056 27.3644 33.1553 27.1528C33.4092 
            26.937 33.5361 26.6408 33.5361 26.2642C33.5361 25.4178 33.0981 24.9883 32.2222 24.9756H30.4766ZM30.4766 23.6299H31.8857C32.8464 
            23.613 33.3267 23.23 33.3267 22.481C33.3267 22.062 33.2039 21.7616 32.9585 21.5796C32.7173 21.3934 32.3343 21.3003 31.8096
            21.3003H30.4766V23.6299ZM44.1304 25.9214C44.0584 26.9159 43.6903 27.6987 43.0259 28.27C42.3657 28.8413 41.494 29.127 40.4106 
            29.127C39.2257 29.127 38.2926 28.7292 37.6113 27.9336C36.9342 27.1338 36.5957 26.0378 36.5957 24.6455V24.0806C36.5957 23.1919 
            36.7523 22.409 37.0654 21.7319C37.3786 21.0549 37.825 20.5365 38.4048 20.1768C38.9888 19.8128 39.6659 19.6309 40.436 19.6309C41.5024 
            19.6309 42.3615 19.9165 43.0132 20.4878C43.6649 21.0591 44.0415 21.861 44.1431 22.8936H42.2388C42.1922 22.2969 42.0251 21.8652 41.7373 
            21.5986C41.4538 21.3278 41.02 21.1924 40.436 21.1924C39.8013 21.1924 39.3252 21.4209 39.0078 21.8779C38.6947 22.3307 38.5339 23.0353 
            38.5254 23.9917V24.6899C38.5254 25.6886 38.6756 26.4186 38.9761 26.8799C39.2808 27.3411 39.759 27.5718 40.4106 27.5718C40.9989 27.5718 
            41.4368 27.4385 41.7246 27.1719C42.0166 26.901 42.1838 26.4842 42.2261 25.9214H44.1304ZM49.6401 29H47.8057V21.9287L45.6157 
            22.6079V21.1162L49.4434 19.7451H49.6401V29ZM58.9839 29H52.6489V27.7432L55.6387 24.5566C56.0492 24.1081 56.3517 23.7166 56.5464 
            23.3823C56.7453 23.048 56.8447 22.7306 56.8447 22.4302C56.8447 22.0197 56.741 21.6981 56.5337 21.4653C56.3263 21.2284 56.0301 
            21.1099 55.645 21.1099C55.2303 21.1099 54.9023 21.2537 54.6611 21.5415C54.4242 21.825 54.3057 22.1995 54.3057 22.665H52.4648C52.4648
            22.1022 52.5981 21.5881 52.8647 21.1226C53.1356 20.6571 53.5164 20.2931 54.0073 20.0308C54.4982 19.7642 55.0547 19.6309 55.6768 
            19.6309C56.6289 19.6309 57.3674 19.8594 57.8921 20.3164C58.4211 20.7734 58.6855 21.4188 58.6855 22.2524C58.6855 22.7095 58.5671 
            23.175 58.3301 23.6489C58.0931 24.1229 57.6868 24.6751 57.1113 25.3057L55.0103 27.521H58.9839V29ZM61.98 23.5664H62.9575C63.423 
            23.5664 63.7679 23.45 63.9922 23.2173C64.2165 22.9845 64.3286 22.6756 64.3286 22.2905C64.3286 21.9181 64.2165 21.6283 63.9922 
            21.4209C63.7721 21.2135 63.4674 21.1099 63.0781 21.1099C62.7269 21.1099 62.4328 21.2072 62.1958 21.4019C61.9588 21.5923 61.8403 
            21.842 61.8403 22.1509H60.0059C60.0059 21.6685 60.1349 21.2368 60.3931 20.856C60.6554 20.4709 61.0194 20.1704 61.4849 19.9546C61.9546 
            19.7388 62.4709 19.6309 63.0337 19.6309C64.0112 19.6309 64.7772 19.8657 65.3315 20.3354C65.8859 20.8009 66.1631 21.4442 66.1631 
            22.2651C66.1631 22.6883 66.034 23.0776 65.7759 23.4331C65.5177 23.7886 65.1792 24.0615 64.7603 24.252C65.2808 24.4382 65.668
            24.7174 65.9219 25.0898C66.18 25.4622 66.3091 25.9023 66.3091 26.4102C66.3091 27.2311 66.0086 27.8892 65.4077 28.3843C64.811
            28.8794 64.0197 29.127 63.0337 29.127C62.1112 29.127 61.3558 28.8836 60.7676 28.397C60.1836 27.9103 59.8916 27.2671 59.8916
            26.4673H61.7261C61.7261 26.8143 61.8551 27.0978 62.1133 27.3179C62.3757 27.5379 62.6973 27.6479 63.0781 27.6479C63.514 27.6479
            63.8547 27.5337 64.1001 27.3052C64.3498 27.0724 64.4746 26.7656 64.4746 26.3848C64.4746 25.4622 63.9668 25.001 62.9512 25.001H61.98V23.5664Z"
            fill="var(--svgColor)"
        />
        <path d="M19 40L107 40" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M19 50L107 50" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M19 60L107 60" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M19 70L107 70" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default GeneralValuesIcon;
