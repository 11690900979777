import React from "react";

const NineBoxSettingsIcon = (props) => (
  <svg {...props} className={"icon"} width="112" height="96" viewBox="0 0 112 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6270_17126)">
      <rect x="70.3142" y="7.18262" width="28.3807" height="28.3807" fill="#F5A506" />
      <rect x="70.3142" y="61.4819" width="28.3807" height="28.3807" fill="#FF592C" />
      <rect x="12.1323" y="61.4819" width="28.3807" height="28.3807" fill="#FF4040" />
      <path d="M99.8577 87.0653L99.8577 8.71926C99.8577 7.83796 99.1432 7.12354 98.2619 7.12354L13.1901 7.12353C12.3088 7.12353 11.5944 7.83796 11.5944 8.71925L11.5944 87.0653C11.5944 87.9466 12.3088 88.6611 13.1901 88.6611L98.2619 88.6611C99.1432 88.6611 99.8577 87.9466 99.8577 87.0653Z" stroke="var(--svgColor)" strokeWidth="2.39358" strokeMiterlimit="10" />
      <path d="M12.5847 61.4819L99.8576 61.4819" stroke="var(--svgColor)" strokeWidth="2.39358" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M12.5847 34.3027L99.8576 34.3027" stroke="var(--svgColor)" strokeWidth="2.39358" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M41.6755 88.6616L41.6755 7.12403" stroke="var(--svgColor)" strokeWidth="2.39358" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M70.7666 88.6616L70.7666 7.12403" stroke="var(--svgColor)" strokeWidth="2.39358" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M99.8577 88.6616L99.8577 7.12403" stroke="var(--svgColor)" strokeWidth="2.39358" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M82.5151 25.4546L81.6069 24.5464C81.3725 24.312 81.2846 23.9604 81.4018 23.6089C81.4897 23.3452 81.6069 23.0229 81.7241 22.6128H78.6479C78.3842 22.6128 78.1499 22.4956 78.0327 22.2612C77.8862 22.0562 77.8862 21.7925 78.0327 21.5581L79.5561 18.98C79.937 18.3354 80.6401 17.9253 81.3725 17.9253H83.8041C83.8627 17.8081 83.9213 17.7202 84.0092 17.603C86.4116 14.0581 89.9858 13.9409 92.0952 14.3511C92.4467 14.4097 92.7104 14.6733 92.769 15.0249C93.1791 17.1343 93.062 20.7085 89.517 23.1108C89.3999 23.1987 89.312 23.2573 89.1948 23.3159V25.7476C89.1948 26.48 88.7846 27.1831 88.1401 27.564L85.562 29.0874C85.3276 29.2339 85.0639 29.2339 84.8588 29.0874C84.6245 28.9702 84.5073 28.7358 84.5073 28.4722V25.3374C84.0678 25.4839 83.7163 25.6011 83.4526 25.689C83.1303 25.8062 82.7495 25.7183 82.5151 25.4546ZM89.1948 19.0972C89.6049 19.0972 89.9858 18.8921 90.1909 18.5112C90.3959 18.1597 90.3959 17.7202 90.1909 17.3394C89.9858 16.9878 89.6049 16.7534 89.1948 16.7534C88.7553 16.7534 88.3745 16.9878 88.1694 17.3394C87.9643 17.7202 87.9643 18.1597 88.1694 18.5112C88.3745 18.8921 88.7553 19.0972 89.1948 19.0972Z" fill="white" />
      <path d="M80.5776 68.5869L83.4213 70.7744C83.5854 70.9111 83.6948 71.1025 83.6948 71.2939V72.7705L86.6752 75.751C87.4682 75.3682 88.4526 75.5049 89.1088 76.1611L92.1713 79.2236C92.5268 79.5518 92.5268 80.126 92.1713 80.4541L90.4213 82.2041C90.0932 82.5596 89.519 82.5596 89.1909 82.2041L86.1284 79.1416C85.4721 78.4854 85.3354 77.4736 85.7456 76.6807L82.7651 73.7002H81.2612C81.0698 73.7002 80.8784 73.6182 80.7416 73.4541L78.5541 70.6104C78.3627 70.3369 78.3901 69.9814 78.6362 69.7354L79.7299 68.6416C79.9487 68.4229 80.3315 68.3955 80.5776 68.5869ZM78.9643 79.3057L83.0385 75.2314L84.7338 76.9268C84.5698 77.501 84.5971 78.1299 84.8159 78.7041L81.5893 81.9307C81.2612 82.2588 80.769 82.4502 80.2768 82.4502C79.2651 82.4502 78.4448 81.6299 78.4448 80.6182C78.4448 80.126 78.6362 79.6338 78.9643 79.3057ZM92.4448 72.3877C92.4448 73.9736 91.5151 75.3408 90.1752 75.9697L89.7377 75.5322C88.9721 74.7666 87.8784 74.4932 86.894 74.7666L84.5698 72.415V72.3877C84.5698 70.2275 86.3198 68.4502 88.5073 68.4502C88.7807 68.4502 89.0541 68.5049 89.3276 68.5596C89.6284 68.6143 89.7104 68.9971 89.4916 69.2158L87.7416 70.9658C87.6596 71.0479 87.6323 71.1572 87.6323 71.2666V72.8525C87.6323 73.0713 87.8237 73.29 88.0698 73.29H89.6284C89.7377 73.29 89.8471 73.2354 89.9291 73.1533L91.6791 71.4033C91.8979 71.1846 92.2807 71.2666 92.3354 71.5674C92.3901 71.8408 92.4448 72.1143 92.4448 72.3877ZM79.976 80.2627C79.976 80.6455 80.2495 80.9189 80.6323 80.9189C80.9877 80.9189 81.2885 80.6455 81.2885 80.2627C81.2885 79.9072 80.9877 79.6064 80.6323 79.6064C80.2495 79.6064 79.976 79.9072 79.976 80.2627Z" fill="white" />
      <path d="M22.3118 68.5752H30.7493H31.6868C32.1849 68.5752 32.6243 69.0146 32.6243 69.5127V78.8877C32.6243 79.415 32.1849 79.8252 31.6868 79.8252V81.7002C32.1849 81.7002 32.6243 82.1396 32.6243 82.6377C32.6243 83.165 32.1849 83.5752 31.6868 83.5752H30.7493H22.3118C20.7591 83.5752 19.4993 82.3154 19.4993 80.7627V71.3877C19.4993 69.835 20.7591 68.5752 22.3118 68.5752ZM22.3118 79.8252C21.7845 79.8252 21.3743 80.2646 21.3743 80.7627C21.3743 81.29 21.7845 81.7002 22.3118 81.7002H29.8118V79.8252H22.3118ZM23.2493 72.7939C23.2493 73.0576 23.4544 73.2627 23.7181 73.2627H29.3431C29.5775 73.2627 29.8118 73.0576 29.8118 72.7939C29.8118 72.5596 29.5775 72.3252 29.3431 72.3252H23.7181C23.4544 72.3252 23.2493 72.5596 23.2493 72.7939ZM23.7181 74.2002C23.4544 74.2002 23.2493 74.4346 23.2493 74.6689C23.2493 74.9326 23.4544 75.1377 23.7181 75.1377H29.3431C29.5775 75.1377 29.8118 74.9326 29.8118 74.6689C29.8118 74.4346 29.5775 74.2002 29.3431 74.2002H23.7181Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_6270_17126">
        <rect x="111.226" y="0.392578" width="95" height="111" rx="2" transform="rotate(90 111.226 0.392578)" fill="var(--svgColor)" />
      </clipPath>
    </defs>
  </svg>
);

export default NineBoxSettingsIcon;
