import React from "react";

const LaborUnionsIcon = (props) => (
    <svg {...props} className={"icon"} width="89" height="95" viewBox="0 0 89 95" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_4114_12026)">
            <path d="M5.84938 30.4561H48.7362C49.8343 30.4561 50.7245 31.3463 50.7245 32.4444V33.8978C50.7245 34.9959 49.8343 35.886 48.7362 35.886H5.84938C4.75131 35.886 3.86115 34.9959 3.86115 33.8978V32.4444C3.86115 31.3463 4.75131 30.4561 5.84938 30.4561Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="2.38588" />
            <path d="M43.59 36.0049C43.5347 37.9989 43.1165 39.9672 42.3547 41.8123C41.5351 43.7976 40.3338 45.6011 38.8197 47.1202C37.3056 48.6393 35.5084 49.844 33.5306 50.6659C31.5529 51.4878 29.4333 51.9108 27.2928 51.9108C25.1524 51.9108 23.0328 51.4878 21.055 50.6659C19.0773 49.844 17.28 48.6393 15.7659 47.1202C14.2518 45.6011 13.0505 43.7976 12.2309 41.8123C11.4691 39.9672 11.0509 37.9989 10.9956 36.0049H43.59Z" stroke="var(--svgColor)" strokeWidth="2.38588" />
            <path d="M27.2923 11.3508H27.2928C32.039 11.3508 36.5906 13.2343 39.9463 16.5866C43.3019 19.9389 45.1869 24.4854 45.1869 29.2257V30.4378H9.39875L9.39875 29.2257L9.39875 29.2252C9.39783 26.8778 9.85997 24.5532 10.7588 22.3843C11.6576 20.2153 12.9755 18.2445 14.6372 16.5844C16.2989 14.9244 18.2718 13.6077 20.4433 12.7097C22.6147 11.8116 24.9421 11.3499 27.2923 11.3508Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="2.38588" />
            <path d="M27.2928 56.5086C34.1619 56.5086 40.7715 59.468 45.6608 64.7723C50.2946 69.7993 53.0186 76.5402 53.2935 83.6491H1.29216C1.56709 76.5402 4.29108 69.7993 8.92489 64.7723C13.8142 59.468 20.4237 56.5086 27.2928 56.5086Z" stroke="var(--svgColor)" strokeWidth="2.38588" />
            <rect x="21.2101" y="18.3714" width="1.79677" height="7.88893" rx="0.898385" fill="#747474" stroke="var(--svgColor)" strokeWidth="0.795292" />
            <rect x="26.3944" y="18.3714" width="1.79677" height="7.88893" rx="0.898383" fill="#747474" stroke="var(--svgColor)" strokeWidth="0.795292" />
            <rect x="31.5786" y="18.3714" width="1.79677" height="7.88893" rx="0.898385" fill="#747474" stroke="var(--svgColor)" strokeWidth="0.795292" />
            <path d="M39.546 30.4561H82.4329C83.531 30.4561 84.4211 31.3463 84.4211 32.4444V33.8978C84.4211 34.9959 83.531 35.886 82.4329 35.886H39.546C38.448 35.886 37.5578 34.9959 37.5578 33.8978V32.4444C37.5578 31.3463 38.448 30.4561 39.546 30.4561Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="2.38588" />
            <path d="M77.2866 36.0049C77.2314 37.9989 76.8132 39.9672 76.0514 41.8123C75.2318 43.7976 74.0305 45.6011 72.5164 47.1202C71.0023 48.6393 69.205 49.844 67.2273 50.6659C65.2495 51.4878 63.1299 51.9108 60.9895 51.9108C58.849 51.9108 56.7294 51.4878 54.7517 50.6659C52.7739 49.844 50.9766 48.6393 49.4625 47.1202C47.9484 45.6011 46.7472 43.7976 45.9275 41.8123C45.1658 39.9672 44.7476 37.9989 44.6923 36.0049H77.2866Z" stroke="var(--svgColor)" strokeWidth="2.38588" />
            <path d="M60.989 11.3508H60.9895C65.7357 11.3508 70.2872 13.2343 73.6429 16.5866C76.9986 19.9389 78.8835 24.4854 78.8835 29.2257V30.4378H43.0954L43.0954 29.2257L43.0954 29.2252C43.0945 26.8778 43.5566 24.5532 44.4554 22.3843C45.3543 20.2153 46.6721 18.2445 48.3338 16.5844C49.9955 14.9244 51.9685 13.6077 54.1399 12.7097C56.3114 11.8116 58.6388 11.3499 60.989 11.3508Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="2.38588" />
            <path d="M60.9895 56.5086C67.8586 56.5086 74.4681 59.468 79.3574 64.7723C83.9912 69.7993 86.7152 76.5402 86.9901 83.6491H34.9888C35.2637 76.5402 37.9877 69.7993 42.6215 64.7723C47.5108 59.468 54.1204 56.5086 60.9895 56.5086Z" fill="#484848" stroke="var(--svgColor)" strokeWidth="2.38588" />
            <rect x="54.9068" y="18.3714" width="1.79677" height="7.88893" rx="0.898385" fill="#747474" stroke="var(--svgColor)" strokeWidth="0.795292" />
            <rect x="60.091" y="18.3714" width="1.79677" height="7.88893" rx="0.898383" fill="#747474" stroke="var(--svgColor)" strokeWidth="0.795292" />
            <rect x="65.2752" y="18.3714" width="1.79677" height="7.88893" rx="0.898385" fill="#747474" stroke="var(--svgColor)" strokeWidth="0.795292" />
        </g>
        <defs>
            <clipPath id="clip0_4114_12026">
                <rect width="88" height="95" fill="white" transform="translate(0.0761719)" />
            </clipPath>
        </defs>
    </svg>
);

export default LaborUnionsIcon;
