import React from "react";

const TaxTableIcon = (props) => (
  <svg {...props} className={"icon"} width="128" height="102" viewBox="0 0 128 102" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50" cy="50" r="45.5" stroke="var(--svgColor)" strokeWidth="3" />
    <path d="M36.521 40.9332V39.317C36.521 38.1556 36.7729 37.0992 37.2766 36.1477C37.7804 35.1962 38.522 34.4336 39.5014 33.8599C40.4809 33.2862 41.6843 32.9994 43.1115 
      32.9994C44.5808 32.9994 45.8051 33.2862 46.7846 33.8599C47.7781 34.4336 48.5197 35.1962 49.0094 36.1477C49.5132 37.0992 49.765 38.1556 49.765 39.317V40.9332C49.765 42.0666 
      49.5132 43.109 49.0094 44.0605C48.5197 45.012 47.7851 45.7746 46.8056 46.3483C45.8261 46.922 44.6157 47.2089 43.1745 47.2089C41.7193 47.2089 40.4949 46.922 39.5014 
      46.3483C38.522 45.7746 37.7804 45.012 37.2766 44.0605C36.7729 43.109 36.521 42.0666 36.521 40.9332ZM40.5299 39.317V40.9332C40.5299 41.4229 40.6209 41.8917 40.8028 
      42.3394C40.9987 42.7732 41.2925 43.13 41.6843 43.4099C42.0901 43.6757 42.5798 43.8087 43.1535 43.8087C43.7552 43.8087 44.2449 43.6757 44.6227 43.4099C45.0005 43.13 
      45.2804 42.7732 45.4623 42.3394C45.6442 41.8917 45.7352 41.4229 45.7352 40.9332V39.317C45.7352 38.7993 45.6372 38.3236 45.4413 37.8898C45.2594 37.442 44.9726 37.0852
      44.5808 36.8194C44.203 36.5395 43.7132 36.3996 43.1115 36.3996C42.5518 36.3996 42.0761 36.5395 41.6843 36.8194C41.2925 37.0852 40.9987 37.442 40.8028 37.8898C40.6209 
      38.3236 40.5299 38.7993 40.5299 39.317ZM51.1293 58.1231V56.507C51.1293 55.3596 51.3812 54.3101 51.8849 53.3586C52.4026 52.4071 53.1512 51.6445 54.1307 51.0708C55.1242 
      50.4971 56.3416 50.2103 57.7828 50.2103C59.238 50.2103 60.4554 50.4971 61.4349 51.0708C62.4143 51.6445 63.156 52.4071 63.6597 53.3586C64.1634 54.3101 64.4153 55.3596 
      64.4153 56.507V58.1231C64.4153 59.2705 64.1634 60.3199 63.6597 61.2714C63.1699 62.2229 62.4353 62.9855 61.4558 63.5592C60.4764 64.1329 59.266 64.4198 57.8248
      64.4198C56.3695 64.4198 55.1452 64.1329 54.1517 63.5592C53.1582 62.9855 52.4026 62.2229 51.8849 61.2714C51.3812 60.3199 51.1293 59.2705 51.1293 58.1231ZM55.1592
      56.507V58.1231C55.1592 58.6268 55.2711 59.1026 55.495 59.5503C55.7189 59.9981 56.0267 60.3549 56.4185 60.6208C56.8243 60.8866 57.293 61.0196 57.8248 
      61.0196C58.4964 61.0196 59.0211 60.8866 59.3989 60.6208C59.7767 60.3549 60.0356 59.9981 60.1755 59.5503C60.3294 59.1026 60.4064 58.6268 60.4064 
      58.1231V56.507C60.4064 56.0032 60.3085 55.5345 60.1126 55.1007C59.9167 54.6529 59.6228 54.2961 59.231 54.0303C58.8532 53.7504 58.3705 53.6105 
      57.7828 53.6105C57.1951 53.6105 56.7054 53.7504 56.3136 54.0303C55.9358 54.2961 55.6489 54.6529 55.453 55.1007C55.2571 55.5345 55.1592 56.0032 55.1592 
      56.507ZM59.273 37.8058L44.3499 61.6912L41.4114 60.138L56.3346 36.2527L59.273 37.8058Z" fill="#FF592C" />
    <path d="M117.292 10H80.2148C79.6626 10 79.2148 10.4477 79.2148 11V23.5828C79.2148 24.1351 79.6626 24.5828 80.2148 24.5828H117.292C117.845 24.5828 118.292 24.1351 118.292 23.5828V11C118.292 10.4477 117.845 10 117.292 10Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
    <path d="M115.078 24.5828H78C77.4477 24.5828 77 25.0305 77 25.5828V38.1656C77 38.7178 77.4477 39.1656 78 39.1656H115.078C115.63 39.1656 116.078 38.7178 116.078 38.1656V25.5828C116.078 25.0305 115.63 24.5828 115.078 24.5828Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
    <path d="M119.706 39.1655H82.6289C82.0766 39.1655 81.6289 39.6132 81.6289 40.1655V52.7483C81.6289 53.3006 82.0766 53.7483 82.6289 53.7483H119.706C120.259 53.7483 120.706 53.3006 120.706 52.7483V40.1655C120.706 39.6132 120.259 39.1655 119.706 39.1655Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
    <path d="M122.574 53.7483H85.4961C84.9438 53.7483 84.4961 54.196 84.4961 54.7483V67.3311C84.4961 67.8834 84.9438 68.3311 85.4961 68.3311H122.574C123.126 68.3311 123.574 67.8834 123.574 67.3311V54.7483C123.574 54.196 123.126 53.7483 122.574 53.7483Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
    <path d="M119.706 68.3311H82.6289C82.0766 68.3311 81.6289 68.7788 81.6289 69.3311V81.9139C81.6289 82.4661 82.0766 82.9139 82.6289 82.9139H119.706C120.259 82.9139 120.706 82.4661 120.706 81.9139V69.3311C120.706 68.7788 120.259 68.3311 119.706 68.3311Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
    <path d="M123.742 82.9138H86.6641C86.1118 82.9138 85.6641 83.3615 85.6641 83.9138V96.4966C85.6641 97.0489 86.1118 97.4966 86.6641 97.4966H123.742C124.294 97.4966 124.742 97.0489 124.742 96.4966V83.9138C124.742 83.3615 124.294 82.9138 123.742 82.9138Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
  </svg>
);

export default TaxTableIcon;
