import React from "react";

const PositionsClassificationIcon = (props) => (
    <svg {...props} className={"icon"} width="179" height="101" viewBox="0 0 179 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M126 2H64C63.4477 2 63 2.44771 63 3V42.8182C63 43.3705 63.4477 43.8182 64 43.8182H126C126.552 43.8182 127 43.3705 127 42.8182V3C127 2.44772 126.552 2 126 2Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
        <path d="M72 27L116 27" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"/>
        <path d="M72 33.5959L116 33.5959" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"/>
        <path d="M84 9H74C72.8954 9 72 9.89543 72 11V20.0101C72 21.1147 72.8954 22.0101 74 22.0101H84C85.1046 22.0101 86 21.1147 86 20.0101V11C86 9.89543 85.1046 9 84 9Z" fill="var(--svgColor)"/>
        <path d="M55 57V51H137V57" stroke="var(--svgColor)"/>
        <path d="M95 44L95 51" stroke="var(--svgColor)"/>
        <path d="M161 57H99C98.4477 57 98 57.4477 98 58V97.8182C98 98.3705 98.4477 98.8182 99 98.8182H161C161.552 98.8182 162 98.3705 162 97.8182V58C162 57.4477 161.552 57 161 57Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
        <path d="M107 82L151 82" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"/>
        <path d="M107 88.5959L151 88.5959" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"/>
        <path d="M119 64H109C107.895 64 107 64.8954 107 66V75.0101C107 76.1147 107.895 77.0101 109 77.0101H119C120.105 77.0101 121 76.1147 121 75.0101V66C121 64.8954 120.105 64 119 64Z" fill="var(--svgColor)"/>
        <path d="M88 57H26C25.4477 57 25 57.4477 25 58V97.8182C25 98.3705 25.4477 98.8182 26 98.8182H88C88.5523 98.8182 89 98.3705 89 97.8182V58C89 57.4477 88.5523 57 88 57Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
        <path d="M34 82L78 82" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"/>
        <path d="M34 88.5959L78 88.5959" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"/>
        <path d="M46 64H36C34.8954 64 34 64.8954 34 66V75.0101C34 76.1147 34.8954 77.0101 36 77.0101H46C47.1046 77.0101 48 76.1147 48 75.0101V66C48 64.8954 47.1046 64 46 64Z" fill="var(--svgColor)"/>
    </svg>
);

export default PositionsClassificationIcon;
