import React from "react";

const LicenceIcon = (props) => (
  <svg {...props} className={"icon"} width="115" height="115" viewBox="0 0 115 115" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3323:10121)">
      <path d="M112.611 1.90629H2.39062V113.094H112.611V1.90629Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M54.8458 14.3988H14.7461V54.8503H54.8458V14.3988Z" fill="#FF0000"/>
      <path d="M34.793 20.8548V49.5762" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M49.0294 35.2155H20.5703" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M66.6133 22.939H101.195" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M66.6133 33.4871H101.195" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M66.6133 44.0352H101.195" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M66.6133 54.5834H101.195" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M12.8945 65.1188H101.194" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M12.8945 75.4001H101.194" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M12.8945 85.6686H101.194" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M12.8945 95.9498H101.194" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    </g>
    <defs>
      <clipPath id="clip0_3323:10121">
        <rect width="114" height="115" fill="white" transform="translate(0.5)"/>
      </clipPath>
    </defs>
  </svg>
);

export default LicenceIcon;
