import React from "react";

const FunctionTypeIcon = (props) => (
    <svg {...props} className={"icon"} width="74" height="92" viewBox="0 0 74 92" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M70.0076 86.5509C70.0078 87.6557 69.1123 88.5513 68.0076 88.5513H5.98828C4.88371 88.5513 3.98828 87.6559 3.98828 86.5513V4.51489C3.98828 3.41033 4.88371 2.51489 5.98828 2.51489H46.9918C47.5128 2.51489 48.0132 2.71819 48.3866 3.08152L69.3899 23.5196C69.7767 23.896 69.995 24.4128 69.9951 24.9525L70.0076 86.5509Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
        <path d="M39.6723 38.576L34.3989 70.462M27.2443 64.1853L18.7079 54.6446L27.2443 46.1082M45.7514 64.1853L55.2921 54.6446L46.7557 46.1082" stroke="var(--svgColor)" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M47.8047 2.51489V22.4402C47.8047 22.9925 48.2524 23.4402 48.8047 23.4402H69.5446" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
    </svg>
);

export default FunctionTypeIcon;
