import React from "react";

const TypesOfPublicationChannelsIcon = (props) => (
  <svg {...props} className={"icon"} width="111" height="96" viewBox="0 0 111 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7352_128)">
      <rect x="18.8387" y="31.8387" width="72.3226" height="50.2581" rx="3.06452" stroke="var(--svgColor)" strokeWidth="3.67742" />
      <path d="M88.7097 30H21.2903C20.1528 30.0013 19.0623 30.4537 18.258 31.258C17.4537 32.0623 17.0013 33.1528 17 34.2903V79.6452C17.0013 80.7826 17.4537 81.8732 18.258 82.6775C19.0623 83.4818 20.1528 83.9342 21.2903 83.9355H46.1497L45.4142 87.6129H40.2903C39.9652 87.6129 39.6534 87.7421 39.4235 87.9719C39.1937 88.2018 39.0645 88.5136 39.0645 88.8387V93.7419C39.0645 94.067 39.1937 94.3788 39.4235 94.6087C39.6534 94.8386 39.9652 94.9677 40.2903 94.9677H69.7097C70.0348 94.9677 70.3466 94.8386 70.5765 94.6087C70.8063 94.3788 70.9355 94.067 70.9355 93.7419V88.8387C70.9355 88.5136 70.8063 88.2018 70.5765 87.9719C70.3466 87.7421 70.0348 87.6129 69.7097 87.6129H64.5858L63.8503 83.9355H88.7097C89.8472 83.9342 90.9377 83.4818 91.742 82.6775C92.5463 81.8732 92.9987 80.7826 93 79.6452V34.2903C92.9987 33.1528 92.5463 32.0623 91.742 31.258C90.9377 30.4537 89.8472 30.0013 88.7097 30ZM68.4839 90.0645V92.5161H41.5161V90.0645H55H68.4839ZM47.9148 87.6129L48.6503 83.9355H61.3497L62.0852 87.6129H47.9148ZM90.5484 79.6452C90.548 80.1327 90.3541 80.6001 90.0094 80.9449C89.6646 81.2896 89.1972 81.4835 88.7097 81.4839H21.2903C20.8028 81.4835 20.3353 81.2896 19.9906 80.9449C19.6459 80.6001 19.452 80.1327 19.4516 79.6452L19.9906 72.2164H91.0874L90.5484 79.6452ZM90.5484 69.2258H19.4516V34.2903C19.452 33.8028 19.6459 33.3353 19.9906 32.9906C20.3353 32.6459 20.8028 32.452 21.2903 32.4516H88.7097C89.1972 32.452 89.6646 32.6459 90.0094 32.9906C90.3541 33.3353 90.548 33.8028 90.5484 34.2903V69.2258Z" fill="var(--svgColor)" />
      <path d="M56.2258 75.3535H53.7742C53.4491 75.3535 53.1373 75.4827 52.9074 75.7125C52.6775 75.9424 52.5484 76.2542 52.5484 76.5793C52.5484 76.9044 52.6775 77.2162 52.9074 77.4461C53.1373 77.676 53.4491 77.8051 53.7742 77.8051H56.2258C56.5509 77.8051 56.8627 77.676 57.0926 77.4461C57.3225 77.2162 57.4516 76.9044 57.4516 76.5793C57.4516 76.2542 57.3225 75.9424 57.0926 75.7125C56.8627 75.4827 56.5509 75.3535 56.2258 75.3535Z" fill="var(--svgColor)" stroke="var(--svgColor)" strokeWidth="2.45161" />
      <path d="M94.5339 18.2617L94.5344 18.2617C96.5659 18.2607 98.5709 18.7331 100.394 19.643C102.217 20.5528 103.81 21.8758 105.048 23.5103C106.287 25.1447 107.137 27.0466 107.534 29.0688C107.93 31.0909 107.862 33.1783 107.334 35.1692C106.806 37.1601 105.832 39.0005 104.49 40.548C103.147 42.0954 101.472 43.3083 99.5931 44.0936C97.7147 44.8789 95.6833 45.2157 93.6563 45.0781C91.6292 44.9406 89.6601 44.3324 87.9015 43.3001L87.39 42.9999L86.9799 43.4285C85.6847 44.7821 84.0435 45.0599 82.6527 44.9752C82.4164 44.9609 82.1897 44.936 81.976 44.9047C82.1996 44.7553 82.4279 44.5887 82.6503 44.4054C83.6722 43.563 84.7836 42.1755 84.3229 40.3461L84.2838 40.1907L84.1845 40.0648C82.627 38.0899 81.6511 35.7087 81.3702 33.1953C81.0893 30.6819 81.515 28.1395 82.5978 25.8611C83.6805 23.5827 85.3759 21.6615 87.4875 20.3176C89.5991 18.9738 92.0415 18.2615 94.5339 18.2617Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="1.5" />
      <path d="M89.8714 24.8137C89.6717 24.8048 89.4705 24.8048 89.2708 24.8197L89.2693 24.8182C89.0769 24.8301 88.8888 24.8808 88.7164 24.9672C88.5868 25.0328 88.4676 25.1282 88.2783 25.307C88.1009 25.4754 87.9981 25.6215 87.8893 25.7615C87.3384 26.4794 87.0427 27.3604 87.0488 28.2652C87.0628 28.9946 87.2309 29.7127 87.5421 30.3725C88.1501 31.7167 89.1516 33.1399 90.4779 34.4589C90.7969 34.7763 91.1083 35.0952 91.4451 35.3918C93.0895 36.8395 95.049 37.8836 97.1678 38.4409C97.1678 38.4409 98.0024 38.5706 98.0143 38.5706C98.2915 38.5855 98.5672 38.5646 98.8429 38.5497C99.2768 38.5277 99.7006 38.4107 100.084 38.2069C100.279 38.1061 100.47 37.9967 100.655 37.8791C100.718 37.8356 100.78 37.7909 100.841 37.7449C101.018 37.6173 101.183 37.4736 101.333 37.3157C101.461 37.1834 101.567 37.0314 101.646 36.8657C101.762 36.6228 101.879 36.1593 101.926 35.7733C101.961 35.4782 101.952 35.3173 101.946 35.2174C101.941 35.0579 101.809 34.8925 101.663 34.8225L100.795 34.4335C100.795 34.4335 99.5001 33.8687 98.7088 33.5066C98.6253 33.4693 98.5344 33.4529 98.445 33.4469C98.3431 33.4365 98.24 33.4479 98.1429 33.4806C98.0457 33.5132 97.9566 33.5663 97.8817 33.6362V33.6332C97.8728 33.6332 97.7744 33.7182 96.6954 35.0252C96.6337 35.108 96.5488 35.1706 96.4514 35.2051C96.354 35.2396 96.2486 35.2444 96.1485 35.2189C96.0516 35.1936 95.9562 35.1593 95.8638 35.1205C95.679 35.0416 95.615 35.0117 95.4883 34.9566L95.4808 34.9536C94.6284 34.5811 93.8385 34.0788 93.1411 33.4633C92.9533 33.2979 92.779 33.1206 92.6001 32.9477C91.9742 32.3397 91.4615 31.6944 91.08 31.058L90.9921 30.9164C90.9295 30.8151 90.861 30.6944 90.8401 30.6109C90.7835 30.3919 90.931 30.216 90.931 30.216C90.931 30.216 91.2931 29.8196 91.4615 29.6035C91.6017 29.4256 91.7325 29.2406 91.8535 29.0491C92.0293 28.766 92.0845 28.4754 91.9921 28.2488C91.5748 27.231 91.1426 26.2161 90.6985 25.2086C90.6106 25.0089 90.3498 24.8644 90.1128 24.8376L90.1128 24.8376C90.0323 24.8286 89.9519 24.8197 89.8714 24.8137Z" fill="var(--svgColor)" />
      <path d="M2.75 26C2.75 25.3096 3.30964 24.75 4 24.75L35 24.75C35.6904 24.75 36.25 25.3096 36.25 26L36.25 45C36.25 45.6904 35.6904 46.25 35 46.25L32.9656 46.25C32.3098 46.25 31.7655 46.7568 31.7187 47.4109L31.4497 51.1778C31.433 51.4114 31.133 51.4959 30.9969 51.3053L28.2085 47.4016C27.6923 46.6789 26.8589 46.25 25.9708 46.25L4 46.25C3.30964 46.25 2.75 45.6904 2.75 45L2.75 26Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="1.5" />
      <path d="M24.2331 31.0498C24.8759 30.6398 25.3569 29.9942 25.5862 29.2335C24.9822 29.6159 24.3213 29.8853 23.6322 30.03C22.6767 28.9516 21.1629 28.6892 19.9366 29.3893C18.7103 30.0895 18.0751 31.5789 18.386 33.0253C15.9118 32.8928 13.6066 31.6458 12.0441 29.5946C11.2286 31.0953 11.6453 33.0136 12.9964 33.9786C12.5078 33.9618 12.0301 33.8207 11.6031 33.567C11.6031 33.5807 11.6031 33.5945 11.6031 33.6083C11.6034 35.1715 12.6359 36.518 14.072 36.8278C13.6188 36.9593 13.1435 36.9787 12.6823 36.8844C13.0861 38.2212 14.2409 39.137 15.5571 39.1644C14.467 40.0772 13.1208 40.5723 11.735 40.5698C11.4894 40.5702 11.244 40.5551 11 40.5247C12.4072 41.4895 14.045 42.0016 15.7177 41.9998C18.0448 42.0168 20.2813 41.038 21.9268 39.2822C23.5723 37.5264 24.4895 35.1402 24.4733 32.6573C24.4733 32.515 24.4702 32.3734 24.464 32.2327C25.0667 31.7679 25.5868 31.1922 26 30.5326C25.4385 30.7982 24.843 30.9725 24.2331 31.0498Z" fill="var(--svgColor)" />
      <path d="M44.5 3.89831C44.5 3.24305 45.0312 2.71186 45.6865 2.71186L71.3136 2.71186C71.9688 2.71186 72.5 3.24305 72.5 3.8983L72.5 21.9322C72.5 22.5875 71.9688 23.1186 71.3136 23.1186L63.4944 23.1186C63.1051 23.1186 62.7406 23.3097 62.5189 23.6297L59.1697 28.4676C59.0753 28.6039 58.8738 28.6039 58.7795 28.4676L55.8548 24.2431C55.3673 23.5389 54.5653 23.1186 53.7088 23.1186L45.6865 23.1186C45.0312 23.1186 44.5 22.5875 44.5 21.9322L44.5 3.89831Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="1.42373" />
      <path d="M59.1111 18H56.6667V10.6667H59.1111V11.8889C59.6322 11.226 60.4223 10.8308 61.2653 10.8115C62.7812 10.8199 64.0044 12.0535 64 13.5694V18H61.5555V13.875C61.4578 13.1922 60.8722 12.6855 60.1824 12.687C59.8807 12.6965 59.5959 12.8286 59.3937 13.0528C59.1915 13.277 59.0895 13.5739 59.1111 13.875V18ZM55.4444 18H53V10.6667H55.4444V18ZM54.2222 9.44444C53.5472 9.44444 53 8.89724 53 8.22222C53 7.54721 53.5472 7 54.2222 7C54.8972 7 55.4444 7.54721 55.4444 8.22222C55.4444 8.54638 55.3157 8.85725 55.0865 9.08646C54.8572 9.31568 54.5464 9.44444 54.2222 9.44444Z" fill="var(--svgColor)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M59.2629 39.3808L61.2223 38.8558L61.4693 39.7773C61.4772 39.8001 61.4843 39.8234 61.4906 39.847L65.0811 53.247L65.0854 53.2459L66.4946 58.505L64.5352 59.0301L64.2753 58.0602C64.2675 58.0374 64.2603 58.0142 64.254 57.9905L63.9135 56.7198L54.184 55.8891L55.2395 59.8283C55.4628 60.6234 55.3585 61.4547 54.9454 62.1702C54.1072 63.6219 52.2443 64.1211 50.7926 63.283C50.0771 62.8699 49.5711 62.2021 49.3669 61.4018L48.2 57.0467L46.578 57.4813C44.955 57.9162 43.2895 56.9547 42.8547 55.3316L42.3301 53.3739C41.8962 51.7547 42.8606 50.0844 44.4798 49.6506L50.8239 47.9507L59.8696 41.6449L59.2629 39.3808ZM60.6721 44.64L60.4274 43.7266L52.4206 49.3081L53.6261 53.8074L63.3557 54.638L60.6764 44.6388L60.6721 44.64ZM46.0534 55.5236C45.5124 55.6686 44.9573 55.3481 44.8123 54.8071L44.2877 52.8494C44.1431 52.3096 44.4646 51.7529 45.0043 51.6083L50.5411 50.1247L51.5902 54.04L46.0534 55.5236ZM53.2874 60.3735C53.3628 60.6394 53.3283 60.9176 53.1901 61.1569C52.9107 61.6408 52.2897 61.8072 51.8058 61.5278C51.5666 61.3897 51.3975 61.166 51.3299 60.898L50.1575 56.5221L52.1147 55.9977L53.2874 60.3735Z" fill="var(--svgColor)" />
      <rect x="68.8322" y="42.0361" width="2.06518" height="4.3858" transform="rotate(57.914 68.8322 42.0361)" fill="var(--svgColor)" />
      <rect x="71.1127" y="48.3208" width="2.06518" height="4.48599" transform="rotate(87.3797 71.1127 48.3208)" fill="var(--svgColor)" />
    </g>
    <defs>
      <clipPath id="clip0_7352_128">
        <rect x="111" y="0.228516" width="95" height="111" rx="2" transform="rotate(90 111 0.228516)" fill="var(--svgColor)" />
      </clipPath>
    </defs>
  </svg>

);

export default TypesOfPublicationChannelsIcon;
