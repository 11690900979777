import React from "react";

const SpecialProcessDefinitionIcon = (props) => (
  <svg {...props} className={"icon"} width="130" height="113" viewBox="0 0 130 113" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4357_11763)">
      <g clipPath="url(#clip1_4357_11763)">
        <path d="M62 110H126" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M77.2774 68.1578H68.2631C67.1585 68.1578 66.2631 69.0533 66.2631 70.1578V108.01C66.2631 109.115 67.1585 110.01 68.2631 110.01H77.2774C78.382 110.01 79.2774 109.115 79.2774 108.01V70.1578C79.2774 69.0533 78.382 68.1578 77.2774 68.1578Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M98.2918 68.1578H89.2775C88.1729 68.1578 87.2775 69.0533 87.2775 70.1578V108.01C87.2775 109.115 88.1729 110.01 89.2775 110.01H98.2918C99.3964 110.01 100.292 109.115 100.292 108.01V70.1578C100.292 69.0533 99.3964 68.1578 98.2918 68.1578Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M119.306 68.1578H110.292C109.187 68.1578 108.292 69.0533 108.292 70.1578V108.01C108.292 109.115 109.187 110.01 110.292 110.01H119.306C120.411 110.01 121.306 109.115 121.306 108.01V70.1578C121.306 69.0533 120.411 68.1578 119.306 68.1578Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M92.4473 43.9572C92.612 43.8513 92.8234 43.8513 92.9882 43.9572L121.049 62.0005C121.181 62.0857 121.234 62.1743 121.258 62.2481C121.287 62.3342 121.292 62.4453 121.258 62.562C121.224 62.6786 121.16 62.7694 121.089 62.8261C121.028 62.8748 120.936 62.9211 120.778 62.9211H64.6571C64.4995 62.9211 64.4077 62.8748 64.3469 62.8261C64.276 62.7694 64.2116 62.6786 64.1774 62.562C64.1431 62.4453 64.1481 62.3342 64.1771 62.2481C64.2019 62.1743 64.2541 62.0857 64.3867 62.0005L92.4473 43.9572Z" stroke="var(--svgColor)" strokeWidth="3" />
      </g>
      <path d="M51.6556 59.6756C51.6558 60.7803 50.7603 61.676 49.6556 61.676H9.34143C8.23686 61.676 7.34143 60.7805 7.34143 59.676V5.69488C7.34143 4.59031 8.23686 3.69489 9.34143 3.69489H35.9373C36.4597 3.69489 36.9613 3.89925 37.335 4.2643L51.0451 17.6587C51.4302 18.0349 51.6473 18.5505 51.6474 19.0888L51.6556 59.6756Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
      <path d="M36.7524 3.69489V16.7968C36.7524 17.349 37.2002 17.7968 37.7524 17.7968H51.345" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
      <path d="M23.509 67.2406C23.8317 72.1614 26.2265 86.8962 43.4694 87.3721L43.4694 81.6202L54 92.3101L43.4694 103L43.4694 98.1723C43.4694 98.1723 16.535 90.748 23.0028 67.1923C23.019 67.133 23.0552 67.0813 23.1051 67.0464C23.155 67.0114 23.2154 66.9954 23.2758 67.0011C23.3362 67.0069 23.3926 67.0341 23.4353 67.0778C23.4779 67.1216 23.504 67.1792 23.509 67.2406Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_4357_11763">
        <rect width="128.341" height="112.298" fill="white" transform="translate(0.906006 0.489746)" />
      </clipPath>
      <clipPath id="clip1_4357_11763">
        <rect width="70" height="71" fill="white" transform="translate(59 42)" />
      </clipPath>
    </defs>
  </svg>
);

export default SpecialProcessDefinitionIcon;
