import React from "react";

const RelatedTablesDefinitionIcon = (props) => (
  <svg {...props} className={"icon"} width="112" height="95" viewBox="0 0 112 95" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5264_16057)">
      <rect x="10.7947" y="53.2112" width="9.02761" height="4.66996" rx="0.797861" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="1.59572" />
      <rect x="26.0947" y="53.2112" width="9.02761" height="4.66996" rx="0.797861" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="1.59572" />
      <rect x="41.3948" y="53.2112" width="9.02761" height="4.66996" rx="0.797861" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="1.59572" />
      <rect x="56.6948" y="53.2112" width="9.02761" height="4.66996" rx="0.797861" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="1.59572" />
      <path fillRule="evenodd" clipRule="evenodd" d="M70.2653 11.2376C70.2653 9.69532 69.015 8.44507 67.4727 8.44507L8.733 8.44507C7.19074 8.44507 5.9405 9.69532 5.9405 11.2376L5.9405 61.0247C5.9405 62.5669 7.19074 63.8172 8.733 63.8172L22.9396 63.8172C22.9459 63.8173 22.9522 63.8173 22.9585 63.8173C22.9647 63.8173 22.971 63.8173 22.9773 63.8172L38.2399 63.8172C38.246 63.8173 38.2522 63.8173 38.2584 63.8173C38.2646 63.8173 38.2708 63.8173 38.277 63.8172L53.5401 63.8172C53.5463 63.8173 53.5525 63.8173 53.5587 63.8173C53.5649 63.8173 53.5711 63.8173 53.5772 63.8172L67.4727 63.8172C69.015 63.8172 70.2653 62.5669 70.2653 61.0247L70.2653 11.2376ZM54.7554 61.4236L67.4727 61.4236C67.6931 61.4236 67.8717 61.245 67.8717 61.0247L67.8717 46.1575L54.7555 46.1575L54.7554 61.4236ZM67.8717 43.7639L67.8717 28.4982L54.7555 28.4982L54.7555 43.7639L67.8717 43.7639ZM67.8717 26.1046L67.8717 11.2376C67.8717 11.0173 67.6931 10.8387 67.4727 10.8387L54.7555 10.8386L54.7555 26.1046L67.8717 26.1046ZM52.3619 10.8386L39.4552 10.8386L39.4552 26.1046L52.3619 26.1046L52.3619 10.8386ZM37.0616 10.8386L24.1552 10.8386L24.1552 26.1046L37.0616 26.1046L37.0616 10.8386ZM21.7617 10.8386L8.733 10.8386C8.51268 10.8386 8.33407 11.0173 8.33407 11.2376L8.33407 26.1046L21.7617 26.1046L21.7617 10.8386ZM8.33407 28.4982L8.33407 43.7639L21.7617 43.7639L21.7617 28.4982L8.33407 28.4982ZM8.33407 46.1575L8.33407 61.0247C8.33407 61.245 8.51268 61.4236 8.733 61.4236L21.7617 61.4236L21.7617 46.1575L8.33407 46.1575ZM24.1552 61.4236L37.0616 61.4236L37.0616 46.1575L24.1552 46.1575L24.1552 61.4236ZM39.4552 61.4236L52.3619 61.4236L52.3619 46.1575L39.4552 46.1575L39.4552 61.4236ZM52.3619 28.4982L52.3619 43.7639L39.4552 43.7639L39.4552 28.4982L52.3619 28.4982ZM37.0616 28.4982L24.1552 28.4982L24.1552 43.7639L37.0616 43.7639L37.0616 28.4982Z" fill="var(--svgColor)" />
      <path d="M105.24 84.9592L105.24 35.1721C105.24 34.2908 104.526 33.5764 103.644 33.5764L44.9046 33.5764C44.0234 33.5764 43.3089 34.2908 43.3089 35.1721L43.3089 84.9592C43.3089 85.8405 44.0234 86.555 44.9046 86.555L103.644 86.555C104.526 86.555 105.24 85.8405 105.24 84.9592Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="2.39358" strokeMiterlimit="10" />
      <rect x="46.7566" y="77.1458" width="9.02761" height="4.66996" rx="0.797861" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="1.59572" />
      <rect x="62.0568" y="77.1458" width="9.02761" height="4.66996" rx="0.797861" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="1.59572" />
      <rect x="77.3567" y="77.1458" width="9.02761" height="4.66996" rx="0.797861" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="1.59572" />
      <rect x="92.6569" y="77.1458" width="9.02761" height="4.66996" rx="0.797861" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="1.59572" />
      <path d="M43.83 68.8953H105.03" stroke="var(--svgColor)" strokeWidth="2.39358" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M43.83 51.2361H105.03" stroke="var(--svgColor)" strokeWidth="2.39358" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M59.1301 86.5552L59.1301 33.5766" stroke="var(--svgColor)" strokeWidth="2.39358" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M74.4301 86.5552L74.4301 33.5766" stroke="var(--svgColor)" strokeWidth="2.39358" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M89.7303 86.5552L89.7303 33.5766" stroke="var(--svgColor)" strokeWidth="2.39358" strokeMiterlimit="10" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_5264_16057">
        <rect x="111.09" width="95" height="111" rx="2" transform="rotate(90 111.09 0)" fill="var(--svgColor)" />
      </clipPath>
    </defs>
  </svg>
);

export default RelatedTablesDefinitionIcon;
