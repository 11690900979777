import React from "react";

const HealthIcon = (props) => (
    <svg {...props} className={"icon"} width="104" height="93" viewBox="0 0 104 93" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M87.9074 2.92921H16.1111C15.0065 2.92921 14.1111 3.82464 14.1111 4.92921V88.0708C14.1111 89.1754 15.0065 90.0708 16.1111 90.0708H87.9074C89.012 90.0708 89.9074 89.1754 89.9074 88.0708V4.92921C89.9074 3.82464 89.012 2.92921 87.9074 2.92921Z" stroke="#E5E5E5" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M2 90.0708H102" stroke="#E5E5E5" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M65 60.4661H39.0186C37.914 60.4661 37.0186 61.3615 37.0186 62.4661V88.0894C37.0186 89.1939 37.914 90.0894 39.0186 90.0894H65C66.1046 90.0894 67 89.1939 67 88.0894V62.4661C67 61.3615 66.1046 60.4661 65 60.4661Z" stroke="#E5E5E5" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M64 16H40C38.8954 16 38 16.8954 38 18V42C38 43.1046 38.8954 44 40 44H64C65.1046 44 66 43.1046 66 42V18C66 16.8954 65.1046 16 64 16Z" fill="#FF4040" />
        <path d="M52 21V41" stroke="#E5E5E5" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M62 31H42" stroke="#E5E5E5" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    </svg>

);

export default HealthIcon;
