import React from "react";

const HolidayTableIcon = (props) => (
    <svg {...props} className={"icon"} width="112" height="95" viewBox="0 0 112 95" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_4178_12029)">
            <path d="M108.076 90.1218L108.076 13.1211C108.076 12.0165 107.181 11.1211 106.076 11.1211L5.69801 11.1211C4.59344 11.1211 3.69801 12.0165 3.69801 13.1211L3.69801 90.1218C3.69801 91.2263 4.59343 92.1218 5.69801 92.1218L106.076 92.1218C107.181 92.1218 108.076 91.2264 108.076 90.1218Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
            <path d="M89.7853 2.71429L89.7853 11.1236" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
            <path d="M67.0432 2.71429L67.0432 11.1236" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
            <path d="M44.3013 2.71429L44.3013 11.1236" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
            <path d="M21.5593 2.71429L21.5593 11.1236" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
            <rect x="59.0762" y="68" width="14" height="14" rx="1" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="2" />
            <rect x="19.0762" y="68" width="14" height="14" rx="1" stroke="var(--svgColor)" strokeWidth="2" />
            <rect x="79.0762" y="68" width="14" height="14" rx="1" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="2" />
            <rect x="39.0762" y="68" width="14" height="14" rx="1" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="2" />
            <rect x="59.0762" y="28" width="14" height="14" rx="1" stroke="var(--svgColor)" strokeWidth="2" />
            <rect x="59.0762" y="48" width="14" height="14" rx="1" stroke="var(--svgColor)" strokeWidth="2" />
            <rect x="79.0762" y="28" width="14" height="14" rx="1" stroke="var(--svgColor)" strokeWidth="2" />
            <rect x="79.0762" y="48" width="14" height="14" rx="1" stroke="var(--svgColor)" strokeWidth="2" />
            <path d="M38.1864 34.8523L38.6394 34.9704L38.9773 34.6465L44.5649 29.2903C44.9073 28.9764 45.3347 28.7672 45.7956 28.6891C46.259 28.6106 46.7353 28.6681 47.1659 28.8542C47.5964 29.0404 47.9621 29.3468 48.2187 29.7353C48.4736 30.121 48.6101 30.5713 48.6125 31.0315C48.6003 31.5245 48.4304 32.0016 48.1265 32.3935L47.9953 32.5627C47.9802 32.5789 47.9633 32.5969 47.9446 32.6166C47.7992 32.7693 47.5825 32.9861 47.3126 33.25C46.7749 33.7758 46.0506 34.4646 45.3213 35.1518C44.5928 35.8382 43.8625 36.5197 43.3141 37.0299C43.04 37.285 42.8115 37.497 42.6515 37.6453L42.4659 37.8173L42.4171 37.8625L42.4046 37.874L42.4015 37.8769L42.4007 37.8776L42.4005 37.8778L42.969 38.4928L42.4005 37.8778L42.0358 38.215L42.1567 38.6967L45.7938 53.1895C45.8685 53.4872 45.7745 53.8019 45.5486 54.0097L43.9007 55.5262C43.4801 55.9132 42.8041 55.7746 42.5697 55.2534L37.6729 44.3627L37.1874 43.283L36.3309 44.1003L29.459 50.6572L29.1996 50.9046V51.2631V55.0105C29.1996 55.2443 29.1019 55.4674 28.9301 55.6259L27.943 56.537C27.5004 56.9455 26.7843 56.7673 26.5848 56.199L25.0531 51.837L24.914 51.4408L24.5132 51.3152L19.8399 49.8514C19.2472 49.6658 19.0557 48.9222 19.485 48.4733L19.9965 47.9385C20.1504 47.7776 20.362 47.6846 20.5846 47.6801L25.7386 47.5748L26.0738 47.568L26.3117 47.3317L32.913 40.7748L33.787 39.9066L32.6582 39.4132L21.1999 34.4049C20.6647 34.171 20.5273 33.4748 20.9335 33.0551L22.681 31.2494C22.8911 31.0323 23.2018 30.9452 23.4941 31.0214L38.1864 34.8523Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="1.675" />
        </g>
        <defs>
            <clipPath id="clip0_4178_12029">
                <rect x="111.076" width="95" height="111" rx="2" transform="rotate(90 111.076 0)" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default HolidayTableIcon;
