import React from "react";

const RatingScalesIcon = (props) => (
  <svg {...props} className={"icon"} width="112" height="96" viewBox="0 0 112 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6430_580)">
      <path d="M81.6761 88.746C81.6763 89.8507 80.7808 90.7464 79.6761 90.7464H17.6568C16.5522 90.7464 15.6568 89.851 15.6568 88.7464V6.70996C15.6568 5.60539 16.5522 4.70996 17.6568 4.70996H58.6603C59.1813 4.70996 59.6817 4.91326 60.0551 5.27659L81.0584 25.7146C81.4452 26.0911 81.6635 26.6078 81.6636 27.1476L81.6761 88.746Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
      <path d="M59.4732 4.70996V24.6353C59.4732 25.1876 59.9209 25.6353 60.4732 25.6353H81.2131" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
      <path d="M39.7839 41.1021H71.7089" stroke="white" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M39.7839 56.9229H71.7089" stroke="white" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M39.7839 74.3408H71.7089" stroke="white" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M28.9471 35.1678C29.2464 34.2465 30.5498 34.2465 30.8492 35.1678L31.4496 37.0157C31.5835 37.4277 31.9674 37.7067 32.4007 37.7067H34.3437C35.3124 37.7067 35.7152 38.9463 34.9315 39.5157L33.3595 40.6578C33.009 40.9124 32.8624 41.3638 32.9963 41.7758L33.5967 43.6237C33.896 44.545 32.8416 45.3112 32.0578 44.7418L30.4859 43.5997C30.1354 43.345 29.6608 43.345 29.3103 43.5997L27.7384 44.7418C26.9547 45.3112 25.9002 44.545 26.1996 43.6237L26.8 41.7758C26.9339 41.3638 26.7872 40.9124 26.4367 40.6578L24.8648 39.5157C24.0811 38.9463 24.4838 37.7067 25.4526 37.7067H27.3956C27.8288 37.7067 28.2128 37.4277 28.3466 37.0157L28.9471 35.1678Z" fill="white" />
      <path d="M28.9471 50.9886C29.2464 50.0673 30.5498 50.0673 30.8492 50.9886L31.4496 52.8365C31.5835 53.2485 31.9674 53.5275 32.4007 53.5275H34.3437C35.3124 53.5275 35.7152 54.7671 34.9315 55.3365L33.3595 56.4786C33.0091 56.7332 32.8624 57.1846 32.9963 57.5966L33.5967 59.4445C33.896 60.3658 32.8416 61.132 32.0579 60.5626L30.4859 59.4205C30.1354 59.1658 29.6608 59.1658 29.3103 59.4205L27.7384 60.5626C26.9547 61.132 25.9002 60.3658 26.1996 59.4445L26.8 57.5966C26.9339 57.1846 26.7872 56.7332 26.4367 56.4786L24.8648 55.3365C24.0811 54.7671 24.4838 53.5275 25.4526 53.5275H27.3956C27.8288 53.5275 28.2128 53.2485 28.3466 52.8365L28.9471 50.9886Z" fill="white" />
      <path d="M28.9471 68.4065C29.2464 67.4852 30.5498 67.4852 30.8492 68.4065L31.4496 70.2545C31.5835 70.6665 31.9674 70.9455 32.4007 70.9455H34.3437C35.3124 70.9455 35.7152 72.1851 34.9315 72.7545L33.3595 73.8965C33.009 74.1512 32.8624 74.6026 32.9963 75.0146L33.5967 76.8625C33.896 77.7838 32.8416 78.5499 32.0578 77.9805L30.4859 76.8385C30.1354 76.5838 29.6608 76.5838 29.3103 76.8385L27.7384 77.9805C26.9547 78.5499 25.9002 77.7838 26.1996 76.8625L26.8 75.0146C26.9339 74.6026 26.7872 74.1512 26.4367 73.8965L24.8648 72.7545C24.0811 72.1851 24.4838 70.9455 25.4526 70.9455H27.3956C27.8288 70.9455 28.2128 70.6665 28.3466 70.2545L28.9471 68.4065Z" fill="white" />
      <rect x="68.8432" y="50.249" width="35" height="35" rx="17.5" fill="#FF4040" />
      <path d="M76.3432 64.7491H79.3432V76.7491H76.3432C76.078 76.7491 75.8236 76.6438 75.6361 76.4562C75.4485 76.2687 75.3432 76.0144 75.3432 75.7491V65.7491C75.3432 65.4839 75.4485 65.2296 75.6361 65.042C75.8236 64.8545 76.078 64.7491 76.3432 64.7491ZM81.6362 63.4561L88.0362 57.0561C88.1212 56.9708 88.2342 56.9191 88.3544 56.9105C88.4746 56.9018 88.5938 56.9369 88.6902 57.0091L89.5432 57.6491C89.7801 57.827 89.959 58.071 90.0574 58.3504C90.1559 58.6298 90.1693 58.9321 90.0962 59.2191L88.9432 63.7491H95.3432C95.8736 63.7491 96.3823 63.9598 96.7574 64.3349C97.1325 64.71 97.3432 65.2187 97.3432 65.7491V67.8531C97.3435 68.1145 97.2925 68.3734 97.1932 68.6151L94.0982 76.1301C94.0227 76.3134 93.8944 76.47 93.7298 76.5802C93.5651 76.6904 93.3713 76.7492 93.1732 76.7491H82.3432C82.078 76.7491 81.8236 76.6438 81.6361 76.4562C81.4485 76.2687 81.3432 76.0144 81.3432 75.7491V64.1631C81.3432 63.8979 81.4486 63.6436 81.6362 63.4561Z" fill="white" />
      <rect x="68.8432" y="50.249" width="35" height="35" rx="17.5" stroke="white" strokeWidth="3" />
    </g>
    <defs>
      <clipPath id="clip0_6430_580">
        <rect x="111.5" y="0.228027" width="95" height="111" rx="2" transform="rotate(90 111.5 0.228027)" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

export default RatingScalesIcon;