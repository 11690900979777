import React from "react";

const PaymentTypesIcon = (props) => (
    <svg {...props} className={"icon"} width="100" height="98" viewBox="0 0 100 98" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.245 66.1594H19.4552C26.8106 59.2711 44.3754 49.3626 55.7917 64.8347H65.9659V64.8347C68.6659 64.8347 70.8547 67.0235 70.8547 69.7236V70.0672C70.8547 72.957 68.5121 75.2996 65.6223 75.2996H40.3322" stroke="var(--svgColor)" strokeWidth="2.41359" strokeLinecap="round" />
        <path d="M13.5092 93.0505H68.3441C71.251 93.0505 78.0426 91.8584 81.9537 87.0895C85.1676 83.1709 92.2892 73.2594 97.1457 66.3954C98.759 64.1152 98.4865 61.0098 96.5139 59.0322V59.0322C94.0067 56.5186 89.869 56.8674 87.6651 59.6507C82.0866 66.6958 73.8962 75.262 65.7795 75.262" stroke="var(--svgColor)" strokeWidth="2.41359" strokeLinecap="round" />
        <mask id="path-3-inside-1_4115_12071" fill="white">
            <rect x="0.923828" y="61.074" width="12.9235" height="36.9261" rx="1.60906" />
        </mask>
        <rect x="0.923828" y="61.074" width="12.9235" height="36.9261" rx="1.60906" stroke="var(--svgColor)" strokeWidth="4.82718" mask="url(#path-3-inside-1_4115_12071)" />
        <path d="M82.6311 22.9865C82.6311 35.018 72.9031 44.7663 60.9095 44.7663C48.9159 44.7663 39.1879 35.018 39.1879 22.9865C39.1879 10.955 48.9159 1.20679 60.9095 1.20679C72.9031 1.20679 82.6311 10.955 82.6311 22.9865Z" stroke="var(--svgColor)" strokeWidth="2.41359" />
        <path d="M58.9165 38.6099V34.9593C56.6966 34.9002 54.5176 34.334 52.5386 33.3021L53.6641 28.8109C55.5822 29.8737 57.7209 30.4503 59.9013 30.4922C62.0585 30.4922 63.5123 29.6275 63.5123 28.0905C63.5123 26.5534 62.3165 25.6887 59.5262 24.752C55.5165 23.3591 52.7731 21.4377 52.7731 17.7151C52.85 15.9816 53.5351 14.3348 54.7025 13.0774C55.8699 11.8199 57.4411 11.0361 59.1275 10.8702V7.2196H62.8089V10.606C64.6898 10.6307 66.5441 11.0645 68.2488 11.8789L67.1233 16.274C65.4567 15.39 63.6071 14.9288 61.7302 14.9291C59.3854 14.9291 58.5179 16.0339 58.5179 17.1146C58.5179 18.1954 59.831 19.2041 63.0199 20.405C67.4985 22.0141 69.2806 24.1276 69.2806 27.6101C69.2831 29.4562 68.5937 31.233 67.3546 32.5737C66.1155 33.9144 64.4212 34.7167 62.6213 34.8152V38.754L58.9165 38.6099Z" fill="#F5A506" />
    </svg>
);

export default PaymentTypesIcon;
