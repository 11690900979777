import React from "react";

const RecognitionPlansIcon = (props) => (
  <svg {...props} className={"icon"} width="112" height="95" viewBox="0 0 112 95" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5702_19065)">
      <path d="M81.3419 51.1891C81.3976 51.0174 81.6404 51.0174 81.6962 51.1891L82.2053 52.756C82.2303 52.8328 82.3018 52.8848 82.3825 52.8848H84.0301C84.2106 52.8848 84.2856 53.1157 84.1396 53.2217L82.8067 54.1902C82.7414 54.2376 82.7141 54.3217 82.739 54.3985L83.2481 55.9654C83.3039 56.1371 83.1075 56.2798 82.9615 56.1737L81.6285 55.2053C81.5632 55.1578 81.4748 55.1578 81.4095 55.2053L80.0766 56.1737C79.9306 56.2798 79.7342 56.1371 79.7899 55.9654L80.2991 54.3985C80.324 54.3217 80.2967 54.2376 80.2314 54.1902L78.8984 53.2217C78.7524 53.1157 78.8275 52.8848 79.0079 52.8848H80.6556C80.7363 52.8848 80.8078 52.8328 80.8327 52.756L81.3419 51.1891Z" fill="var(--svgColor)" />
      <path d="M89.9902 51.1891C90.046 51.0174 90.2888 51.0174 90.3446 51.1891L90.8537 52.756C90.8787 52.8328 90.9502 52.8848 91.0309 52.8848H92.6785C92.859 52.8848 92.934 53.1157 92.788 53.2217L91.455 54.1902C91.3898 54.2376 91.3624 54.3217 91.3874 54.3985L91.8965 55.9654C91.9523 56.1371 91.7558 56.2798 91.6098 56.1737L90.2769 55.2053C90.2116 55.1578 90.1232 55.1578 90.0579 55.2053L88.725 56.1737C88.579 56.2798 88.3825 56.1371 88.4383 55.9654L88.9474 54.3985C88.9724 54.3217 88.9451 54.2376 88.8798 54.1902L87.5468 53.2217C87.4008 53.1157 87.4759 52.8848 87.6563 52.8848H89.3039C89.3846 52.8848 89.4562 52.8328 89.4811 52.756L89.9902 51.1891Z" fill="var(--svgColor)" />
      <path d="M67.4271 56.5083L67.4269 56.5076C66.5675 53.7278 66.0405 49.4138 65.7294 45.0156C65.4633 41.2537 65.3606 37.5244 65.3233 34.8227C70.1862 34.0567 79.4288 32.2091 86.1552 28.4277C92.8817 32.2093 102.121 34.0569 106.987 34.8228C106.95 37.5252 106.847 41.2556 106.581 45.0187C106.269 49.4181 105.742 53.7335 104.881 56.5144L104.881 56.5147C103.318 61.5697 101.102 66.0201 98.3131 69.7563C94.9488 74.2525 90.8638 77.5756 86.1529 79.6828C81.4405 77.5744 77.3546 74.2499 73.9951 69.7529C71.2023 66.0145 68.9863 61.5619 67.4271 56.5083Z" stroke="var(--svgColor)" strokeWidth="2.79426" />
      <path d="M24.598 51.1891C24.6538 51.0174 24.8966 51.0174 24.9524 51.1891L25.4615 52.756C25.4864 52.8328 25.558 52.8848 25.6387 52.8848H27.2863C27.4667 52.8848 27.5418 53.1157 27.3958 53.2217L26.0628 54.1902C25.9975 54.2376 25.9702 54.3217 25.9952 54.3985L26.5043 55.9654C26.5601 56.1371 26.3636 56.2798 26.2176 56.1737L24.8847 55.2053C24.8194 55.1578 24.731 55.1578 24.6657 55.2053L23.3327 56.1737C23.1868 56.2798 22.9903 56.1371 23.0461 55.9654L23.5552 54.3985C23.5802 54.3217 23.5528 54.2376 23.4876 54.1902L22.1546 53.2217C22.0086 53.1157 22.0836 52.8848 22.2641 52.8848H23.9117C23.9924 52.8848 24.0639 52.8328 24.0889 52.756L24.598 51.1891Z" fill="var(--svgColor)" />
      <path d="M6.04918 56.5083L6.04895 56.5076C5.18962 53.7278 4.66262 49.4138 4.35147 45.0156C4.08533 41.2537 3.98265 37.5244 3.94537 34.8227C8.80822 34.0567 18.0509 32.2091 24.7773 28.4277C31.5038 32.2093 40.7434 34.0569 45.6092 34.8228C45.5719 37.5252 45.4692 41.2556 45.2028 45.0187C44.8914 49.4181 44.3638 53.7335 43.5033 56.5144L43.5032 56.5147C41.9405 61.5697 39.7245 66.0201 36.9351 69.7563C33.5709 74.2525 29.4859 77.5756 24.775 79.6828C20.0626 77.5744 15.9767 74.2499 12.6171 69.7529C9.82434 66.0145 7.60835 61.5619 6.04918 56.5083Z" stroke="var(--svgColor)" strokeWidth="2.79426" />
      <path d="M34.0605 48.3666L34.0602 48.3658C33.0781 45.1888 32.4758 40.2585 32.1202 35.2321C31.8161 30.9328 31.6987 26.6707 31.6561 23.583C37.2137 22.7076 47.7767 20.596 55.464 16.2745C63.1514 20.5963 73.711 22.7078 79.2719 23.5832C79.2293 26.6716 79.1119 30.9349 78.8074 35.2356C78.4515 40.2635 77.8485 45.1954 76.8652 48.3735L76.8651 48.3739C75.0792 54.1506 72.5468 59.2365 69.3593 63.5064C65.5143 68.6452 60.8455 72.4434 55.4614 74.8517C50.0757 72.4421 45.4062 68.6427 41.5667 63.5033C38.3749 59.2307 35.8424 54.1421 34.0605 48.3666Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3.19344" />
      <path d="M45.3753 42.2875C45.439 42.0913 45.7165 42.0913 45.7802 42.2875L46.3621 44.0783C46.3906 44.166 46.4723 44.2254 46.5646 44.2254H48.4475C48.6538 44.2254 48.7395 44.4893 48.5727 44.6105L47.0493 45.7173C46.9747 45.7715 46.9435 45.8676 46.972 45.9554L47.5538 47.7462C47.6176 47.9423 47.3931 48.1054 47.2262 47.9842L45.7029 46.8774C45.6282 46.8232 45.5272 46.8232 45.4526 46.8774L43.9292 47.9842C43.7624 48.1054 43.5379 47.9423 43.6016 47.7462L44.1835 45.9554C44.212 45.8676 44.1808 45.7715 44.1061 45.7173L42.5828 44.6105C42.4159 44.4893 42.5017 44.2254 42.7079 44.2254H44.5909C44.6831 44.2254 44.7649 44.166 44.7934 44.0783L45.3753 42.2875Z" fill="#484848" />
      <path d="M55.2591 42.2875C55.3228 42.0913 55.6003 42.0913 55.6641 42.2875L56.2459 44.0783C56.2744 44.166 56.3562 44.2254 56.4484 44.2254H58.3314C58.5376 44.2254 58.6234 44.4893 58.4565 44.6105L56.9332 45.7173C56.8585 45.7715 56.8273 45.8676 56.8558 45.9554L57.4377 47.7462C57.5014 47.9423 57.2769 48.1054 57.1101 47.9842L55.5867 46.8774C55.5121 46.8232 55.4111 46.8232 55.3364 46.8774L53.8131 47.9842C53.6462 48.1054 53.4217 47.9423 53.4855 47.7462L54.0673 45.9554C54.0958 45.8676 54.0646 45.7715 53.99 45.7173L52.4666 44.6105C52.2998 44.4893 52.3855 44.2254 52.5918 44.2254H54.4747C54.567 44.2254 54.6487 44.166 54.6772 44.0783L55.2591 42.2875Z" fill="#484848" />
      <path d="M65.1426 42.2875C65.2064 42.0913 65.4839 42.0913 65.5476 42.2875L66.1295 44.0783C66.158 44.166 66.2397 44.2254 66.332 44.2254H68.2149C68.4212 44.2254 68.5069 44.4893 68.3401 44.6105L66.8167 45.7173C66.7421 45.7715 66.7109 45.8676 66.7394 45.9554L67.3212 47.7462C67.385 47.9423 67.1605 48.1054 66.9936 47.9842L65.4703 46.8774C65.3956 46.8232 65.2946 46.8232 65.22 46.8774L63.6966 47.9842C63.5298 48.1054 63.3053 47.9423 63.369 47.7462L63.9509 45.9554C63.9794 45.8676 63.9482 45.7715 63.8735 45.7173L62.3502 44.6105C62.1833 44.4893 62.2691 44.2254 62.4753 44.2254H64.3583C64.4505 44.2254 64.5323 44.166 64.5608 44.0783L65.1426 42.2875Z" fill="#484848" />
    </g>
    <defs>
      <clipPath id="clip0_5702_19065">
        <rect x="111.09" width="95" height="111" rx="2" transform="rotate(90 111.09 0)" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default RecognitionPlansIcon;
