import React from "react";

const SchedulesProgrammingIcon = (props) => (
  <svg {...props} className={"icon"} width="111" height="96" viewBox="0 0 111 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4757_12442)">
      <path d="M108 90.6218L108 13.6211C108 12.5165 107.105 11.6211 106 11.6211L5.62184 11.6211C4.51727 11.6211 3.62184 12.5165 3.62184 13.6211L3.62184 90.6218C3.62184 91.7263 4.51726 92.6218 5.62184 92.6218L106 92.6218C107.105 92.6218 108 91.7264 108 90.6218Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M89.7091 3.21436L89.7091 11.6237" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M66.9671 3.21436L66.9671 11.6237" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M44.2251 3.21436L44.2251 11.6237" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M21.4832 3.21436L21.4832 11.6237" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <rect x="19" y="68.5" width="14" height="14" rx="1" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="79" y="68.5" width="14" height="14" rx="1" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="39" y="68.5" width="14" height="14" rx="1" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="39" y="28.5" width="14" height="14" rx="1" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="19" y="28.5" width="14" height="14" rx="1" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="2" />
      <path d="M61 55.6726L63.9743 59.2408L73.2677 51.759" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M60.8332 35.9136L63.8075 39.4818L73.1009 32" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M60.8332 75.9136L63.8075 79.4818L73.1009 72" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <rect x="39" y="48.5" width="14" height="14" rx="1" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="19" y="48.5" width="14" height="14" rx="1" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="79" y="28.5" width="14" height="14" rx="1" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="79" y="48.5" width="14" height="14" rx="1" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="2" />
    </g>
    <defs>
      <clipPath id="clip0_4757_12442">
        <rect x="111" y="0.5" width="95" height="111" rx="2" transform="rotate(90 111 0.5)" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SchedulesProgrammingIcon;
