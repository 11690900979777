import React from "react";
import Icon from "./icon";

function PublicationsIcon(props) {
  return (
    <Icon viewBox="0 0 300 300" {...props}>
      <g>
        <g>
          <g>
            <rect x="87.751" y="180.456" width="93.369" height="10.374" />
            <rect x="87.751" y="157.444" width="93.369" height="10.374" />
            <rect x="87.751" y="134.433" width="93.369" height="10.374" />
            <rect x="87.751" y="204.792" width="93.369" height="10.374" />
            <path d="M149.997,0C67.156,0,0,67.156,0,149.997s67.156,149.997,149.997,149.997s149.997-67.156,149.997-149.997
				S232.839,0,149.997,0z M201.641,211.953v18.225c0,10.066-8.159,18.225-18.22,18.225H85.453c-10.066,0-18.225-8.159-18.225-18.225
				V95.763c0-10.066,8.157-18.225,18.225-18.225h97.967c10.066,0,18.223,8.159,18.22,18.228c0.001,0,0.001,18.222,0.001,18.222
				V211.953z M224.986,188.61v18.217c0,8.237-5.467,15.193-12.971,17.45v-12.325v-97.967V95.763
				c0-15.769-12.828-28.599-28.594-28.599H91.349c2.259-7.503,9.21-12.971,17.45-12.971h97.967c10.061,0,18.22,8.162,18.217,18.228
				v18.223h0.003V188.61z"/>
            <rect x="87.751" y="111.418" width="93.369" height="10.374" />
          </g>
        </g>
      </g>
    </Icon>
  );
}
export default PublicationsIcon;
