import React from "react";

const TypesOfObjectivesIcon = (props) => (
  <svg  {...props} className={"icon"} width="112" height="95" viewBox="0 0 112 95" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M32.3406 28.9336H51.4239" stroke="var(--svgColor)" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M32.3406 40.1924H51.4239" stroke="var(--svgColor)" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M32.3406 51.3564H51.4239" stroke="var(--svgColor)" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M32.3406 62.8218H51.4239" stroke="var(--svgColor)" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M62.13 27.8653L64.106 30.2409L70.2802 25.2451" stroke="var(--svgColor)" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M62.13 38.8373L64.106 41.2199L70.2802 36.2241" stroke="var(--svgColor)" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M62.13 51.472L64.106 53.8546L70.2802 48.8589" stroke="var(--svgColor)" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M62.13 62.0957L64.106 64.4713L70.2802 59.4756" stroke="var(--svgColor)" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M67.8862 2H36.3286C35.5949 2 35.0001 2.59478 35.0001 3.32848V14.408C35.0001 15.1417 35.5949 15.7365 36.3286 15.7365H67.8862C68.6199 15.7365 69.2147 15.1417 69.2147 14.408V3.32848C69.2147 2.59478 68.6199 2 67.8862 2Z" stroke="var(--svgColor)" strokeWidth="2.5" strokeMiterlimit="10" />
    <path d="M70.542 4.66113C69.8083 4.66113 69.2135 5.25591 69.2135 5.98961V11.5983C69.2135 12.332 69.8083 12.9268 70.542 12.9268H76.3498C77.0835 12.9268 77.6783 13.5216 77.6783 14.2553V75.4599C77.6783 76.1936 77.0835 76.7884 76.3498 76.7884H26.4952C25.7615 76.7884 25.1667 76.1936 25.1667 75.4599V14.2553C25.1667 13.5216 25.7615 12.9268 26.4952 12.9268H33.6705C34.4042 12.9268 34.999 12.332 34.999 11.5983V5.98961C34.999 5.25591 34.4042 4.66113 33.6705 4.66113H19.6988C18.9651 4.66113 18.3703 5.25591 18.3703 5.98961V83.7256C18.3703 84.4593 18.9651 85.0541 19.6988 85.0541H83.1462C83.8799 85.0541 84.4747 84.4593 84.4747 83.7256V5.98961C84.4747 5.25591 83.8799 4.66113 83.1462 4.66113H70.542Z" stroke="var(--svgColor)" strokeWidth="2.5" strokeMiterlimit="10" />
    <path d="M44.195 8.58838H60.0168" stroke="var(--svgColor)" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" />
    <ellipse cx="83.6675" cy="77.0447" rx="17.4496" ry="17.4622" fill="#FF592C" />
    <mask id="path-14-inside-1_6387_349" fill="var(--svgColor)">
      <path d="M94.2149 73.1834C95.0883 75.5778 95.1227 78.198 94.3126 80.6145C93.5025 83.031 91.8959 85.1003 89.7563 86.4832C87.6167 87.866 85.0711 88.4802 82.5369 88.2252C80.0027 87.9702 77.6303 86.861 75.8087 85.0795C73.987 83.2981 72.8244 80.9501 72.5113 78.4206C72.1982 75.891 72.7533 73.3301 74.0855 71.1577C75.4178 68.9853 77.4481 67.3304 79.8435 66.4644C82.239 65.5983 84.8574 65.5724 87.2695 66.3911L86.5573 68.4927C84.6213 67.8356 82.5197 67.8564 80.597 68.5515C78.6744 69.2466 77.0448 70.5749 75.9755 72.3185C74.9063 74.0621 74.4607 76.1175 74.712 78.1478C74.9633 80.178 75.8965 82.0626 77.3586 83.4924C78.8206 84.9222 80.7248 85.8125 82.7588 86.0172C84.7928 86.2219 86.836 85.7289 88.5532 84.619C90.2705 83.5091 91.56 81.8482 92.2102 79.9087C92.8604 77.9692 92.8327 75.8661 92.1318 73.9444L94.2149 73.1834Z" />
    </mask>
    <path d="M94.2149 73.1834C95.0883 75.5778 95.1227 78.198 94.3126 80.6145C93.5025 83.031 91.8959 85.1003 89.7563 86.4832C87.6167 87.866 85.0711 88.4802 82.5369 88.2252C80.0027 87.9702 77.6303 86.861 75.8087 85.0795C73.987 83.2981 72.8244 80.9501 72.5113 78.4206C72.1982 75.891 72.7533 73.3301 74.0855 71.1577C75.4178 68.9853 77.4481 67.3304 79.8435 66.4644C82.239 65.5983 84.8574 65.5724 87.2695 66.3911L86.5573 68.4927C84.6213 67.8356 82.5197 67.8564 80.597 68.5515C78.6744 69.2466 77.0448 70.5749 75.9755 72.3185C74.9063 74.0621 74.4607 76.1175 74.712 78.1478C74.9633 80.178 75.8965 82.0626 77.3586 83.4924C78.8206 84.9222 80.7248 85.8125 82.7588 86.0172C84.7928 86.2219 86.836 85.7289 88.5532 84.619C90.2705 83.5091 91.56 81.8482 92.2102 79.9087C92.8604 77.9692 92.8327 75.8661 92.1318 73.9444L94.2149 73.1834Z" stroke="var(--svgColor)" strokeWidth="3.72681" strokeLinejoin="round" mask="url(#path-14-inside-1_6387_349)" />
    <mask id="path-15-inside-2_6387_349" fill="var(--svgColor)">
      <path d="M89.4068 77.038C89.4068 78.2343 89.0339 79.4008 88.3402 80.3751C87.6464 81.3493 86.6662 82.0827 85.5363 82.4731C84.4063 82.8636 83.1828 82.8916 82.0361 82.5533C80.8895 82.215 79.8769 81.5272 79.1393 80.5858C78.4017 79.6443 77.9759 78.4961 77.9212 77.3011C77.8666 76.106 78.1857 74.9237 78.8342 73.9187C79.4827 72.9137 80.4283 72.1362 81.5393 71.6945C82.6502 71.2527 83.8712 71.1687 85.0321 71.4542L84.5743 73.3183C83.801 73.1282 82.9877 73.1841 82.2476 73.4784C81.5075 73.7727 80.8776 74.2906 80.4456 74.9601C80.0136 75.6295 79.801 76.4172 79.8374 77.2132C79.8739 78.0093 80.1575 78.7742 80.6488 79.4014C81.1402 80.0285 81.8148 80.4867 82.5786 80.712C83.3424 80.9374 84.1575 80.9187 84.9102 80.6586C85.6629 80.3985 86.3159 79.91 86.778 79.261C87.2402 78.612 87.4886 77.8349 87.4886 77.038H89.4068Z" />
    </mask>
    <path d="M89.4068 77.038C89.4068 78.2343 89.0339 79.4008 88.3402 80.3751C87.6464 81.3493 86.6662 82.0827 85.5363 82.4731C84.4063 82.8636 83.1828 82.8916 82.0361 82.5533C80.8895 82.215 79.8769 81.5272 79.1393 80.5858C78.4017 79.6443 77.9759 78.4961 77.9212 77.3011C77.8666 76.106 78.1857 74.9237 78.8342 73.9187C79.4827 72.9137 80.4283 72.1362 81.5393 71.6945C82.6502 71.2527 83.8712 71.1687 85.0321 71.4542L84.5743 73.3183C83.801 73.1282 82.9877 73.1841 82.2476 73.4784C81.5075 73.7727 80.8776 74.2906 80.4456 74.9601C80.0136 75.6295 79.801 76.4172 79.8374 77.2132C79.8739 78.0093 80.1575 78.7742 80.6488 79.4014C81.1402 80.0285 81.8148 80.4867 82.5786 80.712C83.3424 80.9374 84.1575 80.9187 84.9102 80.6586C85.6629 80.3985 86.3159 79.91 86.778 79.261C87.2402 78.612 87.4886 77.8349 87.4886 77.038H89.4068Z" stroke="var(--svgColor)" strokeWidth="3.72681" strokeLinejoin="round" mask="url(#path-15-inside-2_6387_349)" />
    <mask id="path-16-inside-3_6387_349" fill="var(--svgColor)">
      <path d="M100.296 71.7441C101.477 75.4615 101.382 79.4677 100.024 83.1242C98.6657 86.7808 96.1238 89.8773 92.8031 91.9202C89.4823 93.9632 85.5738 94.8351 81.7004 94.3969C77.827 93.9588 74.2116 92.2358 71.4303 89.5025C68.6491 86.7693 66.8621 83.1831 66.3541 79.3155C65.8461 75.4478 66.6463 71.5213 68.6272 68.1617C70.6082 64.802 73.6558 62.2026 77.2845 60.7776C80.9132 59.3526 84.9141 59.184 88.6496 60.2988L87.984 62.5323C84.7469 61.5663 81.2798 61.7123 78.1353 62.9472C74.9908 64.1821 72.3497 66.4347 70.6331 69.3461C68.9165 72.2575 68.223 75.6601 68.6633 79.0117C69.1035 82.3633 70.6521 85.471 73.0622 87.8396C75.4724 90.2081 78.6054 91.7012 81.962 92.0809C85.3186 92.4606 88.7056 91.7051 91.5833 89.9347C94.461 88.1643 96.6637 85.481 97.8405 82.3123C99.0172 79.1436 99.1003 75.6719 98.0764 72.4505L100.296 71.7441Z" />
    </mask>
    <path d="M100.296 71.7441C101.477 75.4615 101.382 79.4677 100.024 83.1242C98.6657 86.7808 96.1238 89.8773 92.8031 91.9202C89.4823 93.9632 85.5738 94.8351 81.7004 94.3969C77.827 93.9588 74.2116 92.2358 71.4303 89.5025C68.6491 86.7693 66.8621 83.1831 66.3541 79.3155C65.8461 75.4478 66.6463 71.5213 68.6272 68.1617C70.6082 64.802 73.6558 62.2026 77.2845 60.7776C80.9132 59.3526 84.9141 59.184 88.6496 60.2988L87.984 62.5323C84.7469 61.5663 81.2798 61.7123 78.1353 62.9472C74.9908 64.1821 72.3497 66.4347 70.6331 69.3461C68.9165 72.2575 68.223 75.6601 68.6633 79.0117C69.1035 82.3633 70.6521 85.471 73.0622 87.8396C75.4724 90.2081 78.6054 91.7012 81.962 92.0809C85.3186 92.4606 88.7056 91.7051 91.5833 89.9347C94.461 88.1643 96.6637 85.481 97.8405 82.3123C99.0172 79.1436 99.1003 75.6719 98.0764 72.4505L100.296 71.7441Z" stroke="var(--svgColor)" strokeWidth="3.72681" strokeLinejoin="round" mask="url(#path-16-inside-3_6387_349)" />
    <path d="M91.0629 63.0006L92.358 67.3566C92.4001 67.4982 92.5053 67.6124 92.6429 67.6659L96.8511 69.3024C97.036 69.3743 97.2461 69.3245 97.3791 69.1772L100.298 65.9452C100.542 65.6755 100.407 65.2433 100.054 65.1595L96.1284 64.2299C95.9535 64.1884 95.8166 64.0523 95.7741 63.8777L94.8526 60.0892C94.7663 59.7345 94.3303 59.6035 94.0628 59.8519L91.1959 62.514C91.0626 62.6377 91.0111 62.8263 91.0629 63.0006Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="1.8634" />
    <path d="M83.383 76.73L97.6175 62.4624" stroke="var(--svgColor)" strokeWidth="1.8634" strokeLinecap="round" />
  </svg>
);

export default TypesOfObjectivesIcon;