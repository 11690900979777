import React from "react";

const ImportIcon = (props) => (
  <svg {...props} className={"icon"} width="73" height="92" viewBox="0 0 73 92" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M69.5076 86.551C69.5078 87.6557 68.6123 88.5514 67.5076 88.5514H5.48828C4.38371 88.5514 3.48828 87.656 3.48828 86.5514V4.51496C3.48828 3.41039 4.38371 2.51495 5.48828 2.51495H46.4918C47.0128 2.51495 47.5132 2.71825 47.8866 3.08158L68.8899 23.5196C69.2767 23.896 69.495 24.4128 69.4951 24.9526L69.5076 86.551Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
    <path d="M47.3047 2.51495V22.4403C47.3047 22.9926 47.7524 23.4403 48.3047 23.4403H69.0446" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
    <path d="M54 76L59 76C60.1046 76 61 75.1046 61 74L61 55C61 53.8954 60.1046 53 59 53L35 53C33.8954 53 33 53.8954 33 55L33 74C33 75.1046 33.8954 76 35 76L40 76" stroke="var(--svgColor)" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M45.4378 80C45.4378 80.7369 46.0351 81.3342 46.772 81.3342C47.5088 81.3342 48.1062 80.7369 48.1062 80L45.4378 80ZM47.7155 58.0939C47.1944 57.5729 46.3497 57.5729 45.8286 58.0939L37.3379 66.5846C36.8169 67.1057 36.8169 67.9504 37.3379 68.4715C37.8589 68.9925 38.7037 68.9925 39.2247 68.4715L46.772 60.9242L54.3193 68.4715C54.8404 68.9925 55.6851 68.9925 56.2062 68.4715C56.7272 67.9505 56.7272 67.1057 56.2062 66.5847L47.7155 58.0939ZM48.1062 80L48.1062 59.0374L45.4379 59.0373L45.4378 80L48.1062 80Z" fill="var(--svgColor)" />
  </svg>
);

export default ImportIcon;
