import React from "react";

const PublicationIcon = (props) => (
  <svg {...props} className={"icon"} width="121" height="125" viewBox="0 0 121 125" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M102.941 105.128V0.00976562H0V105.128C0 116.031 8.83358 124.901 19.6909 124.901H122.632C111.775 124.901
      102.941 116.032 102.941 105.128ZM19.1032 20.8646H42.4252V28.7758H34.7035V44.971H26.8249V28.7758H19.1032V20.8646ZM85.0012
      95.5816H18.6519V87.6704H85.0012V95.5816ZM85.0012 78.6376H18.6519V70.7264H85.0012V78.6376ZM85.0012
      61.6946H18.6519V53.7834H85.0012V61.6946ZM85.0012 44.7516H46.1312V36.8404H85.0012V44.7516Z"
      fill="var(--icons)"
    />
</svg>

);
export default PublicationIcon;
