import React from "react";

const PeriodsForObjectivesIcon = (props) => (
  <svg {...props} className={"icon"} width="112" height="95" viewBox="0 0 112 95" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6379_230)">
      <path d="M108.391 90.1218L108.391 13.1211C108.391 12.0165 107.496 11.1211 106.391 11.1211L6.01295 11.1211C4.90838 11.1211 4.01295 12.0165 4.01295 13.1211L4.01295 90.1218C4.01295 91.2263 4.90838 92.1218 6.01295 92.1218L106.391 92.1218C107.496 92.1218 108.391 91.2264 108.391 90.1218Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M90.1002 2.71436L90.1002 11.1237" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M67.3582 2.71436L67.3582 11.1237" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M44.6162 2.71436L44.6162 11.1237" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M21.8743 2.71436L21.8743 11.1237" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <rect x="59.3911" y="68" width="14" height="14" rx="1" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="19.3911" y="68" width="14" height="14" rx="1" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="79.3911" y="68" width="14" height="14" rx="1" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="39.3911" y="68" width="14" height="14" rx="1" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="39.3911" y="28" width="14" height="14" rx="1" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="19.3911" y="28" width="14" height="14" rx="1" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="39.3911" y="48" width="14" height="14" rx="1" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="19.3911" y="48" width="14" height="14" rx="1" stroke="var(--svgColor)" strokeWidth="2" />
      <circle cx="78.4324" cy="42.9583" r="17.4041" fill="#F5A506" />
      <mask id="path-16-inside-1_6379_230" fill="var(--svgColor)">
        <path d="M89.0086 39.0644C89.881 41.4544 89.9154 44.07 89.1061 46.4822C88.2969 48.8944 86.692 50.9601 84.5547 52.3404C82.4173 53.7208 79.8744 54.334 77.3428 54.0794C74.8113 53.8248 72.4414 52.7176 70.6217 50.9393C68.802 49.161 67.6405 46.8172 67.3278 44.2922C67.015 41.7671 67.5695 39.2108 68.9004 37.0422C70.2312 34.8737 72.2594 33.2217 74.6523 32.3572C77.0452 31.4927 79.6609 31.4669 82.0704 32.2841L81.359 34.3819C79.425 33.726 77.3256 33.7467 75.405 34.4406C73.4844 35.1345 71.8565 36.4604 70.7884 38.2009C69.7202 39.9414 69.2752 41.9932 69.5262 44.0199C69.7772 46.0465 70.7094 47.9277 72.17 49.355C73.6305 50.7823 75.5326 51.6709 77.5645 51.8753C79.5964 52.0796 81.6374 51.5875 83.3528 50.4796C85.0683 49.3716 86.3564 47.7137 87.0059 45.7776C87.6554 43.8416 87.6278 41.7422 86.9276 39.8239L89.0086 39.0644Z" />
      </mask>
      <path d="M89.0086 39.0644C89.881 41.4544 89.9154 44.07 89.1061 46.4822C88.2969 48.8944 86.692 50.9601 84.5547 52.3404C82.4173 53.7208 79.8744 54.334 77.3428 54.0794C74.8113 53.8248 72.4414 52.7176 70.6217 50.9393C68.802 49.161 67.6405 46.8172 67.3278 44.2922C67.015 41.7671 67.5695 39.2108 68.9004 37.0422C70.2312 34.8737 72.2594 33.2217 74.6523 32.3572C77.0452 31.4927 79.6609 31.4669 82.0704 32.2841L81.359 34.3819C79.425 33.726 77.3256 33.7467 75.405 34.4406C73.4844 35.1345 71.8565 36.4604 70.7884 38.2009C69.7202 39.9414 69.2752 41.9932 69.5262 44.0199C69.7772 46.0465 70.7094 47.9277 72.17 49.355C73.6305 50.7823 75.5326 51.6709 77.5645 51.8753C79.5964 52.0796 81.6374 51.5875 83.3528 50.4796C85.0683 49.3716 86.3564 47.7137 87.0059 45.7776C87.6554 43.8416 87.6278 41.7422 86.9276 39.8239L89.0086 39.0644Z" stroke="var(--svgColor)" strokeWidth="4.04031" strokeLinejoin="round" mask="url(#path-16-inside-1_6379_230)" />
      <mask id="path-17-inside-2_6379_230" fill="var(--svgColor)">
        <path d="M84.2055 42.9126C84.2055 44.1067 83.8331 45.2712 83.14 46.2437C82.447 47.2162 81.4679 47.9483 80.3391 48.338C79.2103 48.7277 77.9881 48.7557 76.8427 48.418C75.6972 48.0803 74.6857 47.3938 73.9489 46.454C73.212 45.5142 72.7867 44.3681 72.7321 43.1751C72.6775 41.9822 72.9963 40.802 73.6441 39.7988C74.2919 38.7956 75.2365 38.0195 76.3463 37.5785C77.4561 37.1376 78.6757 37.0537 79.8354 37.3387L79.3782 39.1995C78.6057 39.0097 77.7932 39.0655 77.0539 39.3593C76.3146 39.653 75.6854 40.1701 75.2538 40.8383C74.8223 41.5066 74.6099 42.2928 74.6463 43.0875C74.6827 43.8821 74.966 44.6457 75.4568 45.2717C75.9476 45.8977 76.6215 46.3551 77.3845 46.58C78.1476 46.805 78.9618 46.7864 79.7137 46.5267C80.4656 46.2671 81.1179 45.7794 81.5795 45.1316C82.0412 44.4838 82.2893 43.7081 82.2893 42.9126H84.2055Z" />
      </mask>
      <path d="M84.2055 42.9126C84.2055 44.1067 83.8331 45.2712 83.14 46.2437C82.447 47.2162 81.4679 47.9483 80.3391 48.338C79.2103 48.7277 77.9881 48.7557 76.8427 48.418C75.6972 48.0803 74.6857 47.3938 73.9489 46.454C73.212 45.5142 72.7867 44.3681 72.7321 43.1751C72.6775 41.9822 72.9963 40.802 73.6441 39.7988C74.2919 38.7956 75.2365 38.0195 76.3463 37.5785C77.4561 37.1376 78.6757 37.0537 79.8354 37.3387L79.3782 39.1995C78.6057 39.0097 77.7932 39.0655 77.0539 39.3593C76.3146 39.653 75.6854 40.1701 75.2538 40.8383C74.8223 41.5066 74.6099 42.2928 74.6463 43.0875C74.6827 43.8821 74.966 44.6457 75.4568 45.2717C75.9476 45.8977 76.6215 46.3551 77.3845 46.58C78.1476 46.805 78.9618 46.7864 79.7137 46.5267C80.4656 46.2671 81.1179 45.7794 81.5795 45.1316C82.0412 44.4838 82.2893 43.7081 82.2893 42.9126H84.2055Z" stroke="var(--svgColor)" strokeWidth="4.04031" strokeLinejoin="round" mask="url(#path-17-inside-2_6379_230)" />
      <mask id="path-18-inside-3_6379_230" fill="var(--svgColor)">
        <path d="M95.0831 37.6272C96.2633 41.338 96.1676 45.3371 94.8111 48.9871C93.4546 52.6372 90.9154 55.7282 87.5982 57.7675C84.2809 59.8069 80.3765 60.6772 76.5072 60.2398C72.6379 59.8024 69.0263 58.0825 66.248 55.3541C63.4697 52.6257 61.6845 49.0459 61.177 45.1852C60.6696 41.3244 61.4689 37.4049 63.4478 34.0512C65.4266 30.6975 68.4711 28.1027 72.0959 26.6802C75.7208 25.2577 79.7175 25.0895 83.4491 26.2023L82.7842 28.4319C79.5505 27.4675 76.0871 27.6133 72.9458 28.846C69.8046 30.0787 67.1664 32.3273 65.4516 35.2335C63.7367 38.1397 63.044 41.5363 63.4838 44.8819C63.9236 48.2276 65.4705 51.3298 67.8781 53.6941C70.2857 56.0585 73.4155 57.5489 76.7685 57.9279C80.1216 58.307 83.5051 57.5528 86.3797 55.7855C89.2543 54.0183 91.4548 51.3397 92.6303 48.1766C93.8058 45.0136 93.8887 41.5481 92.8659 38.3324L95.0831 37.6272Z" />
      </mask>
      <path d="M95.0831 37.6272C96.2633 41.338 96.1676 45.3371 94.8111 48.9871C93.4546 52.6372 90.9154 55.7282 87.5982 57.7675C84.2809 59.8069 80.3765 60.6772 76.5072 60.2398C72.6379 59.8024 69.0263 58.0825 66.248 55.3541C63.4697 52.6257 61.6845 49.0459 61.177 45.1852C60.6696 41.3244 61.4689 37.4049 63.4478 34.0512C65.4266 30.6975 68.4711 28.1027 72.0959 26.6802C75.7208 25.2577 79.7175 25.0895 83.4491 26.2023L82.7842 28.4319C79.5505 27.4675 76.0871 27.6133 72.9458 28.846C69.8046 30.0787 67.1664 32.3273 65.4516 35.2335C63.7367 38.1397 63.044 41.5363 63.4838 44.8819C63.9236 48.2276 65.4705 51.3298 67.8781 53.6941C70.2857 56.0585 73.4155 57.5489 76.7685 57.9279C80.1216 58.307 83.5051 57.5528 86.3797 55.7855C89.2543 54.0183 91.4548 51.3397 92.6303 48.1766C93.8058 45.0136 93.8887 41.5481 92.8659 38.3324L95.0831 37.6272Z" stroke="var(--svgColor)" strokeWidth="4.04031" strokeLinejoin="round" mask="url(#path-18-inside-3_6379_230)" />
      <path d="M86.7918 28.0284L88.1958 32.7509C88.2414 32.9044 88.3554 33.0282 88.5047 33.0862L93.0668 34.8603C93.2673 34.9383 93.4951 34.8843 93.6393 34.7247L96.8041 31.2207C97.0682 30.9283 96.9224 30.4598 96.539 30.369L92.2834 29.3611C92.0937 29.3162 91.9453 29.1687 91.8993 28.9793L90.9002 24.8722C90.8067 24.4876 90.334 24.3455 90.044 24.6149L86.9359 27.5009C86.7915 27.635 86.7356 27.8395 86.7918 28.0284Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="2.02016" />
      <path d="M78.4658 42.9126L93.8977 27.4448" stroke="var(--svgColor)" strokeWidth="2.02016" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_6379_230">
        <rect x="111.391" width="95" height="111" rx="2" transform="rotate(90 111.391 0)" fill="var(--svgColor)" />
      </clipPath>
    </defs>
  </svg>
);

export default PeriodsForObjectivesIcon;