import React from "react";

const RecognitionAnalysisIcon = (props) => (
    <svg {...props} className={"icon"} width="111" height="82" viewBox="0 0 111 82" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M78.8026 54.8649L77.9716 56.2345L79.5124 57.1693L80.2601 55.5296L78.8026 54.8649ZM96.8971 17.9142C96.5875 17.0854 95.6646 16.6646 94.8358 16.9742L81.3302 22.0203C80.5015 22.3299 80.0806 23.2528 80.3903 24.0816C80.6999 24.9103 81.6228 25.3312 82.4516 25.0215L94.4565 20.5362L98.9419 32.5411C99.2516 33.3699 100.174 33.7907 101.003 33.4811C101.832 33.1714 102.253 32.2486 101.943 31.4198L96.8971 17.9142ZM52.8928 39.1444L53.7238 37.7748L52.3155 36.9204L51.5006 38.3519L52.8928 39.1444ZM35.8875 65.7795C35.4498 66.5484 35.7183 67.5265 36.4872 67.9642C37.2561 68.4018 38.2342 68.1334 38.6719 67.3645L35.8875 65.7795ZM80.2601 55.5296L96.854 19.1395L93.9389 17.8102L77.345 54.2003L80.2601 55.5296ZM79.6335 53.4954L53.7238 37.7748L52.0618 40.5139L77.9716 56.2345L79.6335 53.4954ZM51.5006 38.3519L35.8875 65.7795L38.6719 67.3645L54.285 39.9369L51.5006 38.3519Z" fill="#FF4040" />
        <circle cx="52.6319" cy="39.5825" r="5.33981" fill="#FF4040" />
        <circle cx="78.4104" cy="53.9194" r="5.33981" fill="#FF4040" />
        <path d="M19.8357 74.8252H103.334" stroke="var(--svgColor)" strokeWidth="3.20388" strokeLinecap="round" />
        <path d="M19.8357 74.8252L19.8357 23.5879" stroke="var(--svgColor)" strokeWidth="3.20388" strokeLinecap="round" />
        <path d="M7.61508 37.6242L12.5127 27.4953C12.6959 27.1165 13.183 27.0049 13.5128 27.2663L16.3707 29.5314C16.4324 29.5802 16.5022 29.6176 16.5771 29.6418L20.3231 30.8512C20.6792 30.9661 20.8676 31.3548 20.7373 31.7056L17.0069 41.7463C16.8246 42.2369 16.1699 42.3281 15.8605 41.9059L12.8969 37.862C12.7399 37.6478 12.4694 37.5491 12.2113 37.6119L8.36234 38.549C7.82251 38.6804 7.37321 38.1244 7.61508 37.6242Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="1.97197" />
        <path d="M22.8479 41.4003L18.741 30.9404C18.5856 30.5447 18.8411 30.1078 19.2621 30.0492L23.5996 29.4451C23.7002 29.4311 23.7961 29.394 23.8799 29.3367L26.4821 27.5579C26.8099 27.3339 27.26 27.4494 27.4395 27.8036L32.2505 37.3031C32.487 37.77 32.1095 38.3126 31.5895 38.2531L26.6084 37.6837C26.3444 37.6535 26.0883 37.7851 25.9592 38.0173L24.0342 41.4795C23.7642 41.9651 23.0509 41.9175 22.8479 41.4003Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="1.97197" />
        <circle cx="19.651" cy="18.7054" r="12.1232" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="1.97197" />
    </svg>
);

export default RecognitionAnalysisIcon;
