import React from "react";

const TypesOfBenefitsIcon = (props) => (
    <svg {...props} className={"icon"} width="100" height="99" viewBox="0 0 100 99" fill="none" xmlns="http://www.w3.org/2000/svg">
        <svg fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.0101 66.3949H19.2204C26.5757 59.5066 44.1406 49.598 55.5568 65.0702H65.731V65.0702C68.4311 65.0702 70.6199 67.259 70.6199 69.9591V70.3026C70.6199 73.1925 68.2772 75.5351 65.3874 75.5351H40.0973" stroke="var(--svgColor)" strokeWidth="2.41359" strokeLinecap="round" />
            <path d="M13.2744 93.286H68.1093C71.0162 93.286 77.8078 92.0938 81.7189 87.325C84.9328 83.4064 92.0544 73.4948 96.9109 66.6309C98.5242 64.3507 98.2517 61.2453 96.2791 59.2677V59.2677C93.7719 56.7541 89.6342 57.1029 87.4303 59.8862C81.8518 66.9313 73.6614 75.4975 65.5447 75.4975" stroke="var(--svgColor)" strokeWidth="2.41359" strokeLinecap="round" />
            <mask id="path-3-inside-1_5706_19081" fill="white">
                <rect x="0.499985" y="61.3093" width="12.9235" height="36.9261" rx="1.60906" />
            </mask>
            <rect x="0.499985" y="61.3093" width="12.9235" height="36.9261" rx="1.60906" stroke="var(--svgColor)" strokeWidth="4.82718" mask="url(#path-3-inside-1_5706_19081)" />
            <path d="M41.6026 19.1973L41.0197 18.3644L41.6026 19.1973C42.8276 18.3401 43.4038 16.8209 43.0553 15.3669L42.4683 12.9185C42.2602 12.0503 42.8516 11.1936 43.7371 11.0804L46.2345 10.761C47.7176 10.5713 48.9338 9.49379 49.3009 8.04436L49.919 5.60367C50.1382 4.73827 51.0599 4.2545 51.8966 4.56573L54.2564 5.44349C55.6578 5.96476 57.2354 5.57591 58.2341 4.46309L59.9156 2.58923C60.5119 1.92481 61.5528 1.92481 62.1491 2.58923L63.8306 4.46309C64.8293 5.57591 66.4069 5.96476 67.8083 5.44349L70.1681 4.56573C71.0048 4.2545 71.9265 4.73827 72.1457 5.60367L72.7638 8.04436C73.1309 9.49379 74.3471 10.5713 75.8302 10.761L78.3276 11.0804C79.2131 11.1936 79.8045 12.0503 79.5964 12.9185L79.0094 15.3669C78.6609 16.8209 79.2371 18.3401 80.4621 19.1973L82.525 20.6408C83.2565 21.1526 83.382 22.1859 82.7942 22.8579L81.1367 24.7531C80.1524 25.8786 79.9565 27.4916 80.6429 28.8199L81.7987 31.0567C82.2085 31.8498 81.8394 32.8231 81.0067 33.145L78.6584 34.0528C77.2637 34.5919 76.3407 35.9291 76.3312 37.4243L76.3151 39.942C76.3094 40.8347 75.5302 41.525 74.6433 41.423L72.1421 41.1355C70.6567 40.9648 69.2179 41.7199 68.5146 43.0394L67.3304 45.2612C66.9105 46.049 65.8998 46.2981 65.1618 45.7957L63.0807 44.3787C61.8448 43.5372 60.2199 43.5372 58.984 44.3787L56.9029 45.7957C56.1649 46.2981 55.1542 46.049 54.7343 45.2612L53.55 43.0394C52.8468 41.7199 51.408 40.9648 49.9226 41.1355L47.4213 41.423C46.5345 41.525 45.7553 40.8347 45.7496 39.942L45.7335 37.4243C45.724 35.9291 44.801 34.5919 43.4063 34.0528L41.058 33.145C40.2253 32.8231 39.8561 31.8498 40.266 31.0567L41.4218 28.8199C42.1081 27.4916 41.9123 25.8786 40.928 24.7531L39.2705 22.8579C38.6827 22.1859 38.8082 21.1526 39.5397 20.6408L41.6026 19.1973Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="2.13911" />
            <circle cx="61.0324" cy="24.3755" r="13.1108" fill="var(--svgColor)" stroke="var(--svgColor)" strokeWidth="2.13911" />
            <path d="M60.1847 17.6782C60.4515 16.857 61.6133 16.857 61.8801 17.6782L62.9216 20.8837C63.0409 21.251 63.3832 21.4996 63.7693 21.4996H67.1398C68.0032 21.4996 68.3622 22.6045 67.6637 23.112L64.9369 25.0931C64.6245 25.3201 64.4938 25.7224 64.6131 26.0896L65.6546 29.2951C65.9215 30.1163 64.9816 30.7991 64.2831 30.2916L61.5563 28.3105C61.2439 28.0835 60.8209 28.0835 60.5085 28.3105L57.7817 30.2916C57.0832 30.7991 56.1434 30.1163 56.4102 29.2951L57.4517 26.0896C57.571 25.7224 57.4403 25.3201 57.1279 25.0931L54.4011 23.112C53.7026 22.6045 54.0616 21.4996 54.925 21.4996H58.2955C58.6817 21.4996 59.0239 21.251 59.1432 20.8837L60.1847 17.6782Z" fill="#F5A506" />
        </svg>
    </svg>
);

export default TypesOfBenefitsIcon;
