import React from "react";

const ThirdPartyIcon = (props) => (
  <svg {...props} className={"icon"} width="135" height="106" viewBox="0 0 135 106" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M64.4366 35.637H59.6245C58.5946 35.637 57.7596 36.472 57.7596 37.502V65.7992H9.6041V37.502C9.6041 36.472 8.76915 35.637 7.73918 35.637H3.01822L34.4763 6.29291L64.4366 35.637Z" stroke="var(--svgColor)" strokeWidth="2.23791" />
    <mask id="path-2-inside-1_4115_12115" fill="white">
      <rect x="27.2557" y="45.1772" width="16.2465" height="20.4877" rx="0.745971" />
    </mask>
    <rect x="27.2557" y="45.1772" width="16.2465" height="20.4877" rx="0.745971" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="4.47583" mask="url(#path-2-inside-1_4115_12115)" />
    <g clipPath="url(#clip0_4115_12115)">
      <path d="M68.9008 21.2662H129.801" stroke="var(--svgColor)" strokeWidth="2.31685" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M70.0472 65.2855H128.654" stroke="var(--svgColor)" strokeWidth="2.31685" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M123.197 21.2664H75.515C74.662 21.2664 73.9705 21.9579 73.9705 22.8109V63.7302C73.9705 64.5832 74.662 65.2747 75.515 65.2747H123.197C124.05 65.2747 124.741 64.5832 124.741 63.7302V22.8109C124.741 21.9579 124.05 21.2664 123.197 21.2664Z" fill="#484848" stroke="var(--svgColor)" strokeWidth="2.31685" strokeMiterlimit="10" />
      <path d="M110.54 9.1416H87.5973C86.7443 9.1416 86.0527 9.83313 86.0527 10.6862V19.7217C86.0527 20.5747 86.7443 21.2662 87.5973 21.2662H110.54C111.393 21.2662 112.085 20.5747 112.085 19.7217V10.6862C112.085 9.83313 111.393 9.1416 110.54 9.1416Z" fill="#484848" stroke="var(--svgColor)" strokeWidth="2.31685" strokeMiterlimit="10" />
      <path d="M104.666 44.6182H93.4716C92.6185 44.6182 91.927 45.3097 91.927 46.1627V63.7302C91.927 64.5833 92.6185 65.2748 93.4716 65.2748H104.666C105.519 65.2748 106.21 64.5833 106.21 63.7302V46.1627C106.21 45.3097 105.519 44.6182 104.666 44.6182Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="2.31685" strokeMiterlimit="10" />
    </g>
    <path d="M67.0761 82.5578C72.527 82.5578 77.7772 84.9272 81.6646 89.1826C85.3195 93.1835 87.4831 98.5407 87.7317 104.201H46.4205C46.6691 98.5407 48.8327 93.1835 52.4876 89.1826C56.3751 84.9272 61.6252 82.5578 67.0761 82.5578Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="2.19613" />
    <path d="M80.8717 64.7353C80.8717 72.39 74.6912 78.5882 67.0761 78.5882C59.461 78.5882 53.2806 72.39 53.2806 64.7353C53.2806 57.0805 59.461 50.8824 67.0761 50.8824C74.6912 50.8824 80.8717 57.0805 80.8717 64.7353Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="2.19613" />
    <defs>
      <clipPath id="clip0_4115_12115">
        <rect width="64.32" height="59.625" fill="white" transform="translate(67.0762 7.29321)" />
      </clipPath>
    </defs>
  </svg>
);

export default ThirdPartyIcon;
