import React from "react";

const TurnDefinitionIcon = (props) => (
  <svg {...props} className={"icon"} width="112" height="95" viewBox="0 0 112 95" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4757_12383)">
      <path d="M108.576 90.1218L108.576 13.1211C108.576 12.0165 107.681 11.1211 106.576 11.1211L6.19801 11.1211C5.09344 11.1211 4.19801 12.0165 4.19801 13.1211L4.19801 90.1218C4.19801 91.2263 5.09343 92.1218 6.19801 92.1218L106.576 92.1218C107.681 92.1218 108.576 91.2264 108.576 90.1218Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M90.2853 2.71436L90.2853 11.1237" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M67.5432 2.71436L67.5432 11.1237" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M44.8013 2.71436L44.8013 11.1237" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M22.0593 2.71436L22.0593 11.1237" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <rect x="59.5762" y="68" width="14" height="14" rx="1" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="19.5762" y="68" width="14" height="14" rx="1" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="79.5762" y="68" width="14" height="14" rx="1" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="39.5762" y="68" width="14" height="14" rx="1" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="59.5762" y="28" width="14" height="14" rx="1" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="39.5762" y="28" width="14" height="14" rx="1" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="19.5762" y="28" width="14" height="14" rx="1" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="39.5762" y="48" width="14" height="14" rx="1" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="60" y="48" width="14" height="14" rx="1" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="19.5762" y="48" width="14" height="14" rx="1" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="79.5762" y="28" width="14" height="14" rx="1" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="79.5762" y="48" width="14" height="14" rx="1" stroke="var(--svgColor)" strokeWidth="2" />
    </g>
    <defs>
      <clipPath id="clip0_4757_12383">
        <rect x="111.576" width="95" height="111" rx="2" transform="rotate(90 111.576 0)" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default TurnDefinitionIcon;
