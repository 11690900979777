import React from "react";

const GeoanalysisDefinitionIcon = (props) => (
    <svg {...props} className={"icon"} width="73" height="92" viewBox="0 0 73 92" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M69.0193 86.551C69.0195 87.6557 68.124 88.5514 67.0193 88.5514H5C3.89543 88.5514 3 87.656 3 86.5514V4.51496C3 3.41039 3.89543 2.51495 5 2.51495H46.0035C46.5245 2.51495 47.0249 2.71825 47.3983 3.08158L68.4016 23.5196C68.7884 23.896 69.0067 24.4128 69.0068 24.9526L69.0193 86.551Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round"/>
        <path d="M47.3047 2.51495V22.4403C47.3047 22.9926 47.7524 23.4403 48.3047 23.4403H69.0446" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round"/>
        <path d="M52.5 50.5843L52.5 50.5873C52.5033 52.7084 52.0611 54.8108 51.1969 56.772C50.3331 58.7322 49.0641 60.5142 47.4602 62.0115C47.4592 62.0124 47.4581 62.0134 47.4571 62.0144L35.9179 72.6551C35.7273 72.8308 35.434 72.8317 35.2423 72.6572L23.545 62.0087C21.555 60.1468 20.0862 57.8535 19.2614 55.3357C18.4358 52.8154 18.279 50.1427 18.8038 47.5529C19.3286 44.9629 20.5203 42.5292 22.2803 40.4703C24.0407 38.411 26.3156 36.7901 28.9072 35.7586C31.4989 34.7271 34.3223 34.3191 37.1245 34.5736C39.9266 34.8281 42.6143 35.7364 44.9485 37.2127C47.2825 38.6887 49.1881 40.6847 50.5025 43.0173L51.8093 42.2809L50.5025 43.0173C51.8166 45.3493 52.501 47.9485 52.5 50.5843ZM31.5749 54.7065C32.7427 55.4486 34.1082 55.8408 35.4993 55.8415C36.424 55.8419 37.3414 55.6693 38.1997 55.3318C39.058 54.9944 39.8428 54.4976 40.5074 53.8665C41.1721 53.2352 41.7037 52.4815 42.0679 51.6462C42.4322 50.8108 42.6209 49.9124 42.6209 49.0032C42.6209 47.6332 42.193 46.3009 41.4013 45.1749C40.6103 44.0499 39.4943 43.1842 38.2028 42.6758C36.9119 42.1675 35.4953 42.0354 34.1294 42.2933C32.7632 42.5513 31.4982 43.1901 30.4981 44.1399C29.4972 45.0905 28.806 46.3112 28.5248 47.6523C28.2434 48.9941 28.3879 50.3841 28.9362 51.6431C29.484 52.9011 30.4065 53.964 31.5749 54.7065Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="3"/>
    </svg>
);

export default GeoanalysisDefinitionIcon;
