import React from "react";

const ThirdPartyClassificationIcon = (props) => (
  <svg {...props} className={"icon"} width="93" height="98" viewBox="0 0 93 98" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4115_12101)">
      <path d="M46.8031 46.1742H8.58124C5.76187 46.1742 3.47632 48.479 3.47632 51.3222V89.8663C3.47632 92.7095 5.76187 95.0143 8.58124 95.0143H46.8031C49.6225 95.0143 51.908 92.7095 51.908 89.8663V51.3222C51.908 48.479 49.6225 46.1742 46.8031 46.1742Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M43.3956 56C58.0612 56 69.9499 44.0109 69.9499 29.2217C69.9499 14.4325 58.0612 2.44348 43.3956 2.44348C28.7301 2.44348 16.8413 14.4325 16.8413 29.2217C16.8413 44.0109 28.7301 56 43.3956 56Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M87.7884 83.5015L36.8259 83.515C36.489 83.5143 36.1607 83.4141 35.8821 83.2269C35.6035 83.0398 35.3872 82.774 35.2603 82.463C35.1333 82.152 35.1014 81.8096 35.1686 81.4789C35.2358 81.1482 35.3991 80.8439 35.6379 80.6042L61.1259 33.7571C61.2849 33.5981 61.4734 33.4717 61.6807 33.3852C61.8879 33.2986 62.1099 33.2535 62.3338 33.2526C62.5578 33.2517 62.7793 33.2948 62.9859 33.3797C63.1924 33.4645 63.3799 33.5893 63.5376 33.747L89.0149 80.5834C89.2535 80.8213 89.4154 81.1254 89.4799 81.4571C89.5444 81.7887 89.5086 82.1328 89.3771 82.4457C89.2456 82.7585 89.0243 83.026 88.7414 83.214C88.4585 83.402 88.1268 83.5021 87.7884 83.5015Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_4115_12101">
        <rect width="92" height="98" fill="white" transform="translate(0.0761719)" />
      </clipPath>
    </defs>
  </svg>
);

export default ThirdPartyClassificationIcon;
