import React from "react";

const PerformanceFormPeerEvaluationIcon = (props) => (
    <svg {...props} className={"icon"} width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_6468_13106)">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.1774 10.9609L14.1773 10.9609L15.0729 11.8536C15.1251 11.9056 15.1411 11.9802 15.1209 12.0461C15.1412 11.9802 15.1251 11.9056 15.0729 11.8535L14.1774 10.9609ZM13.5354 10.6977C13.5448 10.6745 13.5588 10.6529 13.5775 10.6341L14.7974 9.41023C14.8723 9.33513 14.9938 9.33493 15.0689 9.40979C15.1136 9.4543 15.1318 9.51533 15.1234 9.57328C15.1318 9.51532 15.1136 9.45427 15.0689 9.40975C14.9938 9.33489 14.8723 9.33509 14.7974 9.41019L13.5775 10.6341C13.5588 10.6529 13.5448 10.6745 13.5354 10.6977ZM14.1767 10.5769L14.1767 10.5769L16.2702 10.5735L16.2702 10.5735L14.1767 10.5769ZM15.1238 9.7355L14.362 10.4998L16.3469 10.4965L16.3478 11.0341L14.3629 11.0374L15.1271 11.7991C15.2323 11.9039 15.2326 12.0741 15.1278 12.1793C15.023 12.2844 14.8528 12.2847 14.7476 12.1799L13.5237 10.96C13.4186 10.8552 13.4183 10.685 13.5231 10.5799L14.743 9.35597C14.8478 9.25083 15.018 9.25055 15.1232 9.35535C15.2283 9.46016 15.2286 9.63035 15.1238 9.7355Z" fill="var(--svgColor)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M4.93679 10.9744L6.99039 10.9417L6.99029 10.9418L4.93679 10.9745L4.93679 10.9744ZM6.98421 10.5577L6.98428 10.5577L6.07602 9.67796C6.05164 9.65434 6.03487 9.62583 6.02575 9.59547C6.03486 9.62587 6.05164 9.65442 6.07605 9.67806L6.98421 10.5577ZM7.63471 10.687C7.65403 10.7515 7.63886 10.8242 7.58872 10.8759L6.38644 12.1171C6.31266 12.1933 6.19111 12.1952 6.11494 12.1214C6.09027 12.0975 6.07338 12.0686 6.06434 12.0379C6.07337 12.0687 6.09027 12.0976 6.11497 12.1215C6.19114 12.1953 6.31269 12.1934 6.38647 12.1172L7.58875 10.876C7.63891 10.8242 7.65408 10.7515 7.63471 10.687ZM4.86123 11.0525L6.80626 11.0215L6.05548 11.7966C5.95219 11.9032 5.9549 12.0734 6.06154 12.1767C6.16817 12.28 6.33834 12.2773 6.44163 12.1707L7.64392 10.9295C7.74721 10.8228 7.7445 10.6527 7.63787 10.5494L6.39666 9.34708C6.29003 9.24379 6.11986 9.2465 6.01657 9.35313C5.91328 9.45976 5.91599 9.62994 6.02262 9.73323L6.79769 10.484L4.85266 10.515L4.86123 11.0525Z" fill="var(--svgColor)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.8541 5.9668H9.39453C9.11839 5.9668 8.89453 6.19065 8.89453 6.4668V15.065C8.89453 15.3412 9.11839 15.565 9.39453 15.565H11.8541C12.1302 15.565 12.3541 15.3412 12.3541 15.065V6.4668C12.3541 6.19065 12.1302 5.9668 11.8541 5.9668ZM9.39453 5.2168C8.70418 5.2168 8.14453 5.77644 8.14453 6.4668V15.065C8.14453 15.7554 8.70418 16.315 9.39453 16.315H11.8541C12.5445 16.315 13.1041 15.7554 13.1041 15.065V6.4668C13.1041 5.77644 12.5445 5.2168 11.8541 5.2168H9.39453Z" fill="var(--svgColor)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M4.33456 5.9668H1.875C1.59886 5.9668 1.375 6.19065 1.375 6.4668V15.065C1.375 15.3412 1.59886 15.565 1.875 15.565H4.33456C4.61071 15.565 4.83456 15.3412 4.83456 15.065V6.4668C4.83456 6.19065 4.61071 5.9668 4.33456 5.9668ZM1.875 5.2168C1.18464 5.2168 0.625 5.77644 0.625 6.4668V15.065C0.625 15.7554 1.18464 16.315 1.875 16.315H4.33456C5.02492 16.315 5.58456 15.7554 5.58456 15.065V6.4668C5.58456 5.77644 5.02492 5.2168 4.33456 5.2168H1.875Z" fill="var(--svgColor)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M19.3736 5.9668H16.9141C16.6379 5.9668 16.4141 6.19065 16.4141 6.4668V15.065C16.4141 15.3412 16.6379 15.565 16.9141 15.565H19.3736C19.6498 15.565 19.8736 15.3412 19.8736 15.065V6.4668C19.8736 6.19065 19.6498 5.9668 19.3736 5.9668ZM16.9141 5.2168C16.2237 5.2168 15.6641 5.77644 15.6641 6.4668V15.065C15.6641 15.7554 16.2237 16.315 16.9141 16.315H19.3736C20.064 16.315 20.6236 15.7554 20.6236 15.065V6.4668C20.6236 5.77644 20.064 5.2168 19.3736 5.2168H16.9141Z" fill="var(--svgColor)" />
        </g>
        <defs>
            <clipPath id="clip0_6468_13106">
                <rect width="20" height="20" fill="var(--svgColor)" transform="translate(0.625 0.765625)" />
            </clipPath>
        </defs>
    </svg>
);

export default PerformanceFormPeerEvaluationIcon;
