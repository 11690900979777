import React from "react";

const GoldShieldIcon = (props) => (
  <svg {...props} className={"icon"} width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5678_21080)">
      <path d="M32.6992 64.7246C26.2683 62.2229 20.7791 57.9201 16.4577 52.1358C13.1356 47.6954 10.5698 42.4665 8.79121 36.7116C6.21142 28.367 6.33792 9.91878 6.33664 8.80422C8.3721 8.57876 24.0872 6.66936 32.6992 0.724609C41.3113 6.66936 57.0263 8.57876 59.0618 8.80422C59.0618 9.91878 59.1896 28.3734 56.6047 36.7205C54.8248 42.4729 52.2603 47.7018 48.9382 52.1333C44.6155 57.9188 39.1289 62.2203 32.6992 64.7246Z" fill="url(#paint0_linear_5678_21080)" />
      <path d="M32.6974 64.2787C26.4364 61.8126 21.089 57.6436 16.7868 51.8861C13.5311 47.5284 10.9756 42.3823 9.18678 36.5866C6.9226 29.2598 6.74499 13.9999 6.7501 9.16716C9.71321 8.81432 24.3435 6.83486 32.7 1.21875C41.0565 6.83486 55.6867 8.81432 58.6498 9.16716C58.6498 14.0075 58.4761 29.2675 56.2106 36.5955C54.4218 42.3913 51.8662 47.5361 48.6067 51.8899C44.3032 57.6448 38.9584 61.8126 32.6974 64.2787Z" fill="url(#paint1_linear_5678_21080)" />
      <path d="M32.6977 62.0554C27.1279 59.6976 22.3351 55.8661 18.4431 50.6563C15.3305 46.4898 12.8784 41.5513 11.1599 35.9811C9.20363 29.6529 8.86759 16.8463 8.82031 10.9678C13.6758 10.2697 25.0784 8.23041 32.7002 3.65625C40.322 8.23041 51.7196 10.2697 56.5801 10.9678C56.5329 16.8476 56.1968 29.658 54.238 35.9887C52.5156 41.5602 50.0636 46.4962 46.9548 50.6602C43.0577 55.8687 38.2661 59.6989 32.6977 62.0554Z" fill="url(#paint2_linear_5678_21080)" />
      <path d="M22.8933 16.228C22.9532 16.0437 23.2138 16.0437 23.2737 16.228L23.8203 17.9103C23.8471 17.9927 23.9239 18.0485 24.0105 18.0485H25.7795C25.9732 18.0485 26.0538 18.2965 25.897 18.4103L24.4659 19.4501C24.3958 19.501 24.3665 19.5913 24.3933 19.6737L24.9399 21.356C24.9998 21.5403 24.7889 21.6935 24.6321 21.5797L23.2011 20.5399C23.131 20.489 23.036 20.489 22.9659 20.5399L21.5348 21.5797C21.3781 21.6935 21.1672 21.5403 21.2271 21.356L21.7737 19.6737C21.8005 19.5913 21.7712 19.501 21.7011 19.4501L20.27 18.4103C20.1132 18.2965 20.1938 18.0485 20.3875 18.0485H22.1564C22.2431 18.0485 22.3199 17.9927 22.3467 17.9103L22.8933 16.228Z" fill="#F5A506" />
      <path d="M32.1784 16.228C32.2383 16.0437 32.499 16.0437 32.5589 16.228L33.1055 17.9103C33.1323 17.9927 33.2091 18.0485 33.2957 18.0485H35.0646C35.2584 18.0485 35.3389 18.2965 35.1822 18.4103L33.7511 19.4501C33.681 19.501 33.6517 19.5913 33.6784 19.6737L34.2251 21.356C34.2849 21.5403 34.074 21.6935 33.9173 21.5797L32.4862 20.5399C32.4161 20.489 32.3212 20.489 32.2511 20.5399L30.82 21.5797C30.6633 21.6935 30.4524 21.5403 30.5122 21.356L31.0589 19.6737C31.0856 19.5913 31.0563 19.501 30.9862 19.4501L29.5551 18.4103C29.3984 18.2965 29.4789 18.0485 29.6727 18.0485H31.4416C31.5282 18.0485 31.605 17.9927 31.6318 17.9103L32.1784 16.228Z" fill="#F5A506" />
      <path d="M41.4636 16.228C41.5235 16.0437 41.7842 16.0437 41.844 16.228L42.3907 17.9103C42.4174 17.9927 42.4942 18.0485 42.5809 18.0485H44.3498C44.5435 18.0485 44.6241 18.2965 44.4673 18.4103L43.0363 19.4501C42.9662 19.501 42.9368 19.5913 42.9636 19.6737L43.5102 21.356C43.5701 21.5403 43.3592 21.6935 43.2025 21.5797L41.7714 20.5399C41.7013 20.489 41.6064 20.489 41.5363 20.5399L40.1052 21.5797C39.9484 21.6935 39.7375 21.5403 39.7974 21.356L40.344 19.6737C40.3708 19.5913 40.3415 19.501 40.2714 19.4501L38.8403 18.4103C38.6835 18.2965 38.7641 18.0485 38.9578 18.0485H40.7268C40.8134 18.0485 40.8902 17.9927 40.917 17.9103L41.4636 16.228Z" fill="#F5A506" />
      <path d="M31.8908 28.3815C32.0404 27.9208 32.6921 27.9208 32.8418 28.3815L34.1525 32.4155C34.2195 32.6215 34.4115 32.761 34.6281 32.761H38.8697C39.3541 32.761 39.5554 33.3808 39.1636 33.6655L35.732 36.1587C35.5568 36.286 35.4835 36.5117 35.5504 36.7177L36.8611 40.7517C37.0108 41.2124 36.4836 41.5954 36.0917 41.3107L32.6602 38.8176C32.4849 38.6903 32.2476 38.6903 32.0724 38.8176L28.6408 41.3107C28.249 41.5954 27.7217 41.2124 27.8714 40.7517L29.1822 36.7177C29.2491 36.5117 29.1758 36.286 29.0005 36.1587L25.569 33.6655C25.1771 33.3808 25.3785 32.761 25.8629 32.761H30.1045C30.3211 32.761 30.5131 32.6215 30.58 32.4155L31.8908 28.3815Z" fill="#F5A506" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_5678_21080" x1="17.83" y1="59.1607" x2="39.4082" y2="1.45111" gradientUnits="userSpaceOnUse">
        <stop stopColor="#D7B189" />
        <stop offset="0.28" stopColor="#92562D" />
        <stop offset="0.3" stopColor="#8D5027" />
        <stop offset="0.4" stopColor="#894C26" />
        <stop offset="0.47" stopColor="#824523" />
        <stop offset="0.49" stopColor="#874C29" />
        <stop offset="0.52" stopColor="#955E3A" />
        <stop offset="0.56" stopColor="#AD7C57" />
        <stop offset="0.6" stopColor="#CEA57E" />
        <stop offset="0.61" stopColor="#D7B189" />
        <stop offset="0.77" stopColor="#AB6C2E" />
        <stop offset="0.83" stopColor="#9C530D" />
        <stop offset="0.85" stopColor="#9F5712" />
        <stop offset="0.89" stopColor="#A66322" />
        <stop offset="0.92" stopColor="#B2773C" />
        <stop offset="0.96" stopColor="#C39260" />
        <stop offset="1" stopColor="#D7B189" />
      </linearGradient>
      <linearGradient id="paint1_linear_5678_21080" x1="18.0224" y1="58.7912" x2="39.2855" y2="1.92679" gradientUnits="userSpaceOnUse">
        <stop stopColor="#D7B189" />
        <stop offset="0.2" stopColor="#C88500" />
        <stop offset="0.26875" stopColor="#F5A506" />
        <stop offset="0.33" stopColor="#F5A506" />
        <stop offset="0.42" stopColor="#A87000" />
        <stop offset="0.4875" stopColor="#FFBD3B" />
        <stop offset="0.62" stopColor="#FFF2DB" />
        <stop offset="0.66" stopColor="#D3AB7E" />
        <stop offset="0.71" stopColor="#F5A506" />
        <stop offset="1" stopColor="#D7B189" />
      </linearGradient>
      <linearGradient id="paint2_linear_5678_21080" x1="8.81776" y1="32.8552" x2="56.5776" y2="32.8552" gradientUnits="userSpaceOnUse">
        <stop />
        <stop offset="0.02" stopColor="#060606" />
        <stop offset="0.1" stopColor="#111111" />
        <stop offset="0.21" stopColor="#181818" />
        <stop offset="0.5" stopColor="#1A1A1A" />
        <stop offset="0.79" stopColor="#181818" />
        <stop offset="0.9" stopColor="#111111" />
        <stop offset="0.98" stopColor="#060606" />
        <stop offset="1" />
      </linearGradient>
      <clipPath id="clip0_5678_21080">
        <rect width="64" height="64" fill="white" transform="translate(0.203125 0.724609)" />
      </clipPath>
    </defs>
  </svg>

);

export default GoldShieldIcon;
