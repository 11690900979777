import React from "react";

const TreeOrgChartIcon = (props) => (
    <svg
        {...props}
        className={"icon"}
        id="Capa_1"
        enableBackground="new 0 0 512 512"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg">
        <g>
            <path d="m135 362h-120c-8.284 0-15 6.716-15 15v120c0 8.284 6.716 15 15 15h120c8.284 0 15-6.716 15-15v-120c0-8.284-6.716-15-15-15z" /><path d="m90 332v-41h151v41h30v-41h151v41h30v-56c0-8.284-6.716-15-15-15h-166v-41h-30v41h-166c-8.284 0-15 6.716-15 15v56z" /><path d="m176 190h160c8.284 0 15-6.716 15-15v-160c0-8.284-6.716-15-15-15h-160c-8.284 0-15 6.716-15 15v160c0 8.284 6.716 15 15 15z" /><path d="m316 362h-120c-8.284 0-15 6.716-15 15v120c0 8.284 6.716 15 15 15h120c8.284 0 15-6.716 15-15v-120c0-8.284-6.716-15-15-15z" /><path d="m497 362h-120c-8.284 0-15 6.716-15 15v120c0 8.284 6.716 15 15 15h120c8.284 0 15-6.716 15-15v-120c0-8.284-6.716-15-15-15z" />
        </g>
    </svg>
);

export default TreeOrgChartIcon;
