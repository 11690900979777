import React from "react";

function Icon(props) {
  const { color, width, height, viewBox, className, id } = props;

  return (
    <svg
      fill={color}
      height={height}
      width={width}
      viewBox={viewBox}
      className={"icon " + className}
      id={id}>
      {props.children}
    </svg>
  );
}

export default Icon;
