import React from "react";

const WorkplacesIcon = (props) => (
    <svg {...props} className={"icon"} width="84" height="95" viewBox="0 0 84 95" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3359_10648)">
            <path d="M80.5 41.5187V41.5215C80.5073 46.4298 79.5348 51.2902 77.6394 55.8177C75.7447 60.3437 72.9658 64.446 69.4656 67.8841C69.4645 67.8852 69.4633 67.8864 69.4622 67.8875L44.0216 92.5803C43.8285 92.7677 43.5217 92.7687 43.3274 92.5825L17.5489 67.8817C13.2045 63.6062 9.98333 58.3234 8.17185 52.5029C6.35942 46.6794 6.01433 40.498 7.16727 34.5089C8.3202 28.5197 10.9354 22.9085 14.7802 18.1743C18.6251 13.4401 23.5803 9.72976 29.205 7.37337C34.8298 5.01699 40.9496 4.0876 47.0202 4.66788C53.0908 5.24815 58.9239 7.32011 64.0005 10.6994C69.0772 14.0788 73.2399 18.6607 76.1185 24.0376C78.9971 29.4144 80.5022 35.4196 80.5 41.5187ZM36.1719 49.0213C38.3405 50.4718 40.8904 51.2467 43.4992 51.248C45.2322 51.2488 46.9483 50.9082 48.5496 50.2455C50.1509 49.5828 51.6059 48.6111 52.8315 47.3859C54.0572 46.1607 55.0294 44.706 55.6928 43.1049C56.3561 41.5038 56.6975 39.7877 56.6975 38.0546C56.6975 35.4455 55.924 32.895 54.4748 30.7255C53.0256 28.556 50.9657 26.8649 48.5555 25.8661C46.1454 24.8673 43.4932 24.6057 40.9344 25.1142C38.3756 25.6228 36.025 26.8787 34.1798 28.7232C32.3347 30.5677 31.0778 32.918 30.5681 35.4768C30.0583 38.0356 30.3187 40.6881 31.3161 43.099C32.3136 45.5098 34.0034 47.5708 36.1719 49.0213Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="3" />
        </g>
        <defs>
            <clipPath id="clip0_3359_10648">
                <rect width="84" height="95" fill="white" />
            </clipPath>
        </defs>
    </svg>


);

export default WorkplacesIcon;
