import React from "react";

const NoGroupsSvg = (props) => (
    <svg
    {...props}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="233"
    height="130"
    className={"icon"}
    viewBox="0 0 233 130">
        <path fillRule="evenodd" clipRule="evenodd" d="M155.873 99.3021C139.277 94.1136 137.671 93.6813 137.671 93.6813C136.065 91.5194 134.459 89.7899 131.782 88.0604C130.176 87.628 131.247 85.4662 131.782 85.0338C132.318 84.6014 134.459 85.0338 134.459 77.2511C134.459 74.6569 133.924 74.6569 135.53 74.6569C137.136 74.6569 140.348 72.9274 140.348 66.8742C140.348 60.8209 140.348 60.3886 139.277 60.3886C139.277 58.2267 140.883 54.3353 140.883 45.6879C139.277 37.0404 134.994 32.2843 131.247 30.5548C130.176 29.6901 129.641 28.393 129.105 27.0959C124.823 27.5282 120.54 29.2577 116.257 29.2577C100.196 29.2577 91.6304 37.0404 91.6304 45.6879C91.6304 54.3353 93.2365 57.7943 93.2365 60.3886C92.1657 60.3886 92.1657 60.8209 92.1657 66.8742C92.1657 72.9274 95.9132 74.6569 97.5193 74.6569C99.1254 74.6569 98.59 74.6569 98.59 77.2511C98.59 85.0338 100.731 84.1691 101.267 85.0338C101.802 85.8986 102.873 87.628 101.267 88.0604C99.1254 89.7899 96.984 91.5194 95.3779 93.6813C95.3779 93.6813 94.3072 94.1136 77.1757 99.3021C62.721 102.761 53.6199 114.003 55.226 125.677H178.358C179.429 114.003 170.328 102.761 155.873 99.3021Z" fill={"var(--svgColor)"} />
        <path fillRule="evenodd" clipRule="evenodd" d="M86.5765 67.7782C83.1406 66.7233 82.6283 66.5854 82.6283 66.5854C81.0222 64.4236 79.4161 62.6941 76.7393 60.9646C75.1332 60.5322 76.204 58.3703 76.7393 57.938C76.8158 57.8762 76.9251 57.8321 77.0562 57.7791C77.8428 57.4615 79.4161 56.8261 79.4161 50.1552C79.4161 49.5067 79.3827 49.0203 79.3576 48.6555C79.3211 48.1249 79.3023 47.8515 79.4298 47.7107C79.5653 47.561 79.8662 47.561 80.4868 47.561C82.0929 47.561 85.3051 45.8315 85.3051 39.7783C85.3051 33.7251 85.3051 33.2927 84.2343 33.2927C84.2343 32.5721 84.4128 31.6593 84.6507 30.4422C85.1266 28.0081 85.8404 24.357 85.8404 18.592C84.2344 9.94457 79.9515 5.18847 76.2039 3.45898C75.1332 2.59423 74.5979 1.29712 74.0625 0C72.227 0.185303 70.3915 0.608852 68.556 1.0324C66.1086 1.59713 63.6613 2.16186 61.2139 2.16186C45.1531 2.16186 36.5874 9.94457 36.5874 18.592C36.5874 24.1792 37.2579 27.6005 37.7324 30.022C37.9923 31.3483 38.1935 32.3746 38.1935 33.2927C37.1228 33.2927 37.1228 33.7251 37.1228 39.7783C37.1228 45.8315 40.8703 47.561 42.4764 47.561C43.097 47.561 43.3978 47.561 43.5334 47.7107C43.6609 47.8515 43.6421 48.1249 43.6056 48.6554C43.5805 49.0203 43.5471 49.5067 43.5471 50.1552C43.5471 56.2926 44.8788 57.0524 45.7044 57.5233C45.9257 57.6496 46.1107 57.7551 46.2239 57.938C46.7592 58.8027 47.8299 60.5322 46.2239 60.9646C44.0824 62.6941 41.941 64.4236 40.3349 66.5854C40.3349 66.5854 39.2642 67.0178 22.1327 72.2063C7.67803 75.6652 -1.42307 86.9069 0.183008 98.581H47.6474C53.3617 93.9101 59.8937 90.5937 66.6353 88.6702L66.5712 88.6361C66.7847 88.5982 67.0074 88.5536 67.2384 88.502C67.6459 88.391 68.054 88.285 68.4627 88.1841C74.441 86.4255 84.1535 80.678 86.5426 67.9543C86.5537 67.8951 86.565 67.8364 86.5765 67.7782ZM110.125 75.8697L113.171 97.4997H108.618C108.971 97.8544 109.313 98.215 109.645 98.581H123.316C124.133 89.6682 119.021 81.0075 110.125 75.8697Z" fill={"var(--svgColor)"} />
        <path fillRule="evenodd" clipRule="evenodd" d="M208.849 72.2063C192.253 67.0178 190.647 66.5854 190.647 66.5854C189.041 64.4235 187.435 62.6941 184.758 60.9646C183.152 60.5322 184.223 58.3703 184.758 57.938C184.834 57.8762 184.944 57.8321 185.075 57.7791C185.861 57.4615 187.435 56.8261 187.435 50.1552C187.435 49.5067 187.401 49.0203 187.376 48.6555C187.34 48.1249 187.321 47.8515 187.448 47.7107C187.584 47.561 187.885 47.561 188.505 47.561C190.111 47.561 193.324 45.8315 193.324 39.7783C193.324 33.7251 193.324 33.2927 192.253 33.2927C192.253 32.5721 192.431 31.6593 192.669 30.4422C193.145 28.0081 193.859 24.357 193.859 18.592C192.253 9.94457 187.97 5.18847 184.223 3.45898C183.152 2.59423 182.616 1.29712 182.081 0C180.246 0.185303 178.41 0.608851 176.575 1.0324C174.127 1.59713 171.68 2.16186 169.232 2.16186C153.172 2.16186 144.606 9.94457 144.606 18.592C144.606 24.1792 145.276 27.6005 145.751 30.022C146.011 31.3483 146.212 32.3746 146.212 33.2927C145.141 33.2927 145.141 33.7251 145.141 39.7783C145.141 45.8315 148.889 47.561 150.495 47.561C151.116 47.561 151.416 47.561 151.552 47.7107C151.679 47.8515 151.661 48.1249 151.624 48.6554C151.599 49.0203 151.566 49.5067 151.566 50.1552C151.566 56.2926 152.897 57.0523 153.723 57.5233C153.944 57.6496 154.129 57.7551 154.242 57.938C154.778 58.8027 155.848 60.5322 154.242 60.9646C152.101 62.6941 149.96 64.4235 148.353 66.5854C148.353 66.5854 148.135 66.6738 146.31 67.2506C146.362 67.4775 146.411 67.712 146.456 67.9543C148.505 78.8634 155.937 84.6443 161.783 87.1797C169.582 89.0599 177.082 92.9532 183.295 98.581H231.334C232.405 86.9069 223.304 75.6652 208.849 72.2063ZM123.314 97.4997H119.828L123.036 74.7206C112.821 79.6419 106.879 88.9647 108.202 98.581H122.368C122.674 98.2147 122.99 97.8542 123.314 97.4997Z" fill={"var(--svgColor)"} />
    </svg>
);

export default NoGroupsSvg;
