import React from "react";

const ImagePlaceholder = (props) => (
    <svg
        {...props}
        width="46"
        height="35"
        viewBox="0 0 46 35"
        xmlns="http://www.w3.org/2000/svg">
        <path            
            d="M46 2.37662V32.6234C46 33.6169 45.2278 34.5 44.1247 34.5H1.8753C0.882491 34.5 0 33.7273 0 32.6234V2.37662C0
            1.38312 0.772179 0.5 1.8753 0.5H44.1247C45.1175 0.5 46 1.27273 46 2.37662ZM42.6907 4.14286C42.6907 3.7013
            42.3597 3.37013 41.9185 3.37013H3.86091C3.41966 3.37013 3.08872 3.7013 3.08872 4.14286V19.2662C3.08872
            20.2597 11.0312 8.77922 11.0312 8.77922C11.0312 8.77922 17.4293 19.3766 22.6139 25.0065C22.9448 25.1169
            23.2758 25.1169 23.717 25.2273C25.1511 23.2403 28.4604 18.7143 30.3357 16.1753C32.1007 18.1623 35.0791
            23.1299 37.7266 26C40.5947 26.1104 42.5803 26.1104 42.5803 26.1104L42.6907 4.14286ZM35.2998 13.4156C33.4245
            13.4156 31.9904 11.9805 31.9904 10.1039C31.9904 8.22727 33.4245 6.79221 35.2998 6.79221C37.1751 6.79221
            38.6091 8.22727 38.6091 10.1039C38.6091 11.9805 37.1751 13.4156 35.2998 13.4156Z"
        />
    </svg>
);

export default ImagePlaceholder;
