import React from "react";

const UserMenusIcon = (props) => (
  <svg {...props} className={"icon"} width="112" height="96" viewBox="0 0 112 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6355_175)">
      <rect x="2.31494" y="33" width="95" height="30" fill="#FF4040" />
      <path d="M28.8447 16L72.6176 16" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <circle cx="14.3149" cy="16" r="6" fill="var(--svgColor)" />
      <circle cx="14.3149" cy="48" r="6" fill="var(--svgColor)" />
      <circle cx="14.3149" cy="79" r="6" fill="var(--svgColor)" />
      <path d="M28.8447 48L72.6176 48" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M28.8447 79L72.6176 79" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <rect x="75.3149" y="15.5" width="34" height="35" rx="17" fill="#F5A506" />
      <path d="M90.6072 40.2367C90.6825 40.2099 90.7504 40.1657 90.8055 40.1078L90.8095 40.1118L101.804 29.0021C102.457 28.1657 102.773 27.115 102.691 26.0566C102.609 24.9982 102.135 24.0089 101.362 23.2832C99.8158 21.8203 97.4638 21.7511 95.8275 23.008L84.7472 34.0332L84.7517 34.0371C84.6941 34.0923 84.65 34.1601 84.6231 34.2353L81.9526 42.2513C81.9267 42.329 81.9196 42.4118 81.9319 42.4928C81.9442 42.5739 81.9755 42.6508 82.0233 42.7173C82.0711 42.7838 82.1339 42.838 82.2067 42.8754C82.2795 42.9128 82.3601 42.9323 82.4419 42.9324C82.4469 42.9324 82.4514 42.9324 82.4565 42.9324H96.0076C96.2931 42.9324 96.5246 42.7009 96.5246 42.4154C96.5246 42.1298 96.2931 41.8984 96.0076 41.8984H85.6269L90.6072 40.2367ZM83.7355 40.1658L84.6905 41.1227L83.2603 41.6L83.7355 40.1658ZM85.842 34.3979L93.1199 27.1064L93.99 27.9771L86.7088 35.2682L85.842 34.3979ZM94.7203 28.7078L96.1246 30.115L88.8445 37.4099L87.4402 35.9994L94.7203 28.7078ZM96.8549 30.8467L97.7256 31.7181L90.4443 39.0136L89.5742 38.1406L96.8549 30.8467ZM98.4547 30.9869L93.8485 26.3769L95.7881 24.4349L100.391 29.0506L98.4547 30.9869ZM100.653 24.0353C101.223 24.5704 101.579 25.2945 101.656 26.073C101.734 26.8516 101.526 27.6317 101.073 28.2687L96.5527 23.7369C97.1663 23.3069 97.909 23.1018 98.6559 23.1561C99.4027 23.2104 100.108 23.5209 100.653 24.0353ZM85.3376 35.3548L89.4894 39.5202L85.7819 40.7585L84.1001 39.0699L85.3376 35.3548Z" fill="var(--svgColor)" />
    </g>
    <defs>
      <clipPath id="clip0_6355_175">
        <rect x="111.315" y="0.392578" width="95" height="111" rx="2" transform="rotate(90 111.315 0.392578)" fill="var(--svgColor)" />
      </clipPath>
    </defs>
  </svg>
);

export default UserMenusIcon;
