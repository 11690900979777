import React from "react";

const PerformanceFormsIcon = (props) => (
  <svg {...props} className={"icon"} width="112" height="95" viewBox="0 0 112 95" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6530_22851)">
      <path d="M80.7336 88.0512C80.7339 89.1559 79.8384 90.0516 78.7336 90.0516H16.7144C15.6098 90.0516 14.7144 89.1561 14.7144 88.0516V6.01514C14.7144 4.91057 15.6098 4.01514 16.7144 4.01514H57.7179C58.2388 4.01514 58.7393 4.21843 59.1126 4.58177L80.116 25.0198C80.5028 25.3962 80.721 25.913 80.7212 26.4528L80.7336 88.0512Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
      <path d="M58.5308 4.01514V23.9405C58.5308 24.4928 58.9785 24.9405 59.5308 24.9405H80.2707" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
      <path d="M72.6631 46.525L73.8875 38.1854L96.7184 15.3544L99.9414 12.1315C100.242 11.8313 100.728 11.8313 101.029 12.1315L108.365 19.4679C108.665 19.7681 108.665 20.2548 108.365 20.5551L105.142 23.778L82.3111 46.609L73.9714 47.8334C73.2072 47.9456 72.5509 47.2893 72.6631 46.525Z" fill="#FF4040" />
      <path d="M73.8875 38.1854L72.6631 46.525C72.5509 47.2893 73.2072 47.9456 73.9714 47.8334L82.3111 46.609M73.8875 38.1854L82.3111 46.609M73.8875 38.1854L96.7184 15.3544M82.3111 46.609L105.142 23.778M96.7184 15.3544L99.9414 12.1315C100.242 11.8313 100.728 11.8313 101.029 12.1315L108.365 19.4679C108.665 19.7681 108.665 20.2548 108.365 20.5551L105.142 23.778M96.7184 15.3544L105.142 23.778" stroke="white" strokeWidth="2" strokeLinejoin="round" />
      <path d="M52.2325 64.4704L51.6458 65.4373L52.7336 66.0973L53.2615 64.9396L52.2325 64.4704ZM65.0072 38.3833C64.7886 37.7981 64.137 37.501 63.5519 37.7196L54.017 41.2821C53.4319 41.5008 53.1348 42.1523 53.3534 42.7374C53.572 43.3225 54.2235 43.6196 54.8087 43.401L63.2841 40.2344L66.4508 48.7098C66.6694 49.295 67.321 49.5921 67.9061 49.3735C68.4912 49.1548 68.7883 48.5033 68.5697 47.9182L65.0072 38.3833ZM33.9402 53.3717L34.5269 52.4048L33.5327 51.8016L32.9573 52.8122L33.9402 53.3717ZM21.9345 72.1761C21.6255 72.7189 21.8151 73.4094 22.3579 73.7184C22.9007 74.0274 23.5913 73.8379 23.9003 73.2951L21.9345 72.1761ZM53.2615 64.9396L64.9768 39.2483L62.9187 38.3098L51.2034 64.0012L53.2615 64.9396ZM52.8191 63.5035L34.5269 52.4048L33.3536 54.3386L51.6458 65.4373L52.8191 63.5035ZM32.9573 52.8122L21.9345 72.1761L23.9003 73.2951L34.9231 53.9312L32.9573 52.8122Z" fill="#FF4040" />
      <circle cx="33.7561" cy="53.6815" r="3.76989" fill="#FF4040" />
      <circle cx="51.9557" cy="63.8026" r="3.76989" fill="#FF4040" />
    </g>
    <defs>
      <clipPath id="clip0_6530_22851">
        <rect x="111.226" width="95" height="111" rx="2" transform="rotate(90 111.226 0)" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

export default PerformanceFormsIcon;
