import React from "react";

function ManIcon(props) {
  return (
    <svg {...props} width="40" height="51" viewBox="0 0 40 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.1005 35.7408L31.4074 36.414C35.2169 36.822 38.0952 39.9126 38.0952 43.6152V51H40V43.6152C40
        38.9844 36.4021 35.1084 31.619 34.5882L25.1852 33.8946V30.0084L25.4497 29.8248C27.6825 28.2948 29.1111 25.9182
        29.3757 23.307L29.3862 23.154L32.2328 19.0332V11.5974C32.127 5.2122 26.6243 0 19.9788 0C16.7513 0 13.746 1.2138
        11.5026 3.4068C9.25926 5.61 8.06349 8.5272 8.1164 11.628V19.0128L10.6138 23.1336L10.6243 23.2662C10.8783 25.8978
        12.3069 28.2846 14.5503 29.8248L14.8148 30.0084V33.8946L8.38095 34.5882C3.59788 35.1084 0 38.9844 0
        43.6152V51H1.90476V43.6152C1.90476 39.9228 4.78307 36.822 8.59259 36.414L14.8995 35.7408L15.0582
        36.2202C15.746 38.3112 17.7249 39.7188 20.0106 39.7188C22.2857 39.7188 24.2751 38.3112 24.963
        36.2202L25.1005 35.7408ZM10.0212 15.402V11.6076C9.97884 8.9964 10.9841 6.5484 12.8677 4.7022C14.7407
        2.856 17.2698 1.836 19.9788 1.836C25.5767 1.836 30.2222 6.2322 30.328 11.628V11.6382V15.402H24.2328C21.4709
        15.402 19.1852 13.3926 18.8783 10.812H16.8995C16.582 13.3926 14.2963 15.402 11.545 15.402H10.0212ZM23.2804
        34.7208C23.2804 36.465 21.8095 37.8828 20 37.8828C18.1905 37.8828 16.7196 36.465 16.7196 34.7208V30.9876L17.5238
        31.2018C19.1323 31.6302 20.8677 31.6302 22.4762 31.2018L23.2804 30.9876V34.7208ZM20 29.682C15.8624 29.682 12.4868
        26.4282 12.4868 22.44V17.1972L12.9947 17.0952C14.7407 16.7484 16.2963 15.7998 17.3757 14.4126L17.8836 13.7598L18.3915
        14.4126C19.7884 16.2078 21.9153 17.238 24.2328 17.238H27.5132V22.44C27.5132 26.4282 24.1376 29.682 20 29.682Z"
        fill={props.isSelected ? "white" : "var(--icons"}
      />
    </svg>
  );
}
export default ManIcon;
