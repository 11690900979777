import React from "react";
import Icon from "./icon";

function Settings(props) {
  return (
    <Icon viewBox="0 0 300 300" {...props}>
      <g>
        <g>
          <g>
            <circle cx="149.996" cy="156.072" r="28.197" />
            <path
              d="M149.996,0C67.157,0,0.001,67.161,0.001,149.997S67.157,300,149.996,300s150.003-67.163,150.003-150.003
				S232.835,0,149.996,0z M228.82,171.799l-21.306,1.372c-1.193,4.02-2.783,7.866-4.746,11.482l14.088,16.039l-22.245,22.243
				l-16.031-14.091c-3.618,1.961-7.464,3.551-11.482,4.741l-1.377,21.311l-31.458-0.003l-1.375-21.309
				c-4.015-1.19-7.861-2.78-11.479-4.741l-16.034,14.091l-22.243-22.25l14.088-16.031c-1.963-3.618-3.553-7.464-4.746-11.482
				l-21.306-1.375v-31.458l21.306-1.375c1.193-4.015,2.786-7.864,4.746-11.479l-14.088-16.031l22.245-22.248l16.031,14.088
				c3.618-1.963,7.464-3.551,11.484-4.744l1.375-21.309h31.452l1.377,21.309c4.017,1.193,7.864,2.78,11.482,4.744l16.036-14.088
				l22.243,22.248l-14.088,16.031c1.961,3.618,3.553,7.464,4.746,11.479l21.306,1.375L228.82,171.799z"
            />
          </g>
        </g>
      </g>
    </Icon>
  );
}
export default Settings;
