import React from "react";

const PerformanceAnalyticsHistoricIcon = (props) => (
    <svg  {...props} className={"icon"} width="111" height="96" viewBox="0 0 111 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M88.5076 88.9994C88.5078 90.1041 87.6123 90.9998 86.5076 90.9998H24.4883C23.3837 90.9998 22.4883 90.1044 22.4883 88.9998V6.96338C22.4883 5.85881 23.3837 4.96338 24.4883 4.96338H65.4918C66.0128 4.96338 66.5132 5.16667 66.8866 5.53001L87.8899 25.968C88.2767 26.3445 88.495 26.8613 88.4951 27.401L88.5076 88.9994Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
        <path d="M66.3047 4.96338V24.8887C66.3047 25.441 66.7524 25.8887 67.3047 25.8887H88.0446" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
        <path d="M62.2999 65.4186L61.7132 66.3856L62.801 67.0456L63.3289 65.8879L62.2999 65.4186ZM75.0746 39.3315C74.856 38.7464 74.2045 38.4493 73.6194 38.6679L64.0844 42.2304C63.4993 42.449 63.2022 43.1005 63.4208 43.6856C63.6394 44.2708 64.291 44.5679 64.8761 44.3493L73.3516 41.1826L76.5182 49.6581C76.7369 50.2432 77.3884 50.5403 77.9735 50.3217C78.5586 50.1031 78.8557 49.4515 78.6371 48.8664L75.0746 39.3315ZM44.0077 54.32L44.5943 53.3531L43.6001 52.7498L43.0248 53.7605L44.0077 54.32ZM32.0019 73.1243C31.6929 73.6671 31.8825 74.3577 32.4253 74.6667C32.9681 74.9757 33.6587 74.7861 33.9677 74.2433L32.0019 73.1243ZM63.3289 65.8879L75.0442 40.1966L72.9862 39.2581L61.2709 64.9494L63.3289 65.8879ZM62.8866 64.4517L44.5943 53.3531L43.421 55.2869L61.7132 66.3856L62.8866 64.4517ZM43.0248 53.7605L32.0019 73.1243L33.9677 74.2433L44.9905 54.8795L43.0248 53.7605Z" fill="#FF4040" />
        <circle cx="43.8235" cy="54.6298" r="3.76989" fill="#FF4040" />
        <circle cx="62.0231" cy="64.7508" r="3.76989" fill="#FF4040" />
    </svg>
);

export default PerformanceAnalyticsHistoricIcon;
