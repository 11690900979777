import React from "react";

const TypesOfSkillsIcon = (props) => (
  <svg {...props} className={"icon"} width="96" height="111" viewBox="0 0 96 111" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6379_324)">
      <path
        d="M61.426 23.7419L59.6044 22.517C59.2089 22.2521 58.9299 21.8517 58.8227 21.3952C58.7155 20.9387 58.7879 20.4595 59.0256 20.0528L60.1386 18.1865C60.3315 17.8607 60.4192
       17.4857 60.3904 17.1105C60.3615 16.7352 60.2175 16.3772 59.977 16.0832C59.7365 15.7892 59.4109 15.5729 59.0426 15.4626C58.6742 15.3523 58.2804 15.3531 57.9126 15.4649L55.8053 16.0955C55.3466 
       16.234 54.8513 16.1991 54.4177 15.9978C53.9841 15.7964 53.6438 15.4432 53.4642 15.0083L52.6554 13.0042C52.5153 12.6549 52.2705 12.3549 51.9532 12.1434C51.6358 11.932 51.2606 11.8188 50.8764 
       11.8188C50.4923 11.8188 50.117 11.932 49.7997 12.1434C49.4823 12.3549 49.2376 12.6549 49.0975 13.0042L48.2664 14.9974C48.0876 15.4321 47.748 15.7852 47.315 15.9866C46.882 16.188 46.3873 16.223 
       45.9291 16.0846L43.8143 15.4649C43.4465 15.3531 43.0527 15.3523 42.6844 15.4626C42.316 15.5729 41.9904 15.7892 41.7499 16.0832C41.5094 16.3772 41.3654 16.7352 41.3366 17.1105C41.3077 17.4857 41.3955 
       17.8607 41.5883 18.1865L42.7013 20.0528C42.9412 20.4602 43.0148 20.9411 42.9075 21.3993C42.8002 21.8574 42.5199 22.2592 42.1226 22.5243L40.3046 23.7419C39.9875 23.9539 39.743 24.2542 39.603 24.6038C39.4629 
       24.9533 39.4339 25.336 39.5195 25.7019C39.6052 26.0678 39.8016 26.4001 40.0832 26.6555C40.3648 26.9109 40.7186 27.0776 41.0986 27.1339L43.2764 27.4565C43.7526 27.5281 44.1838 27.7721 44.4842 28.1401C44.7846 28.508 
       44.9322 28.9729 44.8977 29.4424L44.7419 31.5768C44.7142 31.951 44.8024 32.3246 44.9949 32.6491C45.1875 32.9737 45.4755 33.2341 45.8216 33.3967C46.1676 33.5593 46.5558 33.6164 46.9355 33.5607C47.3153 33.505 47.6691 33.3391 
       47.9511 33.0844L49.5686 31.6348C49.9227 31.3167 50.3861 31.1402 50.8672 31.1402C51.3482 31.1402 51.8117 31.3167 52.1657 31.6348L53.787 33.0844C54.0691 33.3378 54.4226 33.5026 54.8016 33.5576C55.1807 33.6126 55.568 33.5552 55.9133 
       33.3928C56.2586 33.2304 56.546 32.9705 56.7385 32.6467C56.9309 32.323 57.0193 31.9503 56.9925 31.5768L56.8329 29.4279C56.7976 28.9583 56.9449 28.4929 57.2454 28.1248C57.546 27.7566 57.9777 27.5128 58.4542 27.442L60.6321 27.1194C61.0082
        27.0602 61.3576 26.8927 61.6358 26.6384C61.9139 26.3841 62.108 26.0545 62.1932 25.6918C62.2785 25.3291 62.251 24.9498 62.1143 24.6025C61.9777 24.2551 61.738 23.9555 61.426 23.7419ZM50.8635 25.6771C50.2618 25.6771 49.6736 
        25.5028 49.1733 25.1763C48.673 24.8498 48.283 24.3857 48.0528 23.8427C47.8225 23.2997 47.7623 22.7022 47.8797 22.1258C47.9971 21.5493 48.2868 21.0198 48.7123 20.6042C49.1377 20.1886 49.6798 19.9056 50.2699 19.791C50.8601 
        19.6763 51.4718 19.7352 52.0277 19.9601C52.5836 20.185 53.0587 20.5659 53.393 21.0545C53.7273 21.5432 53.9057 22.1178 53.9057 22.7055C53.9057 23.4936 53.5852 24.2495 53.0147 24.8067C52.4441 25.364 51.6703 25.6771 50.8635 25.6771Z"
        fill="#FF592C" stroke="var(--svgColor)" strokeWidth="2.03919" strokeMiterlimit="10" />
    </g>
    <path d="M12.8422 51.9161C15.0586 44.3736 19.5863 37.78 25.6325 32.9062M34.2162 97.3509C39.2615 99.6271 44.8839 100.898 50.8114 100.898C56.7388 100.898 62.3613 99.6271 67.4065 97.3509M88.7805 51.9161C86.5641 44.3736 82.0364 37.78 75.9902 32.9062" stroke="var(--svgColor)" strokeWidth="3" />
    <g clipPath="url(#clip1_6379_324)">
      <path d="M50.8114 67.4419C54.4467 67.4419 57.952 68.9708 60.5504 71.7232C62.8186 74.1258 64.2307 77.288 64.5552 80.665H37.0675C37.392 77.288 38.8041 74.1258 41.0723 71.7232C43.6707 68.9708 47.176 67.4419 50.8114 67.4419Z" fill="#484848" stroke="var(--svgColor)" strokeWidth="3" />
      <path d="M59.7856 54.5607C59.7856 59.3108 55.8091 63.2352 50.8114 63.2352C45.8137 63.2352 41.8372 59.3108 41.8372 54.5607C41.8372 49.8107 45.8137 45.8862 50.8114 45.8862C55.8091 45.8862 59.7856 49.8107 59.7856 54.5607Z" fill="#484848" stroke="var(--svgColor)" strokeWidth="3" />
    </g>
    <path d="M88.8228 83.0203L88.4732 82.2936L88.0163 82.5133V83.0203H88.8228ZM82.3518 83.0203H83.1582V82.5133L82.7014 82.2936L82.3518 83.0203ZM92.2578 76.2729C92.2578 78.9241 90.7136 81.2157 88.4732 82.2936L89.1724 83.747C91.9506 82.4105 93.8707 79.5667 93.8707 76.2729H92.2578ZM85.5873 69.597C89.2707 69.597 92.2578 72.5853 92.2578 76.2729H93.8707C93.8707 71.6957 90.1626 67.9841 85.5873 67.9841V69.597ZM78.9168 76.2729C78.9168 72.5853 81.9038 69.597 85.5873 69.597V67.9841C81.0119 67.9841 77.3039 71.6957 77.3039 76.2729H78.9168ZM82.7014 82.2936C80.4609 81.2157 78.9168 78.9241 78.9168 76.2729H77.3039C77.3039 79.5667 79.224 82.4105 82.0022 83.747L82.7014 82.2936ZM83.1582 86.4042V83.0203H81.5454V86.4042H83.1582ZM83.9647 87.2106C83.5193 87.2106 83.1582 86.8496 83.1582 86.4042H81.5454C81.5454 87.7403 82.6285 88.8235 83.9647 88.8235V87.2106ZM87.2099 87.2106H83.9647V88.8235H87.2099V87.2106ZM88.0163 86.4042C88.0163 86.8496 87.6553 87.2106 87.2099 87.2106V88.8235C88.5461 88.8235 89.6292 87.7403 89.6292 86.4042H88.0163ZM88.0163 83.0203V86.4042H89.6292V83.0203H88.0163Z" fill="var(--svgColor)" />
    <path d="M85.494 78.4238V84.7654H82.4457H88.7562" stroke="var(--svgColor)" strokeWidth="1.61288" />
    <ellipse cx="85.5873" cy="76.4718" rx="2.08569" ry="2.05967" fill="#F5A506" />
    <ellipse cx="12.8422" cy="77.1406" rx="7.72952" ry="7.72952" fill="#FF4040" />
    <path d="M9.18501 77.2433L10.9586 79.3711L16.5003 74.9097" fill="#FF4040" />
    <path d="M9.18501 77.2433L10.9586 79.3711L16.5003 74.9097" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
    <defs>
      <clipPath id="clip0_6379_324">
        <rect width="27.0092" height="25.2086" fill="var(--svgColor)" transform="translate(37.7482 10.1025)" />
      </clipPath>
      <clipPath id="clip1_6379_324">
        <rect width="39.3831" height="41.512" fill="var(--svgColor)" transform="translate(31.1198 41.1304)" />
      </clipPath>
    </defs>
  </svg>
);

export default TypesOfSkillsIcon;