import React from "react";

const PositionEditorIcon = (props) => (
    <svg {...props} className={"icon"} width="114" height="95" viewBox="0 0 114 95" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.03308 91.0576H108.967" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M35 35H23C21.8954 35 21 35.8954 21 37V89C21 90.1046 21.8954 91 23 91H35C36.1046 91 37 90.1046 37 89V37C37 35.8954 36.1046 35 35 35Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M63 35H51C49.8954 35 49 35.8954 49 37V89C49 90.1046 49.8954 91 51 91H63C64.1046 91 65 90.1046 65 89V37C65 35.8954 64.1046 35 63 35Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M90 35H78C76.8954 35 76 35.8954 76 37V89C76 90.1046 76.8954 91 78 91H90C91.1046 91 92 90.1046 92 89V37C92 35.8954 91.1046 35 90 35Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M56.2133 2.03145C56.3855 1.91099 56.6145 1.91099 56.7867 2.03145L93.3089 27.5903C93.7094 27.8707 93.511 28.5 93.0222 28.5H19.9778C19.4889 28.5 19.2906 27.8707 19.6911 27.5903L56.2133 2.03145Z" stroke="var(--svgColor)" strokeWidth="3" />
    </svg>


);

export default PositionEditorIcon;
