import React from "react";
import Icon from "./icon";

function WarehouseIcon(props) {
	return (
    <Icon viewBox="0 0 24 24" {...props}>
		<path d="M22 21V7L12 3 2 7v14h5v-9h10v9h5zm-11-2H9v2h2v-2zm2-3h-2v2h2v-2zm2 3h-2v2h2v-2z" />
    </Icon>
	);
}
export default WarehouseIcon;
