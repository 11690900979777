import React from "react";

const RatingsIcon = (props) => (
  <svg {...props} className={"icon"} width="110" height="82" viewBox="0 0 110 82" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M78.6567 36.8445L78.6989 38.4459L79.4505 38.426L79.9155 37.8351L78.6567 36.8445ZM102.213 8.74187C102.109 7.86336 101.312 7.2361 100.433 7.34084L86.1169 9.04772C85.2384 9.15246 84.6112 9.94954 84.7159 10.828C84.8206 11.7065 85.6177 12.3338 86.4962 12.2291L99.2216 10.7118L100.739 23.4373C100.844 24.3158 101.641 24.943 102.519 24.8383C103.398 24.7335 104.025 23.9365 103.92 23.058L102.213 8.74187ZM50.1811 37.5957L50.1388 35.9943L49.4532 36.0124L48.9931 36.521L50.1811 37.5957ZM29.9043 60.0089L30.6092 61.4474L30.8857 61.3119L31.0923 61.0836L29.9043 60.0089ZM8.90689 68.5129C8.1124 68.9022 7.7839 69.8618 8.17317 70.6563C8.56244 71.4508 9.52207 71.7793 10.3166 71.39L8.90689 68.5129ZM79.9155 37.8351L101.882 9.92219L99.3638 7.94085L77.3978 35.8538L79.9155 37.8351ZM78.6144 35.2431L50.1388 35.9943L50.2233 39.197L78.6989 38.4459L78.6144 35.2431ZM48.9931 36.521L28.7164 58.9342L31.0923 61.0836L51.369 38.6704L48.9931 36.521ZM29.1995 58.5703L8.90689 68.5129L10.3166 71.39L30.6092 61.4474L29.1995 58.5703Z" fill="#FF4040" />
    <circle cx="29.9029" cy="58.7378" r="5.33981" fill="#FF4040" />
    <circle cx="50.1942" cy="38.4465" r="5.33981" fill="#FF4040" />
    <circle cx="77.9611" cy="36.3108" r="5.33981" fill="#FF4040" />
    <path d="M3.20386 75.8252H86.5048" stroke="var(--svgColor)" strokeWidth="3.20388" strokeLinecap="round" />
    <path d="M3.20386 75.8252L3.20386 22.4271" stroke="var(--svgColor)" strokeWidth="3.20388" strokeLinecap="round" />
    <path d="M1.77139 6.40364V5.80135C1.77139 5.36853 1.86525 4.97483 2.05298 4.62023C2.2407 4.26563 2.51708 3.98143 2.88211 3.76763C3.24713 3.55383 3.69559 3.44693 
      4.22749 3.44693C4.77503 3.44693 5.23131 3.55383 5.59633 3.76763C5.96657 3.98143 6.24295 4.26563 6.42546 4.62023C6.61319 4.97483 6.70706 5.36853 6.70706 
      5.80135V6.40364C6.70706 6.82603 6.61319 7.21452 6.42546 7.56912C6.24295 7.92371 5.96918 8.20791 5.60416 8.42171C5.23913 8.63551 4.78806 8.74241 4.25095 
      8.74241C3.70863 8.74241 3.25235 8.63551 2.88211 8.42171C2.51708 8.20791 2.2407 7.92371 2.05298 7.56912C1.86525 7.21452 1.77139 6.82603 1.77139 6.40364ZM3.26538 
      5.80135V6.40364C3.26538 6.58615 3.29928 6.76085 3.36707 6.92771C3.44007 7.08937 3.54958 7.22234 3.69559 7.32664C3.84682 7.42571 4.02933 7.47525 4.24313 
      7.47525C4.46736 7.47525 4.64987 7.42571 4.79067 7.32664C4.93147 7.22234 5.03576 7.08937 5.10355 6.92771C5.17134 6.76085 5.20524 6.58615 5.20524 
      6.40364V5.80135C5.20524 5.60841 5.16873 5.43111 5.09573 5.26945C5.02794 5.10259 4.92104 4.96961 4.77503 4.87053C4.63423 4.76624 4.45172 4.71409 4.22749 
      4.71409C4.0189 4.71409 3.8416 4.76624 3.69559 4.87053C3.54958 4.96961 3.44007 5.10259 3.36707 5.26945C3.29928 5.43111 3.26538 5.60841 3.26538 5.80135ZM7.21548 
      12.8098V12.2076C7.21548 11.78 7.30935 11.3889 7.49708 11.0343C7.69002 10.6797 7.969 10.3955 8.33403 10.1817C8.70427 9.96786 9.15794 9.86096 9.69505 9.86096C10.2374 
      9.86096 10.6911 9.96786 11.0561 10.1817C11.4211 10.3955 11.6975 10.6797 11.8852 11.0343C12.0729 11.3889 12.1668 11.78 12.1668 12.2076V12.8098C12.1668 13.2374 
      12.0729 13.6285 11.8852 13.9831C11.7027 14.3377 11.4289 14.6219 11.0639 14.8357C10.6989 15.0495 10.2478 15.1564 9.7107 15.1564C9.16837 15.1564 8.71209 15.0495 
      8.34185 14.8357C7.97161 14.6219 7.69002 14.3377 7.49708 13.9831C7.30935 13.6285 7.21548 13.2374 7.21548 12.8098ZM8.7173 12.2076V12.8098C8.7173 12.9976 8.75902 
      13.1749 8.84246 13.3417C8.92589 13.5086 9.04061 13.6416 9.18662 13.7407C9.33785 13.8397 9.51254 13.8893 9.7107 13.8893C9.961 13.8893 10.1565 13.8397 10.2973 
      13.7407C10.4381 13.6416 10.5346 13.5086 10.5868 13.3417C10.6441 13.1749 10.6728 12.9976 10.6728 12.8098V12.2076C10.6728 12.0198 10.6363 11.8451 10.5633 
      11.6835C10.4903 11.5166 10.3808 11.3836 10.2348 11.2846C10.094 11.1803 9.91407 11.1281 9.69505 11.1281C9.47604 11.1281 9.29352 11.1803 9.14751 11.2846C9.00672 
      11.3836 8.89982 11.5166 8.82681 11.6835C8.75381 11.8451 8.7173 12.0198 8.7173 12.2076ZM10.2504 5.23817L4.68898 14.1396L3.59391 13.5608L9.15534 4.65934L10.2504 
      5.23817Z" fill="var(--svgColor)" />
    <path d="M95.3255 71.625V78.2815H93.0728V69.8181H95.1847L95.3255 71.625ZM94.997 73.7526H94.3869C94.3869 73.1268 94.4677 72.5636 94.6294 72.063C94.791 71.5572 95.0178 71.127 95.3099 70.7724C95.6019 70.4126 95.9487 70.1388 96.3502 69.9511C96.7569 69.7581 97.2106 69.6617 97.7112 69.6617C98.1075 69.6617 98.4699 69.719 98.7985 69.8338C99.127 69.9485 99.4086 70.131 99.6432 70.3813C99.8831 70.6316 100.066 70.9627 100.191 71.3747C100.321 71.7866 100.386 72.2899 100.386 72.8843V78.2815H98.118V72.8765C98.118 72.501 98.0658 72.209 97.9615 72.0004C97.8572 71.7919 97.7034 71.6458 97.5 71.5624C97.3019 71.4738 97.0568 71.4294 96.7648 71.4294C96.4623 71.4294 96.199 71.4894 95.9747 71.6093C95.7557 71.7293 95.5732 71.8961 95.4272 72.11C95.2864 72.3185 95.1795 72.5636 95.1065 72.8452C95.0335 73.1268 94.997 73.4293 94.997 73.7526ZM102.224 68.8795C102.224 68.4884 102.321 68.1312 102.514 67.8079C102.707 67.4793 102.965 67.2186 103.288 67.0257C103.612 66.8327 103.966 66.7362 104.352 66.7362C104.733 66.7362 105.082 66.8327 105.4 67.0257C105.718 67.2186 105.971 67.4793 106.159 67.8079C106.347 68.1312 106.441 68.4884 106.441 68.8795C106.441 69.2706 106.347 69.6278 106.159 69.9511C105.971 70.2692 105.718 70.5221 105.4 70.7098C105.082 70.8975 104.733 70.9914 104.352 70.9914C103.966 70.9914 103.612 70.8975 103.288 70.7098C102.965 70.5221 102.707 70.2692 102.514 69.9511C102.321 69.6278 102.224 69.2706 102.224 68.8795ZM103.351 68.8795C103.351 69.1611 103.45 69.3957 103.648 69.5835C103.846 69.7712 104.081 69.865 104.352 69.865C104.623 69.865 104.85 69.7712 105.033 69.5835C105.215 69.3957 105.306 69.1611 105.306 68.8795C105.306 68.5927 105.215 68.3528 105.033 68.1598C104.85 67.9669 104.623 67.8704 104.352 67.8704C104.081 67.8704 103.846 67.9669 103.648 68.1598C103.45 68.3528 103.351 68.5927 103.351 68.8795Z" fill="var(--svgColor)" />
  </svg>
);

export default RatingsIcon;
