import React from "react";

const PublicationIconImportantNews = (props) => (
  <svg {...props} className={"icon"} width="44" height="42" viewBox="0 0 44 42" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.6271 34.6306V3.48438H0V34.6306C0 37.8609 2.62817 40.4891 5.85846 40.4891H36.4856C33.2553
      40.4891 30.6271 37.8612 30.6271 34.6306ZM5.68359 9.66357H12.6224V12.0076H10.325V16.8062H7.98096V12.0076H5.68359V9.66357ZM25.2896
      31.8019H5.54932V29.4579H25.2896V31.8019ZM25.2896 26.7815H5.54932V24.4374H25.2896V26.7815ZM25.2896 21.7614H5.54932V19.4173H25.2896V21.7614ZM25.2896
      16.7412H13.725V14.3972H25.2896V16.7412Z"
      fill="var(--icons)"
    />
    <path
      d="M38 1L39.3471 5.1459H43.7063L40.1796 7.7082L41.5267 11.8541L38 9.2918L34.4733 11.8541L35.8204 7.7082L32.2937 5.1459H36.6529L38 1Z"
      fill="var(--icons)"
    />
  </svg>
);
export default PublicationIconImportantNews;
