import React from "react";

const SilverMedalIcon = (props) => (
  <svg {...props} className={"icon"} width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5678_21042)">
      <path d="M33.7049 25.1113L24.2167 29.2325L13.1067 59.7057C13.097 59.7329 13.0951 59.7623 13.101 59.7906C13.107 59.8189 13.1206 59.845 13.1404 59.866C13.1602 59.8871 13.1855 59.9022 13.2133 59.9098C13.2412 59.9174 13.2706 59.9172 13.2984 59.9091L21.9556 57.3579L26.9177 64.8854C26.9335 64.9096 26.9558 64.9289 26.982 64.9412C27.0081 64.9535 27.0372 64.9582 27.0659 64.9549C27.0946 64.9515 27.1218 64.9402 27.1445 64.9223C27.1672 64.9043 27.1844 64.8804 27.1942 64.8532L38.3043 34.389L33.7049 25.1113Z" fill="#D50000" />
      <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
        <path d="M33.7049 25.1113L24.2167 29.2325L13.1067 59.7057C13.097 59.7329 13.0951 59.7623 13.101 59.7906C13.107 59.8189 13.1206 59.845 13.1404 59.866C13.1602 59.8871 13.1855 59.9022 13.2133 59.9098C13.2412 59.9174 13.2706 59.9172 13.2984 59.9091L21.9556 57.3579L26.9177 64.8854C26.9335 64.9096 26.9558 64.9289 26.982 64.9412C27.0081 64.9535 27.0372 64.9582 27.0659 64.9549C27.0946 64.9515 27.1218 64.9402 27.1445 64.9223C27.1672 64.9043 27.1844 64.8804 27.1942 64.8532L38.3043 34.389L33.7049 25.1113Z" fill="#D50000" />
      </g>
      <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
        <path d="M36.7477 31.252L32.2371 32.5437L30.3477 56.208L38.304 34.3905L36.7477 31.252Z" fill="#AC0202" />
      </g>
      <path d="M53.5426 58.3483L39.9374 29.0651L30.1341 25.7617L26.3438 35.3936L39.9374 64.6665C39.9555 64.7056 39.9842 64.7388 40.0201 64.7625C40.056 64.7862 40.0979 64.7993 40.1409 64.8006C40.1839 64.8018 40.2264 64.7909 40.2636 64.7693C40.3008 64.7477 40.3313 64.7161 40.3515 64.6781L44.4236 57.0656L53.2905 58.6754C53.3325 58.6833 53.3758 58.6795 53.4158 58.6643C53.4557 58.649 53.4906 58.6231 53.5167 58.5892C53.5428 58.5554 53.559 58.5149 53.5636 58.4724C53.5682 58.4299 53.5609 58.3869 53.5426 58.3483Z" fill="#D50000" />
      <g style={{ mixBlendMode: "multiply" }} opacity="0.3">
        <path d="M32.2388 47.4904C37.3221 47.4982 42.2549 45.7634 46.217 42.5746L39.9379 29.065L34.8047 27.3354L33.7025 25.1113L31.3025 26.1558L30.1346 25.7617L29.7063 26.8499L24.2143 29.2389L19.1172 43.2289C22.9298 46.0053 27.5245 47.4976 32.2388 47.4904Z" fill="#AC0202" />
      </g>
      <path d="M32.2367 46.262C44.5802 46.262 54.5866 36.2425 54.5866 23.8829C54.5866 11.5233 44.5802 1.50391 32.2367 1.50391C19.8931 1.50391 9.88672 11.5233 9.88672 23.8829C9.88672 36.2425 19.8931 46.262 32.2367 46.262Z" fill="#C1BFBF" />
      <g style={{ mixBlendMode: "screen" }} opacity="0.5">
        <path d="M10.5024 24.5001C10.5025 20.1111 11.7915 15.8189 14.209 12.1578C16.6265 8.49658 20.0659 5.62803 24.099 3.90906C28.1322 2.19008 32.5812 1.69657 36.8924 2.48993C41.2035 3.2833 45.1865 5.32851 48.3457 8.37107C46.2869 6.22208 43.8204 4.50615 41.0908 3.32382C38.3612 2.14149 35.4233 1.51654 32.4494 1.48558C29.4755 1.45463 26.5254 2.0183 23.7718 3.14356C21.0183 4.26883 18.5167 5.93305 16.4137 8.03872C14.3106 10.1444 12.6485 12.6492 11.5246 15.4063C10.4007 18.1634 9.83759 21.1173 9.86839 24.0951C9.89918 27.0729 10.5232 30.0145 11.7039 32.7477C12.8846 35.481 14.5982 37.9507 16.7443 40.0123C12.7333 35.8478 10.4952 30.2858 10.5024 24.5001Z" fill="#C1BFBF" />
      </g>
      <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
        <path d="M50.0529 10.3691C53.2949 15.2049 54.5264 21.1157 53.486 26.8461C52.4455 32.5764 49.2151 37.6749 44.4807 41.0587C39.7463 44.4425 33.8811 45.8449 28.1306 44.9681C22.3802 44.0914 17.1976 41.0045 13.6836 36.3632C15.3497 38.8483 17.4966 40.9736 19.9975 42.6132C22.4983 44.2528 25.3023 45.3735 28.2433 45.9088C31.1843 46.4442 34.2028 46.3835 37.1199 45.7302C40.0371 45.0768 42.7938 43.8442 45.2268 42.1053C47.6598 40.3664 49.7197 38.1564 51.2847 35.6062C52.8497 33.0561 53.8879 30.2175 54.3378 27.2584C54.7878 24.2994 54.6405 21.2801 53.9044 18.3791C53.1684 15.4781 51.8588 12.7544 50.0529 10.3691Z" fill="#C1BFBF" />
      </g>
      <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
        <path d="M45.9847 37.6501C53.5772 30.0477 53.5772 17.7218 45.9847 10.1194C38.3922 2.51697 26.0822 2.51697 18.4897 10.1194C10.8972 17.7218 10.8972 30.0477 18.4897 37.6502C26.0822 45.2526 38.3922 45.2526 45.9847 37.6501Z" fill="#C1BFBF" />
      </g>
      <path d="M45.1616 36.8278C52.3005 29.6796 52.3005 18.09 45.1616 10.9417C38.0226 3.79344 26.448 3.79344 19.3091 10.9417C12.1701 18.09 12.1701 29.6796 19.3091 36.8278C26.448 43.9761 38.0226 43.9761 45.1616 36.8278Z" fill="#C1BFBF" />
      <path d="M44.3486 36.0108C51.038 29.3127 51.038 18.4529 44.3486 11.7548C37.6591 5.05665 26.8134 5.05665 20.124 11.7548C13.4346 18.4529 13.4346 29.3127 20.124 36.0108C26.8134 42.709 37.6591 42.709 44.3486 36.0108Z" fill="#C1BFBF" />
      <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M32.8594 37.6994C33.1646 38.0471 33.487 38.3905 33.8266 38.7297C34.0216 38.9129 34.2562 39.0487 34.5121 39.1263C34.6531 39.1731 34.7983 39.2063 34.9456 39.2255C35.1018 39.2431 35.2591 39.2495 35.4163 39.2448C35.5817 39.2416 35.7465 39.2244 35.9089 39.1933C36.0753 39.1609 36.2394 39.1179 36.4002 39.0645C36.7265 38.9546 37.0327 38.7924 37.307 38.5842C36.8954 38.3176 36.5044 38.0574 36.125 37.7664C35.9159 37.6124 35.6792 37.5001 35.4279 37.4354C35.158 37.3607 34.8795 37.3222 34.5996 37.3208C34.3044 37.3162 34.0096 37.3456 33.7211 37.4083C33.4225 37.4677 33.1329 37.5656 32.8594 37.6994Z" fill="#C1BFBF" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M35.433 35.6875C35.5487 35.2496 35.6375 34.7937 35.7121 34.3597C35.2037 34.3517 34.7032 34.4855 34.2664 34.7461C33.8421 34.9867 33.5082 35.3599 33.3159 35.8085C33.1449 36.2232 32.9571 36.6431 32.75 37.0642C33.3261 37.1867 33.9267 37.1047 34.449 36.8324C34.6889 36.7084 34.9043 36.5417 35.0844 36.3404C35.249 36.1524 35.3682 35.929 35.433 35.6875Z" fill="#C1BFBF" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M36.0117 36.9389C36.3496 37.1964 36.7024 37.4484 37.0702 37.6948C37.2771 37.8274 37.5126 37.9087 37.7571 37.9318C38.0352 37.9668 38.3173 37.9497 38.5892 37.8816C38.7362 37.8476 38.8809 37.8045 39.0227 37.7528C39.1666 37.6953 39.3059 37.6268 39.4394 37.548C39.5737 37.471 39.7026 37.385 39.8252 37.2905C39.9464 37.191 40.0599 37.0825 40.1648 36.9659C39.959 36.8822 39.7558 36.7959 39.5577 36.7083C39.3596 36.6208 39.1564 36.5203 38.9609 36.4237C38.7462 36.3215 38.514 36.2612 38.2767 36.246C38.0188 36.2215 37.7586 36.2358 37.505 36.2885C37.2386 36.3333 36.9792 36.4125 36.7333 36.5242C36.4747 36.6283 36.2319 36.7679 36.0117 36.9389Z" fill="#C1BFBF" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M37.9807 34.6613C37.9949 34.4501 38.0064 34.2453 38.0116 34.0354C38.0167 33.8255 38.0116 33.6155 38.0116 33.4082C37.8927 33.4287 37.7757 33.4588 37.6617 33.4984C37.5479 33.5324 37.4369 33.5755 37.3299 33.6271C37.2255 33.6791 37.1249 33.7385 37.0289 33.8049C36.9316 33.8662 36.8396 33.9356 36.7537 34.0122C36.6683 34.0886 36.5892 34.1717 36.517 34.2608C36.4426 34.3441 36.3754 34.4337 36.3164 34.5286C36.1992 34.7179 36.1173 34.9269 36.0746 35.1455C35.9949 35.5512 35.901 35.9672 35.7891 36.3844C36.0612 36.3828 36.3314 36.3394 36.5904 36.2557C36.8392 36.1609 37.0731 36.0307 37.2849 35.8693C37.4785 35.712 37.6442 35.523 37.7749 35.3104C37.8921 35.1126 37.9625 34.8905 37.9807 34.6613Z" fill="#C1BFBF" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M38.918 35.5845C39.2678 35.7562 39.6288 35.9189 40.0009 36.0726C40.2087 36.1551 40.4331 36.1869 40.6556 36.1653C40.9092 36.1483 41.1575 36.0855 41.3887 35.9798C41.9045 35.7537 42.3401 35.3772 42.6389 34.8993C42.253 34.8195 41.8505 34.7126 41.471 34.607C41.0275 34.5065 40.5635 34.5516 40.1476 34.7358C39.6773 34.9208 39.2564 35.2126 38.918 35.5883V35.5845Z" fill="#C1BFBF" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M40.2918 33.1873C40.2443 32.801 40.1941 32.4314 40.1182 32.0527C39.7058 32.2087 39.3447 32.4762 39.0751 32.8254C38.9483 32.9919 38.8445 33.1747 38.7664 33.3689C38.694 33.5599 38.6561 33.7622 38.6545 33.9665C38.6545 34.3528 38.6442 34.7392 38.6211 35.1256C38.8646 35.0731 39.0987 34.9841 39.3156 34.8615C39.5226 34.7331 39.7101 34.5757 39.8726 34.3941C40.1761 34.027 40.3446 33.5982 40.2918 33.1873Z" fill="#C1BFBF" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M41.5547 33.616C41.8968 33.7036 42.2492 33.786 42.6055 33.8556C42.8036 33.8889 43.0066 33.8775 43.1997 33.8221C43.4206 33.7638 43.6289 33.6656 43.8145 33.5323C44.2288 33.2456 44.5489 32.8422 44.7341 32.3732C44.3701 32.3629 44.0023 32.3372 43.6473 32.3063C43.235 32.2894 42.8285 32.4078 42.4897 32.6437C42.2977 32.7617 42.1221 32.9046 41.9675 33.0687C41.8084 33.2338 41.6698 33.4176 41.5547 33.616Z" fill="#C1BFBF" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M42.3563 31.2398C42.2483 30.9024 42.1415 30.5791 42.0155 30.2559C41.6698 30.4651 41.3895 30.7671 41.2065 31.1277C41.1612 31.2156 41.1229 31.3069 41.092 31.4008C40.9942 31.6654 40.9706 31.9518 41.0238 32.2289C41.0933 32.5688 41.1525 32.9127 41.2013 33.2669C41.41 33.1798 41.6026 33.0584 41.7711 32.9076C41.9322 32.7557 42.0716 32.5824 42.1853 32.3924C42.3923 32.0061 42.4734 31.5978 42.3563 31.2398Z" fill="#C1BFBF" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M43.6641 31.2628C43.9792 31.2808 44.3071 31.2937 44.6274 31.295C45.0121 31.2701 45.3724 31.0975 45.6332 30.8133C45.7867 30.6556 45.919 30.4785 46.0268 30.2866C46.1301 30.0859 46.205 29.8718 46.2493 29.6504C45.9243 29.695 45.6028 29.7324 45.2846 29.7624C44.919 29.8109 44.5801 29.9809 44.3226 30.2454C44.1738 30.381 44.044 30.5361 43.9367 30.7064C43.8234 30.88 43.7319 31.0668 43.6641 31.2628Z" fill="#C1BFBF" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M43.9739 29.0339C43.8265 28.7532 43.6738 28.4801 43.5161 28.2148C42.9656 28.7145 42.7083 29.5091 42.9604 30.1299C43.08 30.4196 43.1881 30.712 43.2935 31.0146C43.4628 30.9021 43.6116 30.7615 43.7334 30.5987C43.8503 30.4385 43.9439 30.2624 44.0112 30.0758C44.1371 29.7373 44.1237 29.3626 43.9739 29.0339Z" fill="#C1BFBF" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M45.2227 28.639C45.5005 28.6016 45.7821 28.5591 46.0651 28.5102C46.3935 28.4265 46.6783 28.2219 46.8625 27.9371C46.9708 27.7776 47.0574 27.6044 47.1198 27.422C47.1509 27.3284 47.1759 27.2329 47.1944 27.1361C47.2087 27.038 47.216 26.939 47.2162 26.8398C46.9449 26.9274 46.6735 27.0098 46.3944 27.0807C45.8066 27.2378 45.3358 27.9152 45.2227 28.639Z" fill="#C1BFBF" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M45.1381 26.6459C44.9734 26.4257 44.7959 26.208 44.6159 26.002C44.5641 26.067 44.5181 26.1364 44.4782 26.2093C44.4368 26.2803 44.3998 26.3539 44.3676 26.4295C44.3059 26.579 44.2673 26.7371 44.2532 26.8983C44.2069 27.2015 44.2693 27.5113 44.4294 27.7728C44.576 28.0046 44.7213 28.2428 44.8628 28.4875C44.9903 28.3603 45.097 28.2138 45.1792 28.0535C45.254 27.8947 45.3055 27.726 45.3323 27.5525C45.3456 27.4699 45.352 27.3864 45.3516 27.3027C45.348 27.2213 45.3377 27.1403 45.3207 27.0606C45.2908 26.9106 45.2284 26.7691 45.1381 26.6459Z" fill="#C1BFBF" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M46.2245 25.9107C46.4543 25.8351 46.6858 25.7544 46.919 25.6686C47.048 25.6185 47.1647 25.5412 47.2611 25.4419C47.3662 25.3296 47.453 25.2014 47.5184 25.062C47.589 24.9122 47.638 24.753 47.6637 24.5894C47.6881 24.4185 47.6881 24.2451 47.6637 24.0742C47.4399 24.1863 47.2161 24.2944 46.9936 24.3962C46.7428 24.5263 46.5427 24.7366 46.4251 24.9937C46.3917 25.0626 46.3629 25.1336 46.3389 25.2062C46.3112 25.2792 46.2889 25.3541 46.2721 25.4303C46.2329 25.5873 46.2169 25.7491 46.2245 25.9107Z" fill="#C1BFBF" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M45.8506 24.2134C45.6705 24.0469 45.49 23.8859 45.3091 23.7305C45.2377 23.8576 45.1874 23.9955 45.1599 24.1387C45.1291 24.2768 45.1165 24.4184 45.1226 24.5598C45.1301 24.6969 45.1587 24.832 45.2075 24.9604C45.2501 25.0822 45.3165 25.1943 45.403 25.2901C45.5663 25.4665 45.7297 25.6494 45.8904 25.8361C45.9775 25.7064 46.0439 25.5639 46.0872 25.4137C46.1341 25.2678 46.1542 25.1147 46.1464 24.9617C46.1501 24.683 46.0438 24.414 45.8506 24.2134Z" fill="#C1BFBF" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M46.6423 23.7326C46.8326 23.6425 47.0114 23.5472 47.1979 23.448C47.4094 23.3194 47.5646 23.1157 47.6326 22.8775C47.7137 22.6059 47.7186 22.3172 47.6468 22.043C47.4731 22.1602 47.2892 22.2722 47.1117 22.3817C46.9133 22.5156 46.7678 22.7147 46.7001 22.9445C46.6093 23.197 46.5892 23.4696 46.6423 23.7326Z" fill="#C1BFBF" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M46.1313 22.3424C45.9641 22.2201 45.8033 22.1029 45.6297 21.9883C45.5349 22.219 45.5107 22.4727 45.5602 22.7172C45.5939 22.9436 45.7059 23.151 45.8766 23.3032C46.0348 23.432 46.1995 23.5711 46.3499 23.7089C46.4665 23.4692 46.5077 23.1998 46.4683 22.9361C46.4356 22.7025 46.3151 22.4901 46.1313 22.3424Z" fill="#C1BFBF" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M46.4316 19.6621C46.306 19.8273 46.2182 20.0181 46.1744 20.221C46.1207 20.464 46.0983 20.7129 46.1075 20.9616C46.1157 21.0912 46.1316 21.2202 46.1551 21.3479C46.1746 21.4656 46.2039 21.5815 46.2425 21.6943C46.3083 21.8897 46.4114 22.0703 46.5461 22.2262C46.6963 22.0795 46.8057 21.8962 46.8638 21.6943C46.904 21.5758 46.9333 21.4538 46.9512 21.3299C46.9689 21.195 46.9737 21.0587 46.9654 20.9229C46.958 20.6612 46.8994 20.4035 46.793 20.1644C46.7138 19.9707 46.59 19.7986 46.4316 19.6621Z" fill="#C1BFBF" />
        </g>
      </g>
      <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M31.6131 37.6994C31.3078 38.0471 30.9854 38.3905 30.6458 38.7297C30.4508 38.9129 30.2162 39.0487 29.9603 39.1263C29.8193 39.1731 29.6742 39.2063 29.5269 39.2255C29.3706 39.2431 29.2133 39.2495 29.0561 39.2448C28.8908 39.2416 28.726 39.2244 28.5635 39.1933C28.3972 39.1609 28.2331 39.1179 28.0722 39.0645C27.7482 38.954 27.4442 38.7918 27.1719 38.5842C27.5835 38.3176 27.9745 38.0574 28.3539 37.7664C28.563 37.6124 28.7996 37.5001 29.051 37.4354C29.3208 37.3607 29.5993 37.3222 29.8793 37.3208C30.1745 37.3162 30.4692 37.3456 30.7577 37.4083C31.0541 37.4683 31.3416 37.5661 31.6131 37.6994Z" fill="#C1BFBF" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M29.0447 35.6875C28.929 35.2496 28.8402 34.7937 28.7656 34.3597C29.274 34.3517 29.7745 34.4855 30.2113 34.7461C30.6356 34.9867 30.9695 35.3599 31.1618 35.8085C31.3328 36.2232 31.5206 36.6431 31.7277 37.0642C31.1516 37.1867 30.551 37.1047 30.0287 36.8324C29.7887 36.7084 29.5734 36.5417 29.3933 36.3404C29.2287 36.1524 29.1095 35.929 29.0447 35.6875Z" fill="#C1BFBF" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M28.4617 36.9389C28.1238 37.1964 27.771 37.4484 27.4031 37.6948C27.1963 37.8274 26.9608 37.9087 26.7163 37.9318C26.4382 37.9668 26.1561 37.9497 25.8842 37.8816C25.7372 37.8476 25.5924 37.8045 25.4507 37.7528C25.3068 37.6953 25.1675 37.6268 25.034 37.548C24.8997 37.471 24.7708 37.385 24.6481 37.2905C24.527 37.191 24.4135 37.0825 24.3086 36.9659C24.5144 36.8801 24.7172 36.7942 24.917 36.7083C25.1163 36.6169 25.317 36.5203 25.5125 36.4237C25.7272 36.3215 25.9594 36.2612 26.1967 36.246C26.4546 36.2215 26.7147 36.2358 26.9684 36.2885C27.2348 36.3333 27.4942 36.4125 27.7401 36.5242C27.9987 36.6283 28.2415 36.7679 28.4617 36.9389Z" fill="#C1BFBF" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M26.4902 34.6613C26.476 34.4501 26.4645 34.2453 26.4593 34.0354C26.4542 33.8255 26.4593 33.6155 26.4593 33.4082C26.5782 33.4287 26.6952 33.4588 26.8092 33.4984C26.923 33.5324 27.034 33.5755 27.141 33.6271C27.2454 33.6791 27.346 33.7385 27.442 33.8049C27.5393 33.8662 27.6313 33.9356 27.7172 34.0122C27.8026 34.0886 27.8817 34.1717 27.9539 34.2608C28.0283 34.3441 28.0955 34.4337 28.1545 34.5286C28.2717 34.7179 28.3536 34.9269 28.3963 35.1455C28.4761 35.5512 28.5699 35.9672 28.6818 36.3844C28.4097 36.3828 28.1395 36.3394 27.8805 36.2557C27.6317 36.1609 27.3978 36.0307 27.186 35.8693C26.9924 35.712 26.8267 35.523 26.696 35.3104C26.5788 35.1126 26.5084 34.8905 26.4902 34.6613Z" fill="#C1BFBF" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M25.5569 35.5845C25.207 35.7562 24.846 35.9189 24.4739 36.0726C24.2661 36.1551 24.0417 36.1869 23.8192 36.1653C23.5657 36.1483 23.3173 36.0855 23.0861 35.9798C22.5704 35.7537 22.1347 35.3772 21.8359 34.8993C22.2218 34.8195 22.6244 34.7126 23.0038 34.607C23.4473 34.5065 23.9114 34.5516 24.3273 34.7358C24.7975 34.9208 25.2185 35.2126 25.5569 35.5883V35.5845Z" fill="#C1BFBF" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M24.1815 33.1873C24.229 32.801 24.2792 32.4314 24.3551 32.0527C24.7675 32.2087 25.1286 32.4762 25.3982 32.8254C25.525 32.9919 25.6288 33.1747 25.7069 33.3689C25.7793 33.5599 25.8172 33.7622 25.8188 33.9665C25.8188 34.3528 25.8291 34.7392 25.8522 35.1256C25.6087 35.0731 25.3746 34.9841 25.1577 34.8615C24.9507 34.7331 24.7631 34.5757 24.6007 34.3941C24.2972 34.027 24.1287 33.5982 24.1815 33.1873Z" fill="#C1BFBF" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M22.9177 33.616C22.5756 33.7036 22.2232 33.786 21.8669 33.8556C21.6688 33.8889 21.4658 33.8775 21.2727 33.8221C21.0518 33.7638 20.8435 33.6656 20.6579 33.5323C20.2436 33.2456 19.9235 32.8422 19.7383 32.3732C20.1023 32.3629 20.4701 32.3372 20.8251 32.3063C21.2374 32.2894 21.6439 32.4078 21.9827 32.6437C22.1747 32.7617 22.3503 32.9046 22.5049 33.0687C22.664 33.2338 22.8026 33.4176 22.9177 33.616Z" fill="#C1BFBF" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M22.1166 31.2398C22.2246 30.9024 22.3313 30.5791 22.4574 30.2559C22.8031 30.4651 23.0834 30.7671 23.2664 31.1277C23.3098 31.2145 23.3463 31.3045 23.3757 31.3969C23.4735 31.6615 23.4971 31.9479 23.4439 32.225C23.3744 32.565 23.3153 32.9088 23.2664 33.263C23.0578 33.176 22.8652 33.0545 22.6966 32.9037C22.5355 32.7519 22.3962 32.5785 22.2825 32.3885C22.0805 32.0061 21.9995 31.5978 22.1166 31.2398Z" fill="#C1BFBF" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M20.8131 31.2628C20.4967 31.2808 20.17 31.2937 19.8484 31.295C19.4637 31.2701 19.1035 31.0975 18.8426 30.8133C18.6891 30.6556 18.5568 30.4785 18.4491 30.2866C18.3457 30.0859 18.2708 29.8718 18.2266 29.6504C18.5515 29.695 18.8731 29.7324 19.1912 29.7624C19.5564 29.8098 19.8952 29.9785 20.1533 30.2415C20.302 30.3771 20.4318 30.5322 20.5391 30.7026C20.6526 30.8776 20.7446 31.0657 20.8131 31.2628Z" fill="#C1BFBF" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M20.5018 29.0339C20.6493 28.7532 20.8019 28.4801 20.9597 28.2148C21.5101 28.7145 21.7674 29.5091 21.5153 30.1299C21.3957 30.4196 21.2876 30.712 21.1822 31.0146C21.0129 30.9021 20.8641 30.7615 20.7423 30.5987C20.6254 30.4385 20.5318 30.2624 20.4645 30.0758C20.3386 29.7373 20.352 29.3626 20.5018 29.0339Z" fill="#C1BFBF" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M19.2514 28.639C18.9736 28.6016 18.6919 28.5591 18.4089 28.5102C18.0805 28.4265 17.7958 28.2219 17.6115 27.9371C17.5032 27.7776 17.4167 27.6044 17.3543 27.422C17.3231 27.3284 17.2982 27.2329 17.2797 27.1361C17.2653 27.038 17.258 26.939 17.2578 26.8398C17.5292 26.9274 17.8006 27.0098 18.0797 27.0807C18.6675 27.2378 19.1434 27.9152 19.2514 28.639Z" fill="#C1BFBF" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M19.3346 26.6459C19.4993 26.4257 19.6767 26.208 19.8568 26.002C19.9085 26.067 19.9546 26.1364 19.9944 26.2093C20.0359 26.2803 20.0728 26.3539 20.105 26.4295C20.1668 26.579 20.2054 26.7371 20.2195 26.8983C20.2658 27.2015 20.2034 27.5113 20.0433 27.7728C19.8967 28.0046 19.7513 28.2428 19.6099 28.4875C19.4824 28.3603 19.3756 28.2138 19.2935 28.0535C19.2187 27.8947 19.1672 27.726 19.1404 27.5525C19.1271 27.4699 19.1207 27.3864 19.1211 27.3027C19.1247 27.2213 19.135 27.1403 19.152 27.0606C19.1819 26.9106 19.2442 26.7691 19.3346 26.6459Z" fill="#C1BFBF" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M18.2466 25.9107C18.016 25.8351 17.7844 25.7544 17.5521 25.6686C17.4231 25.6185 17.3064 25.5412 17.2099 25.4419C17.1048 25.3296 17.018 25.2014 16.9527 25.062C16.8821 24.9122 16.8331 24.753 16.8074 24.5894C16.783 24.4185 16.783 24.2451 16.8074 24.0742C17.0312 24.1863 17.255 24.2944 17.4775 24.3962C17.7283 24.5263 17.9284 24.7366 18.046 24.9937C18.0794 25.0626 18.1082 25.1336 18.1321 25.2062C18.1598 25.2792 18.1822 25.3541 18.199 25.4303C18.2381 25.5873 18.2542 25.7491 18.2466 25.9107Z" fill="#C1BFBF" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M18.622 24.2134C18.8021 24.0469 18.9826 23.8859 19.1635 23.7305C19.2349 23.8576 19.2852 23.9955 19.3127 24.1387C19.3436 24.2768 19.3561 24.4184 19.35 24.5598C19.3415 24.6966 19.312 24.8313 19.2626 24.9591C19.22 25.0809 19.1535 25.193 19.0671 25.2888C18.9037 25.4652 18.7404 25.6481 18.5796 25.8348C18.4925 25.7051 18.4261 25.5626 18.3828 25.4124C18.3368 25.2664 18.3176 25.1132 18.3262 24.9604C18.3228 24.6821 18.4291 24.4137 18.622 24.2134Z" fill="#C1BFBF" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M17.8323 23.7326C17.642 23.6425 17.4632 23.5472 17.2767 23.448C17.0652 23.3194 16.91 23.1157 16.842 22.8775C16.7609 22.6059 16.756 22.3172 16.8278 22.043C17.0015 22.1602 17.1854 22.2722 17.3629 22.3817C17.5612 22.5156 17.7068 22.7147 17.7745 22.9445C17.8653 23.197 17.8853 23.4696 17.8323 23.7326Z" fill="#C1BFBF" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M18.3431 22.3424C18.5103 22.2201 18.6711 22.1029 18.8448 21.9883C18.9396 22.219 18.9637 22.4727 18.9142 22.7172C18.8805 22.9436 18.7685 23.151 18.5978 23.3032C18.4396 23.432 18.275 23.5711 18.1245 23.7089C18.0077 23.4693 17.9669 23.1996 18.0075 22.9361C18.0392 22.7025 18.1594 22.4899 18.3431 22.3424Z" fill="#C1BFBF" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M18.0416 19.6621C18.1673 19.8273 18.2551 20.0181 18.2989 20.221C18.3531 20.4639 18.3756 20.7129 18.3658 20.9616C18.3576 21.0912 18.3417 21.2202 18.3182 21.3479C18.2986 21.4656 18.2694 21.5815 18.2307 21.6943C18.1649 21.8897 18.0618 22.0703 17.9272 22.2262C17.777 22.0795 17.6675 21.8962 17.6095 21.6943C17.5692 21.5758 17.54 21.4538 17.522 21.3299C17.5043 21.195 17.4996 21.0587 17.5079 20.9229C17.5153 20.6612 17.5738 20.4035 17.6802 20.1644C17.7594 19.9707 17.8833 19.7986 18.0416 19.6621Z" fill="#C1BFBF" />
        </g>
      </g>
      <g style={{ mixBlendMode: "multiply" }} opacity="0.4">
        <path d="M42.1164 11.5645L41.9479 12.7956L42.8649 13.6327L41.6431 13.853L41.1324 14.9837L40.5459 13.889L39.3125 13.7525L40.1717 12.8549L39.9209 11.6391L41.0373 12.18L42.1164 11.5645Z" fill="#C1BFBF" />
      </g>
      <g style={{ mixBlendMode: "multiply" }} opacity="0.4">
        <path d="M37.4445 9.26172L37.6644 10.4839L38.795 10.9965L37.7005 11.5837L37.5641 12.8188L36.6689 11.9585L35.4535 12.2096L35.9937 11.0905L35.3789 10.0112L36.6085 10.18L37.4445 9.26172Z" fill="#C1BFBF" />
      </g>
      <g style={{ mixBlendMode: "multiply" }} opacity="0.4">
        <path d="M32.2872 8.51562L32.8737 9.6103L34.1058 9.7481L33.248 10.6444L33.4988 11.8615L32.3811 11.3206L31.3032 11.9349L31.4717 10.7037L30.5547 9.86787L31.7753 9.64765L32.2872 8.51562Z" fill="#C1BFBF" />
      </g>
      <g style={{ mixBlendMode: "multiply" }} opacity="0.4">
        <path d="M27.1567 9.40234L28.0518 10.2626L29.2673 10.0115L28.7271 11.1294L29.3406 12.2086L28.1123 12.0412L27.2763 12.9594L27.0563 11.7359L25.9258 11.2234L27.019 10.6374L27.1567 9.40234Z" fill="#C1BFBF" />
      </g>
      <g style={{ mixBlendMode: "multiply" }} opacity="0.4">
        <path d="M22.5459 11.8312L23.6636 12.3721L24.7414 11.7578L24.5729 12.989L25.4899 13.8248L24.2693 14.045L23.7574 15.1771L23.1709 14.0824L21.9375 13.9446L22.7967 13.0482L22.5459 11.8312Z" fill="#C1BFBF" />
      </g>
      <g style={{ mixBlendMode: "multiply" }} opacity="0.4">
        <path d="M21.6111 16.0759C21.6111 15.9539 21.5749 15.8346 21.5072 15.7331C21.4395 15.6317 21.3433 15.5526 21.2307 15.5059C21.1182 15.4593 20.9943 15.447 20.8748 15.4708C20.7553 15.4946 20.6455 15.5534 20.5594 15.6397C20.4732 15.7259 20.4145 15.8359 20.3907 15.9555C20.367 16.0752 20.3792 16.1992 20.4258 16.3119C20.4724 16.4247 20.5514 16.521 20.6527 16.5888C20.754 16.6566 20.8731 16.6927 20.995 16.6927C21.0759 16.6929 21.1561 16.6771 21.2309 16.6461C21.3058 16.6152 21.3738 16.5698 21.431 16.5124C21.4882 16.4551 21.5336 16.3871 21.5645 16.3121C21.5954 16.2372 21.6112 16.1569 21.6111 16.0759Z" fill="#C1BFBF" />
      </g>
      <g style={{ mixBlendMode: "multiply" }} opacity="0.4">
        <path d="M44.7087 16.0759C44.7087 15.9538 44.6726 15.8345 44.6048 15.733C44.5371 15.6315 44.4408 15.5525 44.3281 15.5058C44.2155 15.4592 44.0915 15.447 43.972 15.4709C43.8524 15.4948 43.7427 15.5537 43.6566 15.6401C43.5704 15.7265 43.5119 15.8366 43.4882 15.9563C43.4646 16.0761 43.477 16.2001 43.5238 16.3128C43.5707 16.4255 43.6498 16.5218 43.7513 16.5894C43.8528 16.657 43.972 16.693 44.0939 16.6927C44.1748 16.6927 44.2548 16.6768 44.3295 16.6458C44.4042 16.6147 44.472 16.5693 44.5291 16.512C44.5862 16.4547 44.6315 16.3867 44.6623 16.3118C44.6931 16.237 44.7089 16.1568 44.7087 16.0759Z" fill="#C1BFBF" />
      </g>
      <g style={{ mixBlendMode: "screen" }} opacity="0.3">
        <path d="M32.2387 6.73243C31.8528 6.73243 31.467 6.7466 31.0811 6.77235L15.1454 22.7289C15.1196 23.1152 15.1055 23.4951 15.1055 23.8879C15.1013 26.6924 15.7874 29.4547 17.1029 31.9306L40.2722 8.73633C37.7994 7.41772 35.0403 6.72947 32.2387 6.73243Z" fill="#C1BFBF" />
      </g>
      <g style={{ mixBlendMode: "screen" }} opacity="0.3">
        <path d="M41.9126 9.73047L18.1016 33.5712C18.8487 34.6618 19.7187 35.6626 20.6945 36.5539L44.8927 12.3255C44.0022 11.3488 43.0022 10.478 41.9126 9.73047Z" fill="#C1BFBF" />
      </g>
      <g style={{ mixBlendMode: "screen" }} opacity="0.3">
        <path d="M49.3666 23.8831C49.3665 23.0431 49.3055 22.2042 49.184 21.373L29.7305 40.8505C30.5605 40.9726 31.3983 41.0341 32.2372 41.0347C34.0783 41.0365 35.9076 40.7408 37.6546 40.159L48.492 29.3075C49.0733 27.5583 49.3686 25.7266 49.3666 23.8831Z" fill="#C1BFBF" />
      </g>
      <path d="M32.2374 41.2446C22.676 41.2446 14.8984 33.4557 14.8984 23.883C14.8984 14.3104 22.676 6.52148 32.2374 6.52148C41.7989 6.52148 49.5816 14.3091 49.5816 23.883C49.5816 33.4569 41.7976 41.2446 32.2374 41.2446ZM32.2374 6.94132C27.7516 6.94644 23.451 8.73301 20.279 11.9091C17.1071 15.0852 15.3228 19.3914 15.3177 23.883C15.3177 33.2238 22.9062 40.8235 32.2374 40.8235C41.5687 40.8235 49.1558 33.2251 49.1558 23.883C49.1558 14.5409 41.5661 6.94132 32.2374 6.94132Z" fill="#C1BFBF" />
      <g style={{ mixBlendMode: "screen" }} opacity="0.5">
        <g style={{ mixBlendMode: "screen" }} opacity="0.5">
          <path d="M32.2374 41.2446C22.676 41.2446 14.8984 33.4557 14.8984 23.883C14.8984 14.3104 22.676 6.52148 32.2374 6.52148C41.7989 6.52148 49.5816 14.3091 49.5816 23.883C49.5816 33.4569 41.7976 41.2446 32.2374 41.2446ZM32.2374 6.94132C27.7516 6.94644 23.451 8.73301 20.279 11.9091C17.1071 15.0852 15.3228 19.3914 15.3177 23.883C15.3177 33.2238 22.9062 40.8235 32.2374 40.8235C41.5687 40.8235 49.1558 33.2251 49.1558 23.883C49.1558 14.5409 41.5661 6.94132 32.2374 6.94132Z" fill="#C1BFBF" />
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_5678_21042">
        <rect width="64" height="64" fill="white" transform="translate(0.203125 0.724609)" />
      </clipPath>
    </defs>
  </svg>
);

export default SilverMedalIcon;
