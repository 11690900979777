import React from "react";

function WomanIcon(props) {
  return (
    <svg  {...props} width="39" height="49" viewBox="0 0 39 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36.9135 24.9508L36.3675 24.5L36.9135 24.0492C38.2395 22.9418 39 21.3248 39 19.6C39 16.9246
        37.1182 14.553 34.5247 13.965L34.1055 13.867L34.0665 13.4358C33.4327 5.8996 27.027 0 19.5 0C11.973
        0 5.56725 5.8996 4.92375 13.4358L4.88475 13.867L4.4655 13.965C1.88175 14.553 0 16.9246 0 19.6C0
        21.3248 0.7605 22.9418 2.0865 24.0492L2.6325 24.5L2.0865 24.9508C0.7605 26.0582 0 27.6752 0 29.4C0
        31.7128 1.37475 33.8002 3.4905 34.7214L4.29 35.0644L3.666 35.6818C1.99875 37.3282 1.0725 39.543
        1.0725 41.9048V49H2.8275V41.9048C2.8275 38.3572 5.4795 35.378 8.9895 34.986L9.906 34.888L10.023
        35.3976C10.4423 37.191 11.6025 38.7394 13.2015 39.6312L19.5098 43.1494L25.8083 39.6312C27.4072
        38.7394 28.5675 37.191 28.9867 35.3976L29.1037 34.888L30.0202 34.986C33.5302 35.378 36.1823 38.3474
        36.1823 41.9048V49H37.9372V41.9048C37.9372 39.543 37.011 37.3282 35.334 35.6818L34.71 35.0644L35.5095
        34.7214C37.635 33.81 39 31.7226 39 29.4C39 27.6752 38.2395 26.0582 36.9135 24.9508ZM27.1635 35.378C26.793
        36.5344 26.0032 37.4948 24.9502 38.0828L19.5 41.1306L14.0498 38.0828C12.9967 37.4948 12.207 36.5344 11.8365
        35.378L11.6122 34.692L16.4775 34.153V29.7724L17.2185 29.9782C18.7005 30.3898 20.2995 30.3898 21.7815 29.9782L22.5225
        29.7724V34.153L27.3877 34.692L27.1635 35.378ZM12.5775 21.56V15.5428L13.0455 15.4448C14.6543 15.1116 16.0875 14.2002
        17.082 12.8674L17.55 12.2402L18.018 12.8674C19.305 14.5922 21.2647 15.582 23.4 15.582H26.4225V21.56C26.4225 25.3918
        23.3122 28.518 19.5 28.518C15.6877 28.518 12.5775 25.3918 12.5775 21.56ZM33.2475 33.418H31.2487L31.1805 33.4082C30.849
        33.3298 30.5175 33.2808 30.2055 33.2416L24.2775 32.5752V28.8414L24.5212 28.665C26.8125 27.0284 28.1775 24.3726 28.1775
        21.56V13.818H23.4C20.8552 13.818 18.7492 11.8874 18.4665 9.408H16.6432C16.3507 11.8874 14.2447 13.818 11.7097
        13.818H10.8322V21.56C10.8322 24.3726 12.1973 27.0284 14.4885 28.665L14.7323 28.8414V32.5752L8.80425 33.2416C8.49225
        33.2808 8.1705 33.3298 7.82925 33.4082L7.761 33.418H5.7525C3.549 33.418 1.755 31.6148 1.755 29.4C1.755 27.3812 3.237
        25.7152 5.1675 25.4212V23.569C3.237 23.2848 1.755 21.609 1.755 19.5902C1.755 17.3754 3.549 15.5722 5.7525
        15.5722H6.63V14.6902C6.63 7.5558 12.402 1.7542 19.5 1.7542C26.598 1.7542 32.37 7.5558 32.37 14.6902V15.5722H33.2475C35.451
        15.5722 37.245 17.3754 37.245 19.5902C37.245 21.609 35.763 23.275 33.8325 23.569V25.4212C35.763 25.7054 37.245 27.3812
        37.245 29.4C37.245 31.6148 35.451 33.418 33.2475 33.418Z"
        fill={props.isSelected ? "white" : "var(--icons"}
      />
    </svg>
  );
}
export default WomanIcon;
