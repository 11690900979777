import React from "react";

const StudiesIcon = (props) => (
  <svg {...props} className={"icon"} width="143" height="77" viewBox="0 0 143 77" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M122.159 67.138L124.313 59.9558C124.662 58.795 125.73 58 126.942 58C128.064 58 129.073 58.6832 129.49 59.7252L132.354 66.8858C133.143 68.8564 131.691 71 129.569 71H125.032C123.023 71 121.581 69.0628 122.159 67.138Z" fill="#F5A506" />
    <path d="M31.5 65.0001V36.7055L68.8594 51.4648C69.9102 51.88 71.0788 51.8846 72.1329 51.4778L113.5 35.5138V65.0001C113.5 70.7991 108.799 75.5001 103 75.5001H42C36.201 75.5001 31.5 70.7991 31.5 65.0001Z" stroke="var(--svgColor)" strokeWidth="3" />
    <path d="M69.4065 51.5744L7.88244 27.3849C5.25176 26.3506 5.38059 22.5846 8.0758 21.7325L69.5972 2.28193C70.1779 2.09833 70.8007 2.09583 71.3829 2.27475L134.672 21.7262C137.399 22.5644 137.527 26.3775 134.862 27.3961L71.5753 51.5848C70.8764 51.8519 70.1028 51.8482 69.4065 51.5744Z" stroke="var(--svgColor)" strokeWidth="3" />
    <path d="M71 22L114.682 27.4367C117.52 27.7899 120.183 29.0006 122.315 30.9071V30.9071C125.296 33.5733 127 37.3833 127 41.3829V56.5" stroke="var(--svgColor)" strokeWidth="3" strokeLinecap="round" />
    <ellipse cx="127" cy="56.5" rx="4" ry="3.5" fill="#F5A506" />
  </svg>
);
export default StudiesIcon;
