import React from "react";

const RetirementIcon = (props) => (
  <svg {...props} className={"icon"} width="112" height="103" viewBox="0 0 112 103" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 99.0576H106.934" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M29 53V99" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
    <path d="M61 53V99" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
    <path d="M93 53V99" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
    <path d="M15 53V99" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
    <path d="M47 53V99" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
    <path d="M79 53V99" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
    <path d="M55.6761 21.0187C55.8498 20.9029 56.0773 20.9072 56.2465 21.0296L97.1161 50.5949C97.5089 50.8791 97.3079 51.5 96.823 51.5H11.6056C11.4501 51.5 11.3585 51.4545 11.2973 51.406C11.2262 51.3497 11.1619 51.26 11.127 51.1449C11.0921 51.0297 11.0959 50.9194 11.1238 50.8332C11.1478 50.7588 11.1989 50.6702 11.3282 50.584L55.6761 21.0187Z" stroke="var(--svgColor)" strokeWidth="3" />
    <rect x="37" y="4" width="36" height="36" rx="18" fill="var(--svgColor)" />
    <path d="M53.5714 33.3949V30.7323C51.9803 30.6892 50.4185 30.2762 49 29.5236L49.8067 26.2479C51.1815 27.0231 52.7145 27.4436 54.2773 27.4741C55.8235 27.4741 56.8656 26.8435 56.8656 25.7224C56.8656 24.6013 56.0084 23.9707 54.0084 23.2875C51.1345 22.2715 49.1681 20.8702 49.1681 18.155C49.2232 16.8907 49.7143 15.6896 50.551 14.7724C51.3878 13.8553 52.514 13.2836 53.7227 13.1626V10.5H56.3614V12.9699C57.7095 12.9879 59.0386 13.3044 60.2605 13.8983L59.4538 17.104C58.2592 16.4592 56.9335 16.1228 55.5882 16.123C53.9076 16.123 53.2857 16.9288 53.2857 17.7171C53.2857 18.5053 54.2269 19.2411 56.5126 20.1169C59.7227 21.2906 61 22.8321 61 25.3721C61.0018 26.7186 60.5077 28.0145 59.6195 28.9924C58.7314 29.9702 57.517 30.5554 56.2269 30.6272V33.5L53.5714 33.3949Z" fill="#F5A506" />
  </svg>
);

export default RetirementIcon;
