import React from "react";

const PerformanceAnalyticsTalentIcon = (props) => (
    <svg {...props} className={"icon"} width="112" height="95" viewBox="0 0 111 95" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_7208_205)">
            <path d="M87.3149 48.1113L86.7283 49.0782L87.8161 49.7382L88.344 48.5806L87.3149 48.1113ZM100.09 22.0242C99.8711 21.4391 99.2195 21.142 98.6344 21.3606L89.0995 24.9231C88.5144 25.1417 88.2173 25.7932 88.4359 26.3783C88.6545 26.9634 89.306 27.2605 89.8911 27.0419L98.3666 23.8753L101.533 32.3508C101.752 32.9359 102.403 33.233 102.989 33.0144C103.574 32.7958 103.871 32.1442 103.652 31.5591L100.09 22.0242ZM69.0227 37.0127L69.6094 36.0457L68.685 35.4849L68.0832 36.3831L69.0227 37.0127ZM62.0605 45.3704C61.7128 45.8893 61.8515 46.5918 62.3704 46.9395C62.8893 47.2872 63.5918 47.1485 63.9395 46.6296L62.0605 45.3704ZM88.344 48.5806L100.059 22.8892L98.0012 21.9508L86.2859 47.6421L88.344 48.5806ZM87.9016 47.1444L69.6094 36.0457L68.436 37.9796L86.7283 49.0782L87.9016 47.1444ZM68.0832 36.3831L62.0605 45.3704L63.9395 46.6296L69.9622 37.6423L68.0832 36.3831Z" fill="#F5A506" />
            <circle cx="68.8386" cy="37.3221" r="3.76989" fill="#F5A506" />
            <circle cx="87.0382" cy="47.4437" r="3.76989" fill="#F5A506" />
            <g clipPath="url(#clip1_7208_205)">
                <path d="M79.156 57.5701C78.7712 57.1159 78.2685 56.7768 77.7034 56.5899C77.1382 56.403 76.5324 56.3756 75.9527 56.5108C75.373 56.6459 74.8418 56.9384 74.4176 57.3559C73.9933 57.7735 73.6925 58.3 73.5481 58.8774L72.6594 62.4321L69.3185 69.1134L67.0561 69.8682L67.7022 68.7492C68.1169 67.9192 68.203 66.9631 67.9431 66.0723C67.6833 65.1815 67.0966 64.4217 66.3006 63.9448C65.5046 63.468 64.5579 63.3093 63.6498 63.5005C62.7418 63.6917 61.9395 64.2187 61.4033 64.976C57.856 69.9895 56.9539 71.2639 56.7061 71.624L56.7012 71.6209C53.9216 75.5333 54.1646 81.248 54.3587 85.8403C54.388 86.5123 54.4149 87.1635 54.4319 87.7849L56.9319 87.7153C56.9136 87.083 56.8856 86.4189 56.8563 85.7347C56.678 81.5233 56.4559 76.2828 58.7435 73.0626C58.824 72.9484 63.4432 66.4213 63.4432 66.4213C63.5526 66.2671 63.6974 66.1413 63.8653 66.0543C64.0333 65.9674 64.2196 65.9218 64.4087 65.9215C64.6105 65.92 64.8092 65.9709 64.9855 66.0691C65.1618 66.1673 65.3096 66.3094 65.4146 66.4818C65.5184 66.6406 65.5808 66.8229 65.5959 67.0121C65.611 67.2013 65.5785 67.3912 65.5013 67.5645L62.723 72.3729C62.3831 72.9584 62.2041 73.6234 62.2042 74.3004H64.7042C64.704 74.0636 64.7668 73.8311 64.8861 73.6265L65.1712 73.1327L71.0042 71.1863L71.0628 71.2156L71.0921 71.157L74.648 69.9705C75.2548 69.7414 75.7945 69.3639 76.2178 68.8725L78.9627 65.6695L78.9656 65.67L78.9664 65.6651L82.1565 61.9426C82.2665 61.8146 82.4021 61.7112 82.5545 61.6388C82.7069 61.5665 82.8728 61.5269 83.0415 61.5227C83.213 61.5241 83.3824 61.5594 83.5402 61.6265C83.6979 61.6937 83.8408 61.7913 83.9607 61.9139C84.1436 62.1371 84.246 62.4154 84.2513 62.7038C84.2566 
                62.9923 84.1646 63.2742 83.99 63.5039L76.7659 73.8243C76.5839 74.0838 76.3716 74.3206 76.1336 74.5299L71.2275 78.8218C69.521 80.3148 65.5793 84.1606 65.8296 87.6213L68.3222 87.4418C68.1843 85.5278 70.688 82.6158 72.873 80.7029L77.7803 76.4097C78.1699 76.0683 78.5171 75.6812 78.8142 75.2568L86.0383 64.9376C86.4539 64.3811 86.7044 63.7189 86.7614 63.0267C86.8183 62.3346 86.6794 61.6403 86.3604 61.0234C86.0415 60.4065 85.5553 59.8917 84.9576 59.538C84.3599 59.1843 83.6748 59.0059 82.9805 59.0233C82.4617 59.0363 81.9514 59.158 81.4825 59.3804C81.0136 59.6029 80.5966 59.9212 80.2583 60.3148L79.7619 60.8941L79.8787 60.1933C79.9561 59.7298 79.9315 59.255 79.8067 58.802C79.6819 58.3489 79.4598 57.9286 79.156 57.5701ZM74.3208 67.2435C74.1835 67.4057 74.0094 67.5327 73.813 67.614L72.6736 67.9942L74.9568 63.4276C74.9973 63.3458 75.0288 63.2599 75.0508 63.1713L75.9724 59.4835C76.0122 59.3243 76.1042 59.1829 76.2336 59.082C76.363 58.981 76.5224 58.9262 76.6865 58.9263C76.7934 58.9263 76.899 58.9495 76.9959 58.9944C77.0929 59.0393 77.1789 59.1047 77.248 59.1862C77.3171 59.2677 77.3677 59.3633 77.3961 59.4663C77.4246 59.5692 77.4303 59.6772 77.4129 59.7826L76.6153 64.5661L74.3208 67.2435Z" fill="var(--svgColor)" />
                <path d="M13.0196 59.0234C12.4973 59.0086 11.9778 59.1055 11.4959 59.3076C11.0141 59.5097 10.5809 59.8124 10.2254 60.1953C9.6441 60.8355 9.30065 61.6561 9.25264 62.5194C9.20463 63.3828 9.45499 64.2364 9.96172 64.9371L17.1858 75.2563C17.4832 75.6815 17.8307 76.0692 18.221 76.4111L23.127 80.7031C25.3121 82.6159 27.8157 85.5279 27.6778 87.442L30.1705 87.6214C30.4207 84.1607 26.479 80.3149 24.7725 78.822L19.8677 74.5312C19.6291 74.3216 19.4164 74.0841 19.2342 73.8238L12.01 63.5034C11.8354 63.2737 11.7434 62.9919 11.7489 62.7034C11.7545 62.4149 11.8572 62.1368 12.0406 61.914C12.1603 61.7917 12.303 61.6941 12.4606 61.627C12.6181 61.5598 12.7873 61.5244 12.9585 61.5228C13.127 61.527 13.2927 61.5664 13.4449 61.6387C13.5971 61.7109 13.7325 61.8143 13.8423 61.9421L17.0335 65.6651L17.0344 65.6701L17.0375 65.6696L19.7847 68.8745C20.2187 69.3758 20.7732 69.7584 21.3961 69.9859L24.9083 71.1577L24.9373 71.2158L24.9955 71.1867L30.8297 73.1331L31.1165 73.6291C31.2338 73.9225 31.2947 74.2354 31.296 74.5514H33.796C33.7959 73.7957 33.619 73.0506 33.2796 72.3755L30.5342 67.6312C30.4441 67.4511 30.4014 67.2509 30.4105 67.0496C30.4195 66.8484 30.4798 66.6528 30.5858 66.4814C30.6917 66.3101 30.8398 66.1687 31.0158 66.0707C31.1918 65.9727 31.3899 65.9214 31.5914 65.9216C31.7802 65.9219 31.9663 65.9673 32.134 66.054C32.3018 66.1407 32.4464 66.2663 32.5557 66.4203C32.5557 66.4203 37.1761 72.9486 37.2603 73.0688C39.5443 76.2829 39.3221 81.5234 39.1439 85.7348C39.1146 86.419 39.0865 87.0831 39.0682 87.7154L41.5682 87.785C41.5853 87.1637 
                41.6121 86.5124 41.6414 85.8404C41.8355 81.2481 42.0784 75.5334 39.2989 71.6211L39.2941 71.6241C39.0462 71.264 38.1441 69.9896 34.5956 64.975C34.0557 64.2123 33.246 63.6835 32.3307 63.4957C31.4154 63.3079 30.4629 63.4753 29.6664 63.9638C28.8698 64.4524 28.2889 65.2255 28.0414 66.1265C27.7939 67.0276 27.8982 67.989 28.3334 68.8159L28.9415 69.8676L26.6818 69.1139L23.3407 62.4322L22.452 58.8769C22.2493 58.066 21.7407 57.3649 21.0327 56.9207C20.3247 56.4764 19.4722 56.3234 18.6538 56.4936C17.8355 56.6639 17.1148 57.1442 16.6427 57.834C16.1707 58.5238 15.9839 59.3696 16.1214 60.194L16.2383 60.8949L15.7406 60.3143C15.4025 59.9208 14.9857 59.6026 14.517 59.3802C14.0483 59.1579 13.5382 59.0363 13.0196 59.0234ZM18.5872 59.7833C18.5697 59.6778 18.5753 59.5699 18.6038 59.4668C18.6322 59.3638 18.6827 59.2681 18.7518 59.1866C18.8209 59.1051 18.907 59.0395 19.004 58.9946C19.101 58.9497 19.2066 58.9264 19.3135 58.9264C19.4775 58.9263 19.6369 58.981 19.7663 59.0818C19.8957 59.1827 19.9876 59.3239 20.0276 59.483L20.9492 63.1714C20.9712 63.26 21.0027 63.3459 21.0432 63.4277L23.3267 67.9949L22.2309 67.6294C22.0183 67.5499 21.8293 67.4178 21.6816 67.2455L19.3846 64.5657L18.5872 59.7833Z" fill="var(--svgColor)" />
                <path d="M73.0136 25.1054L57.5456 21.2165L49.102 7.57581C48.9769 7.39777 48.8117 7.2526 48.62 7.15243C48.4283 7.05227 48.2157 7 48 7C47.7843 7 47.5717 7.05227 47.38 7.15243C47.1883 7.2526 47.0231 7.39777 46.898 7.57581L38.4544 21.2165L22.9864 25.1054C22.7721 25.1594 22.5749 25.2679 22.4134 25.4205C22.2519 25.5731 22.1314 25.7648 22.0633 25.9776C21.9953 26.1904 21.9818 26.4173 22.0242 26.6369C22.0667 26.8564 22.1636 27.0614 22.3059 27.2325L32.551 39.5498L31.4389 55.5903C31.4232 55.813 31.4637 56.036 31.5565 56.2385C31.6494 56.441 31.7916 56.6162 31.9697 56.7477C32.1479 56.8792 32.3561 56.9625 32.5749 56.99C32.7936 57.0174 33.0157 56.988 33.2201 56.9045L48 50.8764L62.7799 56.9046C62.9843 56.9881 63.2064 57.0175 63.4251 56.99C63.6439 56.9626 63.8521 56.8792 64.0303 56.7478C64.2084 56.6163 64.3506 56.4411 64.4435 56.2386C64.5363 56.0361 64.5768 55.813 64.5611 55.5903L63.449 39.5498L73.6941 27.2325C73.8364 27.0614 73.9333 26.8564 73.9758 26.6368C74.0182 26.4173 74.0047 26.1904 73.9367 25.9776C73.8686 25.7648 73.7481 25.573 73.5866 25.4204C73.4251 25.2678 73.2279 25.1594 73.0136 25.1054ZM61.1206 38.2606C60.9025 38.5225 60.7941 38.8603 60.8185 39.202L61.8227 53.6747L48.485 48.2349C48.1738 48.1081 47.8262 48.1081 47.515 48.2349L34.1773 53.6748L35.1815 39.202C35.2059 38.8603 35.0975 38.5225 34.8794 38.2607L25.6397 27.1521L39.5906 23.6452C39.9173 23.5626 40.1998 23.3552 40.379 23.0664L48 10.7542L55.621 23.0664C55.8002 23.3552 56.0827 23.5626 56.4094 23.6452L70.3603 27.1521L61.1206 38.2606Z" fill="var(--svgColor)" />
                <path d="M48 36.3706C50.028 36.3706 51.9836 37.2165 53.4334 38.7395C54.6606 40.0287 55.4389 41.7134 55.6501 43.5188H40.3499C40.5611 41.7134 41.3394 40.0287 42.5665 38.7395C44.0164 37.2165 45.9719 36.3706 48 36.3706Z" fill="#484848" stroke="var(--svgColor)" strokeWidth="2" />
                <path d="M52.9583 28.3891C52.9583 31.1703 50.7289 33.4076 48 33.4076C45.271 33.4076 43.0416 31.1703 43.0416 28.3891C43.0416 25.608 45.271 23.3706 48 23.3706C50.7289 23.3706 52.9583 25.608 52.9583 28.3891Z" fill="#484848" stroke="var(--svgColor)" strokeWidth="2" />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_7208_205">
                <rect x="111" width="95" height="111" rx="2" transform="rotate(90 111 0)" fill="var(--svgColor)" />
            </clipPath>
            <clipPath id="clip1_7208_205">
                <rect width="80" height="80" fill="var(--svgColor)" transform="translate(8 7)" />
            </clipPath>
        </defs>
    </svg>
);

export default PerformanceAnalyticsTalentIcon;
