import React from "react";

const ReasonForPointAwardingIcon = (props) => (
  <svg {...props} className={"icon"} width="112" height="96" viewBox="0 0 112 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5800_18589)">
      <path d="M80.0076 88.4662C80.0078 89.5709 79.1123 90.4666 78.0076 90.4666H15.9883C14.8837 90.4666 13.9883 89.5712 13.9883 88.4666V6.43018C13.9883 5.32561 14.8837 4.43018 15.9883 4.43018H56.9918C57.5128 4.43018 58.0132 4.63347 58.3866 4.99681L79.3899 25.4348C79.7767 25.8113 79.995 26.3281 79.9951 26.8678L80.0076 88.4662Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
      <path d="M26.3401 49.8257H66.207" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M26.3401 63.6631H66.207" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M26.3401 77.5H66.207" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M57.8047 4.43018V24.3555C57.8047 24.9078 58.2524 25.3555 58.8047 25.3555H79.5446" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
      <path d="M74.1475 76.4567L80.8693 62.5553C81.1207 62.0354 81.7892 61.8823 82.2418 62.241L86.1642 65.3497C86.2488 65.4167 86.3447 65.468 86.4474 65.5012L91.5886 67.161C92.0774 67.3188 92.336 67.8523 92.1571 68.3337L87.0373 82.114C86.7871 82.7874 85.8886 82.9125 85.464 82.3331L81.3966 76.7831C81.1811 76.489 80.8098 76.3536 80.4556 76.4398L75.1731 77.7259C74.4322 77.9063 73.8156 77.1432 74.1475 76.4567Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="2.70642" />
      <path d="M95.0538 81.6395L89.4172 67.2839C89.204 66.7408 89.5546 66.1411 90.1325 66.0607L96.0855 65.2316C96.2235 65.2124 96.3551 65.1614 96.4702 65.0828L100.042 62.6416C100.491 62.334 101.109 62.4926 101.355 62.9788L107.958 76.0163C108.283 76.6571 107.765 77.4018 107.051 77.3202L100.215 76.5386C99.8526 76.4972 99.5011 76.6779 99.3239 76.9965L96.6819 81.7482C96.3114 82.4146 95.3324 82.3493 95.0538 81.6395Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="2.70642" />
      <circle cx="90.6663" cy="50.4916" r="16.6384" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="2.70642" />
    </g>
    <defs>
      <clipPath id="clip0_5800_18589">
        <rect x="111.5" y="0.415039" width="95" height="111" rx="2" transform="rotate(90 111.5 0.415039)" fill="var(--svgColor)" />
      </clipPath>
    </defs>
  </svg>
);

export default ReasonForPointAwardingIcon;
