import React from "react";

const PublicationIconNumbers = (props) => (
  <svg {...props} className={"icon"}  width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z"
      fill="var(--icons)"
    />
    <path
      d="M5.66406 17.6094L6.64844 8.9375H16.2109V11.7617H9.42578L9.00391 15.4297C9.80859 15 10.6641 14.7852 11.5703 14.7852C13.1953
      14.7852 14.4688 15.2891 15.3906 16.2969C16.3125 17.3047 16.7734 18.7148 16.7734 20.5273C16.7734 21.6289 16.5391 22.6172 16.0703
      23.4922C15.6094 24.3594 14.9453 25.0352 14.0781 25.5195C13.2109 25.9961 12.1875 26.2344 11.0078 26.2344C9.97656 26.2344 9.01953
      26.0273 8.13672 25.6133C7.25391 25.1914 6.55469 24.6016 6.03906 23.8438C5.53125 23.0859 5.26172 22.2227 5.23047 21.2539H8.58203C8.65234
      21.9648 8.89844 22.5195 9.32031 22.918C9.75 23.3086 10.3086 23.5039 10.9961 23.5039C11.7617 23.5039 12.3516 23.2305 12.7656 22.6836C13.1797
      22.1289 13.3867 21.3477 13.3867 20.3398C13.3867 19.3711 13.1484 18.6289 12.6719 18.1133C12.1953 17.5977 11.5195 17.3398 10.6445 17.3398C9.83984
      17.3398 9.1875 17.5508 8.6875 17.9727L8.35938 18.2773L5.66406 17.6094ZM30.4258 18.9453C30.4258 21.3047 29.9375 23.1094 28.9609 24.3594C27.9844
      25.6094 26.5547 26.2344 24.6719 26.2344C22.8125 26.2344 21.3906 25.6211 20.4062 24.3945C19.4219 23.168 18.918 21.4102 18.8945 19.1211V15.9805C18.8945
      13.5977 19.3867 11.7891 20.3711 10.5547C21.3633 9.32031 22.7891 8.70312 24.6484 8.70312C26.5078 8.70312 27.9297 9.31641 28.9141 10.543C29.8984 11.7617
      30.4023 13.5156 30.4258 15.8047V18.9453ZM27.0391 15.5C27.0391 14.0859 26.8438 13.0586 26.4531 12.418C26.0703 11.7695 25.4688 11.4453 24.6484 11.4453C23.8516
      11.4453 23.2617 11.7539 22.8789 12.3711C22.5039 12.9805 22.3047 13.9375 22.2812 15.2422V19.3906C22.2812 20.7812 22.4688 21.8164 22.8438 22.4961C23.2266
      23.168 23.8359 23.5039 24.6719 23.5039C25.5 23.5039 26.0977 23.1797 26.4648 22.5312C26.832 21.8828 27.0234 20.8906 27.0391 19.5547V15.5Z"
      fill="var(--drawer)"
    />
  </svg>
);
export default PublicationIconNumbers;
