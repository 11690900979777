import React from "react";
import Icon from "./icon";

function ShrinkIcon(props) {
  return (
    <Icon viewBox="0 0 511.998 511.998" {...props}>
      <g>
        <path
          d="M490.145,3.757L358.399,135.503V64.002c0-7.074-5.726-12.8-12.8-12.8c-7.074,0-12.8,5.726-12.8,12.8
                    v102.4c0,7.074,5.726,12.8,12.8,12.8h102.4c7.074,0,12.8-5.726,12.8-12.8c0-7.074-5.726-12.8-12.8-12.8h-71.492L508.244,21.856
                    c5.001-5.001,5.001-13.099,0-18.099C503.244-1.252,495.146-1.252,490.145,3.757z"
        />
        <path
          d="M3.754,508.247c5.001,5.001,13.099,5.001,18.099,0l131.746-131.746v71.501
                    c0,7.074,5.726,12.8,12.8,12.8s12.8-5.726,12.8-12.8v-102.4c0-7.074-5.726-12.8-12.8-12.8h-102.4c-7.074,0-12.8,5.726-12.8,12.8
                    s5.726,12.8,12.8,12.8h71.492L3.754,490.148C-1.247,495.149-1.247,503.247,3.754,508.247z"
        />
      </g>
    </Icon>
  );
}
export default ShrinkIcon;
