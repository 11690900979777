import React from "react";

const PositionGroupsForEvaluationsIcon = (props) => (
  <svg  {...props} className={"icon"} width="112" height="96" viewBox="0 0 112 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6437_11453)">
      <path d="M78.6393 12.5361H32.9905C32.5839 12.5361 32.2542 12.8658 32.2542 13.2724V42.5894C32.2542 42.996 32.5838 43.3257 32.9905 43.3257H78.6393C79.046 43.3257 79.3756 42.996 79.3756 42.5894V13.2724C79.3756 12.8658 79.046 12.5361 78.6393 12.5361Z" stroke="var(--svgColor)" strokeWidth="2.20882" strokeMiterlimit="10" />
      <path d="M14.8149 60.4371V50.3252H96.8149V60.4371" stroke="var(--svgColor)" strokeWidth="1.5" />
      <path d="M55.8149 43.3257L55.8149 61.0708" stroke="var(--svgColor)" strokeWidth="1.5" />
      <path d="M70.8477 60.4375H40.7822C40.3755 60.4375 40.0459 60.7671 40.0459 61.1738V84.5855C40.0459 84.9921 40.3755 85.3218 40.7822 85.3218H70.8477C71.2544 85.3218 71.584 84.9921 71.584 84.5855V61.1738C71.584 60.7671 71.2544 60.4375 70.8477 60.4375Z" stroke="var(--svgColor)" strokeWidth="2.20882" strokeMiterlimit="10" />
      <path d="M108.177 60.437H78.1119C77.7053 60.437 77.3756 60.7667 77.3756 61.1733V84.585C77.3756 84.9916 77.7053 85.3213 78.1119 85.3213H108.177C108.584 85.3213 108.914 84.9916 108.914 84.585V61.1733C108.914 60.7667 108.584 60.437 108.177 60.437Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="2.20882" strokeMiterlimit="10" />
      <path d="M33.1444 60.437H3.07886C2.67223 60.437 2.34259 60.7667 2.34259 61.1733V84.585C2.34259 84.9916 2.67223 85.3213 3.07886 85.3213H33.1444C33.5511 85.3213 33.8807 84.9916 33.8807 84.585V61.1733C33.8807 60.7667 33.5511 60.437 33.1444 60.437Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="2.20882" strokeMiterlimit="10" />
    </g>
    <defs>
      <clipPath id="clip0_6437_11453">
        <rect x="111.315" y="0.228027" width="95" height="111" rx="2" transform="rotate(90 111.315 0.228027)" fill="var(--svgColor)" />
      </clipPath>
    </defs>
  </svg>
);

export default PositionGroupsForEvaluationsIcon;