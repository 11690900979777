import React from "react";
import Icon from "./icon";

function OpenLock(props) {
  return (
    <Icon viewBox="0 0 592.875 592.875" {...props}>
      <path
        d="M525.938,286.875h-229.5V143.438C296.438,65.025,231.413,0,153,0S9.562,65.025,9.562,143.438v28.688v47.812
				c0,15.3,13.388,28.688,28.688,28.688s28.688-13.388,28.688-28.688v-47.812v-28.688c0-47.812,38.25-86.062,86.062-86.062
				s86.062,38.25,86.062,86.062v143.438c-32.513,0-57.375,24.862-57.375,57.375v95.625c0,84.15,68.85,153,153,153h95.625
				c84.15,0,153-68.85,153-153V344.25C583.312,311.737,558.45,286.875,525.938,286.875z M392.062,437.963v49.725
				c0,5.737-3.825,9.562-9.562,9.562s-9.562-3.825-9.562-9.562v-49.725c-11.475-3.825-19.125-15.301-19.125-26.775
				c0-15.3,13.388-28.688,28.688-28.688s28.688,13.388,28.688,28.688C411.188,424.575,403.537,434.138,392.062,437.963z"
      />
    </Icon>
  );
}
export default OpenLock;
