import React from "react";

const TotemsIcon = (props) => (
    <svg {...props} className={"icon"} width="112" height="95" viewBox="0 0 112 95" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_5461_16356)">
            <path fillRule="evenodd" clipRule="evenodd" d="M83.9353 0.32959C85.8683 0.32959 87.4353 1.89659 87.4353 3.82959L87.4353 90.8035C87.4353 92.7365 85.8683 94.3035 83.9353 94.3035L28.2455 94.3034C26.3126 94.3034 24.7455 92.7364 24.7455 90.8034L24.7455 3.82959C24.7455 1.89659 26.3125 0.329588 28.2455 0.329588L83.9353 0.32959ZM84.4353 3.82959C84.4353 3.55345 84.2114 3.32959 83.9353 3.32959L28.2455 3.32959C27.9694 3.32959 27.7455 3.55345 27.7455 3.82959L27.7455 90.8034C27.7455 91.0796 27.9694 91.3034 28.2455 91.3034L83.9353 91.3035C84.2114 91.3035 84.4353 91.0796 84.4353 90.8035L84.4353 3.82959Z" fill="var(--svgColor)" />
            <path d="M38.725 64.5742L73.4558 64.5742" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
            <path d="M56.0905 10.3577L56.0905 10.3318" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
            <path d="M12.3597 92.7852L99.8209 92.7852" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
            <rect x="38.725" y="19.0337" width="34.7309" height="36.8643" rx="2" fill="#F5A506" />
        </g>
        <defs>
            <clipPath id="clip0_5461_16356">
                <rect x="111.59" width="95" height="111" rx="2" transform="rotate(90 111.59 0)" fill="var(--svgColor)" />
            </clipPath>
        </defs>
    </svg>
);

export default TotemsIcon;
