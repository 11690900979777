import React from "react";

const HealthInstitutionIcon = (props) => (
  <svg {...props} className={"icon"} width="121" height="120" viewBox="0 0 121 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 116.058H113" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M110 43H90C88.8954 43 88 43.8954 88 45V114C88 115.105 88.8954 116 90 116H110C111.105 116 112 115.105 112 114V45C112 43.8954 111.105 43 110 43Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
    <path d="M31 43H11C9.89543 43 9 43.8954 9 45V114C9 115.105 9.89543 116 11 116H31C32.1046 116 33 115.105 33 114V45C33 43.8954 32.1046 43 31 43Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
    <path d="M86 28H35C33.8954 28 33 28.8954 33 30V114C33 115.105 33.8954 116 35 116H86C87.1046 116 88 115.105 88 114V30C88 28.8954 87.1046 28 86 28Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
    <path d="M72 99H49C47.8954 99 47 99.8954 47 101V114C47 115.105 47.8954 116 49 116H72C73.1046 116 74 115.105 74 114V101C74 99.8954 73.1046 99 72 99Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
    <rect x="15.5" y="53.5" width="12" height="12" rx="1.5" fill="var(--svgColor)" stroke="var(--svgColor)" />
    <rect x="94.5" y="53.5" width="12" height="12" rx="1.5" fill="var(--svgColor)" stroke="var(--svgColor)" />
    <rect x="62.5" y="45.5" width="12" height="12" rx="1.5" fill="var(--svgColor)" stroke="var(--svgColor)" />
    <rect x="45.5" y="45.5" width="12" height="12" rx="1.5" fill="var(--svgColor)" stroke="var(--svgColor)" />
    <rect x="15.5" y="70.5" width="12" height="12" rx="1.5" fill="var(--svgColor)" stroke="var(--svgColor)" />
    <rect x="94.5" y="70.5" width="12" height="12" rx="1.5" fill="var(--svgColor)" stroke="var(--svgColor)" />
    <rect x="62.5" y="62.5" width="12" height="12" rx="1.5" fill="var(--svgColor)" stroke="var(--svgColor)" />
    <rect x="45.5" y="62.5" width="12" height="12" rx="1.5" fill="var(--svgColor)" stroke="var(--svgColor)" />
    <rect x="15.5" y="87.5" width="12" height="12" rx="1.5" fill="var(--svgColor)" stroke="var(--svgColor)" />
    <rect x="94.5" y="87.5" width="12" height="12" rx="1.5" fill="var(--svgColor)" stroke="var(--svgColor)" />
    <rect x="62.5" y="79.5" width="12" height="12" rx="1.5" fill="var(--svgColor)" stroke="var(--svgColor)" />
    <rect x="45.5" y="79.5" width="12" height="12" rx="1.5" fill="var(--svgColor)" stroke="var(--svgColor)" />
    <rect x="42" y="2" width="36" height="36" rx="18" fill="var(--svgColor)" />
    <path d="M70 18.4468C70 17.8945 69.5523 17.4468 69 17.4468H63.5532C63.0009 17.4468 62.5532 16.9991 62.5532 16.4468V11C62.5532 10.4477 62.1055 10 61.5532 10H58.4468C57.8945 10 57.4468 10.4477 57.4468 11V16.4468C57.4468 16.9991 56.9991 17.4468 56.4468 17.4468H51C50.4477 17.4468 50 17.8945 50 18.4468V21.5532C50 22.1055 50.4477 22.5532 51 22.5532H56.4468C56.9991 22.5532 57.4468 23.0009 57.4468 23.5532V29C57.4468 29.5523 57.8945 30 58.4468 30H61.5532C62.1055 30 62.5532 29.5523 62.5532 29V23.5532C62.5532 23.0009 63.0009 22.5532 63.5532 22.5532H69C69.5523 22.5532 70 22.1055 70 21.5532V18.4468Z" fill="#FF4040" />
  </svg>
);

export default HealthInstitutionIcon;
