import React from "react";

const HomePublicationsIcon = (props) => (
  <svg {...props} className={"icon"} width="100" height="94" viewBox="0 0 100 94" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M51.5375 2.42873L95.4967 46.0426C95.8136 46.357 95.5909 46.8975 95.1445 46.8975H89.6124C87.6794 46.8975 86.1124 48.4645 86.1124 50.3975V92C86.1124 92.2761 85.8886 92.5 85.6124 92.5H14.3984C14.1223 92.5 13.8984 92.2761 13.8984 92V50.3975C13.8984 48.4645 12.3314 46.8975 10.3984 46.8975H5.02865C4.57634 46.8975 4.35649 46.3448 4.68525 46.0341L50.8419 2.42026C51.0381 2.23491 51.3459 2.23866 51.5375 2.42873Z" stroke="var(--svgColor)" strokeWidth="3" />
    <rect x="39.4321" y="66.0013" width="19.7593" height="26.0256" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="3" />
  </svg>

);

export default HomePublicationsIcon;
