import React from "react";
import Icon from "./icon";

function ShieldAlert(props) {
  return (
    <Icon viewBox="0 0 28.125 28.125" {...props}>
      <path
        d="M14.063,0L1.659,6.617v0.464c0,13.979,11.906,20.767,12.026,20.834l0.378,0.21l0.377-0.21
			c0.12-0.067,12.026-6.854,12.026-20.834V6.617L14.063,0z M14.063,26.329C12.13,25.096,3.445,18.92,3.214,7.544l10.849-5.787
			l10.849,5.786C24.681,18.919,15.996,25.094,14.063,26.329z"
      />
      <path
        d="M4.004,8.001c0.416,10.17,7.652,15.812,10.059,17.412c2.407-1.601,9.643-7.241,10.059-17.411L14.063,2.636L4.004,8.001z
			 M14,21.191c-1.067,0-1.778-0.771-1.778-1.799c0-1.047,0.731-1.799,1.778-1.799c1.048,0,1.739,0.752,1.759,1.799
			C15.759,20.421,15.067,21.191,14,21.191z M15.127,16.605h-2.253l-0.456-8.954h3.144L15.127,16.605z"
      />
    </Icon>
  );
}
export default ShieldAlert;
