import React from "react";

const NineBoxClassificationIcon = (props) => (
  <svg {...props} className={"icon"} width="112" height="96" viewBox="0 0 112 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6233_16935)">
      <path d="M100.132 86.8378L100.132 8.49172C100.132 7.61042 99.4172 6.896 98.5359 6.896L13.464 6.89599C12.5827 6.89599 11.8683 7.61042 11.8683 8.49171L11.8683 86.8378C11.8683 87.7191 12.5827 88.4335 13.464 88.4335L98.5359 88.4335C99.4172 88.4335 100.132 87.7191 100.132 86.8378Z" stroke="var(--svgColor)" strokeWidth="2.39358" strokeMiterlimit="10" />
      <path d="M12.8586 61.2544L100.132 61.2544" stroke="var(--svgColor)" strokeWidth="2.39358" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M12.8586 34.0752L100.132 34.0752" stroke="var(--svgColor)" strokeWidth="2.39358" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M41.9495 88.4341L41.9495 6.89649" stroke="var(--svgColor)" strokeWidth="2.39358" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M71.0405 88.4341L71.0405 6.89649" stroke="var(--svgColor)" strokeWidth="2.39358" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M100.132 88.4341L100.132 6.89649" stroke="var(--svgColor)" strokeWidth="2.39358" strokeMiterlimit="10" strokeLinecap="round" />
      <circle cx="58.6943" cy="20.3916" r="5.57178" fill="#F5A506" />
      <circle cx="86.6968" cy="42.0933" r="5.57178" fill="#F5A506" />
      <circle cx="28.6945" cy="72.7139" r="5.57178" fill="#FF4040" />
      <circle cx="58.6943" cy="51.1523" r="5.57178" fill="#FF592C" />
    </g>
    <defs>
      <clipPath id="clip0_6233_16935">
        <rect x="111.5" y="0.165039" width="95" height="111" rx="2" transform="rotate(90 111.5 0.165039)" fill="var(--svgColor)" />
      </clipPath>
    </defs>
  </svg>

);

export default NineBoxClassificationIcon;
