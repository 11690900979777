import React from "react";

const IcariusSmartIcon = (props) => (
  <svg {...props} className={"icon"} width="111" height="96" viewBox="0 0 111 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7343_176)">
      <path d="M55.2442 82.0782C81.1093 82.0782 102.077 66.4828 102.077 47.2449C102.077 28.007 81.1093 12.4116 55.2442 12.4116C29.3791 12.4116 8.41135 28.007 8.41135 47.2449C8.41135 66.4828 29.3791 82.0782 55.2442 82.0782Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="4" strokeMiterlimit="10" />
      <path fillRule="evenodd" clipRule="evenodd" d="M7.70746 47.2603C7.70746 34.3729 18.2201 23.8604 31.1074 23.8604C38.2831 23.8604 43.8393 26.9335 47.9252 31.1765C51.9061 35.3106 54.6967 40.4413 57.4193 45.4471L57.6875 45.94C60.4795 51.0698 63.2742 56.0181 66.5665 59.4607C68.2129 61.1821 69.935 62.5743 71.9637 63.5327C73.9959 64.4927 76.3051 65.0031 79.1073 65.0031C88.9476 65.0031 96.8501 57.1006 96.8501 47.2603C96.8501 37.4736 88.894 29.5175 79.1073 29.5175C70.9524 29.5175 66.1895 34.2485 62.1051 40.617C61.1284 38.8698 60.0724 37.0889 58.9372 35.2817C63.5724 28.8651 69.5482 23.8604 79.1073 23.8604C92.008 23.8604 102.507 34.3596 102.507 47.2603C102.507 60.1476 91.9946 70.6602 79.1073 70.6602C71.9991 70.6602 66.4438 67.5876 62.3987 63.3462C58.5345 59.2944 55.8268 54.2864 53.1701 49.3729C53.0274 49.1088 52.8847 48.845 52.7421 48.5817C49.9636 43.4522 47.1816 38.502 43.8607 35.0581C40.5132 31.5866 36.8417 29.5175 31.1074 29.5175C21.2671 29.5175 13.3646 37.42 13.3646 47.2603C13.3646 57.047 21.3207 65.0031 31.1074 65.0031C39.2972 65.0031 44.11 60.0511 48.2173 53.4943L48.2436 53.5429C49.1845 55.2817 50.1632 57.0904 51.2826 58.9184C46.656 65.4902 40.7169 70.6602 31.1074 70.6602C18.2067 70.6602 7.70746 60.161 7.70746 47.2603Z" fill="var(--svgColor)" />
    </g>
    <defs>
      <clipPath id="clip0_7343_176">
        <rect x="111" y="0.228027" width="95" height="111" rx="2" transform="rotate(90 111 0.228027)" fill="var(--svgColor)" />
      </clipPath>
    </defs>
  </svg>
);

export default IcariusSmartIcon;