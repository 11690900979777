import React from "react";

const SpecialtyIcon = (props) => (
  <svg {...props} className={"icon"} width="118" height="114" viewBox="0 0 118 114" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M58.6795 29.7305C58.9721 29.5321 59.3477 29.5029 59.6674 29.6537L97.5781 47.5368C98.1596 47.8111 98.3349 48.5549 97.937 49.06L74.0519 79.3804C73.9346 79.5293 73.7774 79.6418 73.5986 79.7048L36.8488 92.6491C36.2501 92.8599 35.609 92.4669 35.5253 91.8377L29.9448 49.8843C29.8948 49.5088 30.0613 49.1373 30.3748 48.9248L58.6795 29.7305Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round"/>
    <path d="M3.78122 49.6086C2.59969 45.9022 3.93332 41.8561 7.08722 39.5787L54.0776 5.64666C57.2401 3.36299 61.5137 3.37659 64.6617 5.68035L110.993 39.5871C114.104 41.8642 115.419 45.8704 114.263 49.5487L96.5562 105.883C95.3775 109.633 91.9013 112.184 87.9704 112.184H30.3059C26.3884 112.184 22.9209 109.65 21.7311 105.917L3.78122 49.6086Z" stroke="var(--svgColor)" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M59.3828 4.58541V55.7583C59.3828 56.3965 59.9725 56.8714 60.596 56.7353L113.803 45.127" stroke="var(--svgColor)" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M93.1598 109.162L60.2309 58.3069C59.8351 57.6956 58.9394 57.6989 58.5481 58.3132L25.3125 110.485" stroke="var(--svgColor)" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3.82812 44.0554L59.3809 57.0001" stroke="var(--svgColor)" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M30.5892 57.3088C34.4851 57.3088 37.6433 54.1251 37.6433 50.198C37.6433 46.2708 34.4851 43.0871 30.5892 43.0871C26.6934 43.0871 23.5352 46.2708 23.5352 50.198C23.5352 54.1251 26.6934 57.3088 30.5892 57.3088Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M59.1517 36.5212C63.0476 36.5212 66.2058 33.3376 66.2058 29.4104C66.2058 25.4832 63.0476 22.2996 59.1517 22.2996C55.2559 22.2996 52.0977 25.4832 52.0977 29.4104C52.0977 33.3376 55.2559 36.5212 59.1517 36.5212Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M98.7181 55.1838C102.614 55.1838 105.772 52.0002 105.772 48.073C105.772 44.1458 102.614 40.9622 98.7181 40.9622C94.8223 40.9622 91.6641 44.1458 91.6641 48.073C91.6641 52.0002 94.8223 55.1838 98.7181 55.1838Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M73.8744 86.7192C77.7702 86.7192 80.9284 83.5356 80.9284 79.6084C80.9284 75.6812 77.7702 72.4976 73.8744 72.4976C69.9785 72.4976 66.8203 75.6812 66.8203 79.6084C66.8203 83.5356 69.9785 86.7192 73.8744 86.7192Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M35.6908 100.169C39.5866 100.169 42.7448 96.9853 42.7448 93.0581C42.7448 89.1309 39.5866 85.9473 35.6908 85.9473C31.7949 85.9473 28.6367 89.1309 28.6367 93.0581C28.6367 96.9853 31.7949 100.169 35.6908 100.169Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
  </svg>
);

export default SpecialtyIcon;
