import React from "react";

const TypesOfScoreIcon = (props) => (
  <svg {...props} className={"icon"} width="112" height="96" viewBox="0 0 112 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6387_392)">
      <path d="M87.7363 82.7146L87.7363 12.7422C87.7363 11.0853 86.3932 9.74219 84.7363 9.74219L13.3265 9.74218C11.6696 9.74218 10.3265 11.0853 10.3265 12.7422L10.3265 82.7146C10.3265 84.3714 11.6696 85.7146 13.3265 85.7146L84.7363 85.7146C86.3932 85.7146 87.7363 84.3714 87.7363 82.7146Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M26.8018 29.3577C27.1012 28.4364 28.4046 28.4364 28.7039 29.3577L29.6629 32.3092C29.7968 32.7212 30.1807 33.0002 30.614 33.0002L33.7174 33.0002C34.6861 33.0002 35.0888 34.2398 34.3051 34.8092L31.7944 36.6333C31.444 36.888 31.2973 37.3393 31.4312 37.7514L32.3902 40.7028C32.6895 41.6242 31.635 42.3903 30.8513 41.8209L28.3406 39.9968C27.9902 39.7421 27.5156 39.7421 27.1651 39.9968L24.6544 41.8209C23.8707 42.3903 22.8162 41.6242 23.1155 40.7028L24.0745 37.7514C24.2084 37.3393 24.0618 36.888 23.7113 36.6333L21.2006 34.8092C20.4169 34.2398 20.8197 33.0002 21.7884 33.0002L24.8918 33.0002C25.325 33.0002 25.7089 32.7212 25.8428 32.3092L26.8018 29.3577Z" fill="#F5A506" />
      <path d="M37.4412 52.9373C37.7406 52.016 39.044 52.016 39.3433 52.9373L40.3023 55.8888C40.4362 56.3008 40.8201 56.5798 41.2534 56.5798L44.3568 56.5798C45.3255 56.5798 45.7283 57.8194 44.9445 58.3888L42.4339 60.2129C42.0834 60.4676 41.9367 60.9189 42.0706 61.3309L43.0296 64.2824C43.3289 65.2037 42.2745 65.9699 41.4907 65.4005L38.9801 63.5763C38.6296 63.3217 38.155 63.3217 37.8045 63.5763L35.2938 65.4005C34.5101 65.9699 33.4556 65.2037 33.755 64.2824L34.714 61.3309C34.8478 60.9189 34.7012 60.4676 34.3507 60.2129L31.84 58.3888C31.0563 57.8194 31.4591 56.5798 32.4278 56.5798L35.5312 56.5798C35.9644 56.5798 36.3483 56.3008 36.4822 55.8888L37.4412 52.9373Z" fill="#F5A506" />
      <path d="M48.0804 29.3587C48.3797 28.4374 49.6831 28.4374 49.9825 29.3587L50.9415 32.3102C51.0754 32.7222 51.4593 33.0012 51.8925 33.0012L54.9959 33.0012C55.9646 33.0012 56.3674 34.2408 55.5837 34.8102L53.073 36.6343C52.7225 36.8889 52.5759 37.3403 52.7097 37.7523L53.6687 40.7038C53.9681 41.6251 52.9136 42.3913 52.1299 41.8219L49.6192 39.9977C49.2687 39.7431 48.7941 39.7431 48.4436 39.9977L45.933 41.8219C45.1492 42.3913 44.0948 41.6251 44.3941 40.7038L45.3531 37.7523C45.487 37.3403 45.3403 36.8889 44.9898 36.6343L42.4792 34.8102C41.6954 34.2408 42.0982 33.0012 43.0669 33.0012L46.1703 33.0012C46.6035 33.0012 46.9875 32.7222 47.1214 32.3102L48.0804 29.3587Z" fill="#F5A506" />
      <path d="M58.7194 52.9378C59.0188 52.0165 60.3222 52.0165 60.6215 52.9378L61.5805 55.8893C61.7144 56.3013 62.0983 56.5803 62.5316 56.5803L65.635 56.5803C66.6037 56.5803 67.0065 57.8199 66.2227 58.3893L63.7121 60.2134C63.3616 60.468 63.2149 60.9194 63.3488 61.3314L64.3078 64.2829C64.6071 65.2042 63.5526 65.9704 62.7689 65.401L60.2582 63.5768C59.9078 63.3222 59.4332 63.3222 59.0827 63.5768L56.572 65.401C55.7883 65.9704 54.7338 65.2042 55.0332 64.2829L55.9921 61.3314C56.126 60.9194 55.9794 60.468 55.6289 60.2134L53.1182 58.3893C52.3345 57.8199 52.7373 56.5803 53.706 56.5803L56.8094 56.5803C57.2426 56.5803 57.6265 56.3013 57.7604 55.8893L58.7194 52.9378Z" fill="#F5A506" />
      <path d="M69.3588 29.3577C69.6582 28.4364 70.9616 28.4364 71.2609 29.3577L72.2199 32.3092C72.3538 32.7212 72.7378 33.0002 73.171 33.0002L76.2744 33.0002C77.2431 33.0002 77.6459 34.2398 76.8621 34.8092L74.3515 36.6333C74.001 36.888 73.8543 37.3393 73.9882 37.7514L74.9472 40.7028C75.2465 41.6242 74.1921 42.3903 73.4083 41.8209L70.8977 39.9968C70.5472 39.7421 70.0726 39.7421 69.7221 39.9968L67.2114 41.8209C66.4277 42.3903 65.3732 41.6242 65.6726 40.7028L66.6316 37.7514C66.7654 37.3393 66.6188 36.888 66.2683 36.6333L63.7576 34.8092C62.9739 34.2398 63.3767 33.0002 64.3454 33.0002L67.4488 33.0002C67.882 33.0002 68.2659 32.7212 68.3998 32.3092L69.3588 29.3577Z" fill="#F5A506" />
      <path d="M78.0053 64.0664L78.0563 56.1303L96.5349 31.6227L99.1206 28.1934C99.3762 27.8544 99.8582 27.7868 100.197 28.0424L108.012 33.9345C108.351 34.1901 108.418 34.6721 108.163 35.0111L105.577 38.4405L87.0985 62.948L79.4827 65.1803C78.7414 65.3976 78.0003 64.8388 78.0053 64.0664Z" fill="#FF4040" />
      <path d="M78.0563 56.1303L78.0053 64.0664C78.0003 64.8388 78.7414 65.3976 79.4827 65.1803L87.0985 62.948M78.0563 56.1303L87.0985 62.948M78.0563 56.1303L96.5349 31.6227M87.0985 62.948L105.577 38.4405M96.5349 31.6227L99.1206 28.1934C99.3762 27.8544 99.8582 27.7868 100.197 28.0424L108.012 33.9345C108.351 34.1901 108.418 34.6721 108.163 35.0111L105.577 38.4405M96.5349 31.6227L105.577 38.4405" stroke="var(--svgColor)" strokeWidth="2" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_6387_392">
        <rect x="111.391" y="0.228027" width="95" height="111" rx="2" transform="rotate(90 111.391 0.228027)" fill="var(--svgColor)" />
      </clipPath>
    </defs>
  </svg>
);

export default TypesOfScoreIcon;