import React from "react";
import Icon from "./icon";

function Personal(props) {
  return (
    <Icon viewBox="0 0 299.998 299.998" {...props}>
      <g>
        <g>
          <path
            d="M149.997,0C67.157,0,0.001,67.158,0.001,149.995s67.156,150.003,149.995,150.003s150-67.163,150-150.003
			S232.836,0,149.997,0z M40.732,206.496c0-46.858,41.152-46.848,50.284-59.1l1.045-5.587c-12.83-6.505-21.887-22.178-21.887-40.514
			c0-24.154,15.712-43.738,35.089-43.738c15.258,0,28.205,12.164,33.037,29.118c-19.41,6.168-33.768,27.214-33.768,52.232
			c0,17.224,6.79,33.06,17.937,43.121c-1.79,0.918-3.781,1.854-5.493,2.656c-8.989,4.217-21.449,10.079-30.438,21.812H40.732z
			 M150.453,244.105v-0.002h-0.916H85.466c0-46.856,41.152-46.843,50.284-59.095l1.045-5.587
			c-12.83-6.505-21.887-22.178-21.887-40.514c0-24.154,15.712-43.738,35.089-43.738c19.377,0,35.089,19.584,35.089,43.738
			c0,18.178-8.896,33.758-21.555,40.361l1.19,6.352c10.019,11.658,49.802,12.418,49.802,58.485H150.453z M213.452,206.498v-0.002h0
			c-8.992-11.731-21.452-17.592-30.441-21.809c-1.769-0.83-3.73-1.751-5.483-2.651c11.137-10.074,17.935-25.944,17.935-43.129
			c0-25.015-14.353-46.057-33.758-52.227c4.829-16.957,17.776-29.121,33.037-29.121c19.379,0,35.089,19.584,35.089,43.738
			c0,18.178-8.896,33.756-21.555,40.361l1.19,6.352c10.019,11.656,49.802,12.415,49.802,58.488H213.452z"
          />
        </g>
      </g>
    </Icon>
  );
}
export default Personal;
