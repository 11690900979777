import React from "react";

const PerformanceFormSelfEvaluationIcon = (props) => (
    <svg {...props} className={"icon"} width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M11.5076 6.01953H4.26172C3.91654 6.01953 3.63672 6.29935 3.63672 6.64453V13.8904C3.63672 14.2356 3.91654 14.5154 4.26172 14.5154H11.5076C11.8527 14.5154 12.1326 14.2356 12.1326 13.8904V6.64453C12.1326 6.29935 11.8527 6.01953 11.5076 6.01953ZM4.26172 5.39453C3.57136 5.39453 3.01172 5.95418 3.01172 6.64453V13.8904C3.01172 14.5807 3.57136 15.1404 4.26172 15.1404H11.5076C12.1979 15.1404 12.7576 14.5807 12.7576 13.8904V6.64453C12.7576 5.95418 12.1979 5.39453 11.5076 5.39453H4.26172Z" fill="var(--svgColor)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M11.5076 6.14453H4.26172C3.98558 6.14453 3.76172 6.36839 3.76172 6.64453V13.8904C3.76172 14.1665 3.98558 14.3904 4.26172 14.3904H11.5076C11.7837 14.3904 12.0076 14.1665 12.0076 13.8904V6.64453C12.0076 6.36839 11.7837 6.14453 11.5076 6.14453ZM2.88672 6.64453C2.88672 5.88514 3.50233 5.26953 4.26172 5.26953H11.5076C12.267 5.26953 12.8826 5.88514 12.8826 6.64453V13.8904C12.8826 14.6498 12.267 15.2654 11.5076 15.2654H4.26172C3.50233 15.2654 2.88672 14.6498 2.88672 13.8904V6.64453ZM4.26172 6.01953H11.5076C11.8527 6.01953 12.1326 6.29935 12.1326 6.64453V13.8904C12.1326 14.2356 11.8527 14.5154 11.5076 14.5154H4.26172C3.91654 14.5154 3.63672 14.2356 3.63672 13.8904V6.64453C3.63672 6.29935 3.91654 6.01953 4.26172 6.01953ZM3.01172 6.64453C3.01172 5.95418 3.57136 5.39453 4.26172 5.39453H11.5076C12.1979 5.39453 12.7576 5.95418 12.7576 6.64453V13.8904C12.7576 14.5807 12.1979 15.1404 11.5076 15.1404H4.26172C3.57136 15.1404 3.01172 14.5807 3.01172 13.8904V6.64453Z" fill="var(--svgColor)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M13.8047 7.95881C13.7665 7.79051 13.8719 7.62308 14.0402 7.58486L16.7829 6.96193C16.9512 6.92371 17.1186 7.02915 17.1568 7.19746C17.1951 7.36576 17.0896 7.53318 16.9213 7.57141L14.9144 8.02724L17.755 9.81637C19.0076 10.6053 18.5631 12.5316 17.0915 12.6918L14.6645 12.9561L14.5969 12.3347L17.0238 12.0705C17.9068 11.9743 18.1735 10.8186 17.422 10.3452L14.5813 8.55609L15.0371 10.563C15.0753 10.7313 14.9699 10.8988 14.8016 10.937C14.6333 10.9752 14.4659 10.8698 14.4276 10.7015L13.8047 7.95881Z" fill="var(--svgColor)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M17.0238 12.0705C17.9068 11.9744 18.1735 10.8186 17.422 10.3452L14.5813 8.55611L15.0371 10.5631C15.0754 10.7314 14.9699 10.8988 14.8016 10.937C14.6333 10.9752 14.4659 10.8698 14.4277 10.7015L13.8047 7.95884C13.7665 7.79054 13.872 7.62311 14.0403 7.58489L16.7829 6.96196C16.9512 6.92373 17.1186 7.02918 17.1569 7.19748C17.1951 7.36579 17.0896 7.53321 16.9213 7.57144L14.9144 8.02727L17.7551 9.81639C19.0076 10.6053 18.5631 12.5316 17.0915 12.6918L14.6646 12.9561L14.5969 12.3348L17.0238 12.0705ZM15.2364 8.08232L16.949 7.69333C17.1846 7.63981 17.3323 7.40542 17.2788 7.1698C17.2252 6.93417 16.9908 6.78655 16.7552 6.84006L14.0126 7.46299C13.7769 7.51651 13.6293 7.7509 13.6828 7.98652L14.3058 10.7292C14.3593 10.9648 14.5937 11.1124 14.8293 11.0589C15.0649 11.0054 15.2125 10.771 15.159 10.5354L14.77 8.82271L17.3554 10.451C18.0067 10.8612 17.7755 11.8629 17.0103 11.9462L14.4591 12.224L14.5538 13.0939L17.105 12.8161C18.6944 12.643 19.1745 10.5626 17.8217 9.71062L15.2364 8.08232Z" fill="var(--svgColor)" />
    </svg>
);

export default PerformanceFormSelfEvaluationIcon;
