import React from "react";

const HealthIcon = (props) => (
    <svg {...props} className={"icon"} width="111" height="95" viewBox="0 0 111 95" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3372_10185)">
            <path d="M108 90.1218L108 13.1211C108 12.0165 107.105 11.1211 106 11.1211L5.62184 11.1211C4.51727 11.1211 3.62184 12.0165 3.62184 13.1211L3.62184 90.1218C3.62184 91.2263 4.51726 92.1218 5.62184 92.1218L106 92.1218C107.105 92.1218 108 91.2264 108 90.1218Z" stroke="#E5E5E5" strokeWidth="3" strokeMiterlimit="10" />
            <path d="M89.7091 2.71429L89.7091 11.1236" stroke="#E5E5E5" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
            <path d="M66.967 2.71429L66.967 11.1236" stroke="#E5E5E5" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
            <path d="M44.2252 2.71429L44.2252 11.1236" stroke="#E5E5E5" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
            <path d="M21.4832 2.71429L21.4832 11.1236" stroke="#E5E5E5" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
            <rect x="59" y="68" width="14" height="14" rx="1" fill="#F5A506" stroke="#E5E5E5" strokeWidth="2" />
            <rect x="19" y="68" width="14" height="14" rx="1" fill="#F5A506" stroke="#E5E5E5" strokeWidth="2" />
            <path d="M22.2471 47.921C21.5306 48.3369 21.2868 49.2548 21.7027 49.9713C22.1185 50.6878 23.0364 50.9316 23.7529 50.5157L22.2471 47.921ZM47.4888 36.2324C47.7014 35.4318 47.2247 34.6103 46.424 34.3977L33.3761 30.9331C32.5755 30.7205 31.754 31.1972 31.5414 31.9979C31.3288 32.7985 31.8055 33.62 32.6062 33.8326L44.2043 36.9123L41.1246 48.5104C40.912 49.311 41.3887 50.1325 42.1894 50.3451C42.9901 50.5577 43.8115 50.081 44.0241 49.2803L47.4888 36.2324ZM23.7529 50.5157L46.7919 37.1448L45.2861 34.5501L22.2471 47.921L23.7529 50.5157Z" fill="#E5E5E5" />
            <rect x="79" y="68" width="14" height="14" rx="1" fill="#F5A506" stroke="#E5E5E5" strokeWidth="2" />
            <rect x="39" y="68" width="14" height="14" rx="1" fill="#F5A506" stroke="#E5E5E5" strokeWidth="2" />
            <rect x="59" y="28" width="14" height="14" rx="1" fill="#F5A506" stroke="#E5E5E5" strokeWidth="2" />
            <rect x="59" y="48" width="14" height="14" rx="1" fill="#FF4040" stroke="#E5E5E5" strokeWidth="2" />
            <rect x="79" y="28" width="14" height="14" rx="1" fill="#F5A506" stroke="#E5E5E5" strokeWidth="2" />
            <rect x="79" y="48" width="14" height="14" rx="1" fill="#FF4040" stroke="#E5E5E5" strokeWidth="2" />
        </g>
        <defs>
            <clipPath id="clip0_3372_10185">
                <rect x="111" width="95" height="111" rx="2" transform="rotate(90 111 0)" fill="white" />
            </clipPath>
        </defs>
    </svg>

);

export default HealthIcon;
