import React from "react";

const GeographicalStructure = (props) => (
    <svg {...props} className={"icon"} width="97" height="97" viewBox="0 0 97 97" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="48.5" cy="48.5" r="47" stroke="var(--svgColor)" strokeWidth="3" />
        <path d="M3.50001 35C0.999971 47 5.79125 65.1577 20 65.5C37.7609 65.9279 44.0369 62.1937 46.5742 69.8964C49.7641 79.5802 28.3536 86.0856 44.0001 95.5M44.5001 1.5C42.1038 5.06607 22.301 13.3243 30.0819 23.2523C39.8082 35.6622 23.7388 38.4414 30.0819 47C36.4251 55.5586 44.0369 44.6486 52.0716 48.5C60.1063 52.3514 53.3403 61.3378 63.0665 62.1937C72.7927 63.0496 69.8325 50.2117 82.096 52.3514C91.9068 54.0631 90.0001 65 91.5001 67" stroke="var(--svgColor)" strokeWidth="3" />
        <ellipse cx="62.5" cy="40" rx="8.5" ry="4" fill="#F5A506" />
        <path d="M71.4947 27.2109L71.4947 27.2119C71.4965 28.3639 71.261 29.505 70.8016 30.5685C70.3424 31.6316 69.6684 32.5962 68.8183 33.4055C68.818 33.4058 68.8176 33.4062 68.8173 33.4065L62.6574 39.2006C62.5929 39.2613 62.4923 39.2616 62.4274 39.2013L56.1849 33.4045C55.13 32.3982 54.3492 31.1563 53.9103 29.7898C53.4712 28.4223 53.3876 26.9713 53.6669 25.5654C53.9462 24.1594 54.5799 22.8407 55.5133 21.727C56.4466 20.6132 57.6509 19.7389 59.0197 19.1831C60.3886 18.6274 61.8788 18.4079 63.3572 18.5449C64.8357 18.6818 66.2553 19.1708 67.4898 19.9672C68.7242 20.7635 69.7347 21.842 70.4328 23.1057C71.1308 24.3693 71.4953 25.7795 71.4947 27.2109ZM60.6227 29.1309C61.1796 29.4919 61.8327 29.6838 62.4998 29.6841C62.943 29.6843 63.3822 29.5999 63.7925 29.4354C64.2029 29.2708 64.5768 29.029 64.8925 28.7232C65.2082 28.4174 65.4595 28.0534 65.6313 27.6516C65.803 27.2498 65.8917 26.8185 65.8917 26.3826C65.8917 25.7261 65.6908 25.0857 65.3163 24.5425C64.942 23.9994 64.4116 23.5785 63.7941 23.3305C63.1766 23.0825 62.4981 23.0178 61.8435 23.1439C61.1889 23.2699 60.5855 23.5817 60.1103 24.042C59.635 24.5025 59.3093 25.0912 59.1771 25.7345C59.0448 26.378 59.1125 27.0449 59.3709 27.6501C59.6292 28.2552 60.0657 28.7699 60.6227 29.1309Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="1.01053" />
    </svg>
);

export default GeographicalStructure;


