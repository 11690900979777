import React from "react";
import Icon from "./icon";

function OrgChartIcon(props) {
  return (
    <Icon viewBox="0 0 32 32" {...props}>
      <path d="m16 1a15 15 0 1 0 15 15 15.00543 15.00543 0 0 0 -15-15zm0 6a2.4 2.4 0 1 1 -2.4 2.4 2.39426 2.39426 0 0 1 2.4-2.4zm-7.2 8.4h6.6v-2.75a3.62119 3.62119 0 0 0 1.2 0v2.75h6.6v3.95a3.62578 3.62578 0 0 0 -1.2 0v-2.75h-5.4v2.75a3.62548 3.62548 0 0 0 -1.2 0v-2.75h-5.4v2.75a3.62578 3.62578 0 0 0 -1.2 0zm.6 9.6a2.4 2.4 0 1 1 2.4-2.4 2.39426 2.39426 0 0 1 -2.4 2.4zm6.6 0a2.4 2.4 0 1 1 2.4-2.4 2.39426 2.39426 0 0 1 -2.4 2.4zm6.6 0a2.4 2.4 0 1 1 2.4-2.4 2.39425 2.39425 0 0 1 -2.4 2.4z" />
    </Icon>
  );
}
export default OrgChartIcon;
