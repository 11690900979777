import React from "react";

const KpiComparisonIcon = (props) => (
    <svg {...props} className={"icon"} width="111" height="82" viewBox="0 0 111 82" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.0302 74.8252H97.5284" stroke="var(--svgColor)" strokeWidth="3.20388" strokeLinecap="round" />
        <path d="M14.0302 74.8252L14.0301 23.5879" stroke="var(--svgColor)" strokeWidth="3.20388" strokeLinecap="round" />
        <rect x="31.35" y="29.853" width="16.3762" height="43.4189" fill="#FF4040" />
        <rect x="57.5919" y="47.2803" width="16.3762" height="25.9917" fill="#F5A506" />
    </svg>
);

export default KpiComparisonIcon;
