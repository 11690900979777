import React from "react";

const GroupIcon = (props) => (
  <svg {...props} className={"icon"} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.36585 18.4878H0V17.8049C0.00301078 15.1659 2.14155 13.0274 4.78049 13.0244H7.5122C10.1511 13.0274 12.2897 15.1659 12.2927 17.8049V18.4878H10.9268V17.8049C10.9246 15.92 9.39711 14.3925 7.5122 14.3903H4.78049C2.89557 14.3925 1.36811 15.92 1.36585 17.8049V18.4878ZM6.14634 12.3415C4.26049 12.3415 2.73171 10.8127 2.73171 8.92684C2.73171 7.04099 4.26049 5.51221 6.14634 5.51221C8.03219 5.51221 9.56098 7.04099 9.56098 8.92684C9.55872 10.8118 8.03126 12.3392 6.14634 12.3415ZM6.14634 6.87806C5.01483 6.87806 4.09756 7.79533 4.09756 8.92684C4.09756 10.0584 5.01483 10.9756 6.14634 10.9756C7.27785 10.9756 8.19512 10.0584 8.19512 8.92684C8.19512 7.79533 7.27785 6.87806 6.14634 6.87806Z" fill={props.color} />
    <path d="M13.0731 18.4878H11.7073V17.8049C11.7103 15.1659 13.8488 13.0274 16.4878 13.0244H19.2195C21.8584 13.0274 23.9969 15.1659 24 17.8049V18.4878H22.6341V17.8049C22.6318 15.92 21.1044 14.3925 19.2195 14.3903H16.4878C14.6028 14.3925 13.0754 15.92 13.0731 17.8049V18.4878ZM17.8536 12.3415C15.9678 12.3415 14.439 10.8127 14.439 8.92684C14.439 7.04099 15.9678 5.51221 17.8536 5.51221C19.7395 5.51221 21.2683 7.04099 21.2683 8.92684C21.266 10.8118 19.7385 12.3392 17.8536 12.3415ZM17.8536 6.87806C16.7221 6.87806 15.8048 7.79533 15.8048 8.92684C15.8048 10.0584 16.7221 10.9756 17.8536 10.9756C18.9851 10.9756 19.9024 10.0584 19.9024 8.92684C19.9024 7.79533 18.9851 6.87806 17.8536 6.87806Z" fill={props.color} />
  </svg>
);

export default GroupIcon;
