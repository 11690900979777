import React from "react";

const OrganizationalBenefitsIcon = (props) => (
    <svg {...props} className={"icon"} width="100" height="99" viewBox="0 0 100 99" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.8211 66.2201H19.0314C26.3868 59.3318 43.9516 49.4232 55.3678 64.8954H65.542V64.8954C68.2421 64.8954 70.4309 67.0842 70.4309 69.7843V70.1278C70.4309 73.0177 68.0882 75.3603 65.1984 75.3603H39.9083" stroke="var(--svgColor)" strokeWidth="2.41359" strokeLinecap="round" />
        <path d="M13.0854 93.1112H67.9203C70.8272 93.1112 77.6188 91.919 81.5299 87.1502C84.7438 83.2316 91.8655 73.32 96.7219 66.4561C98.3352 64.1759 98.0627 61.0705 96.0901 59.0929V59.0929C93.5829 56.5793 89.4452 56.9281 87.2413 59.7113C81.6628 66.7564 73.4724 75.3227 65.3557 75.3227" stroke="var(--svgColor)" strokeWidth="2.41359" strokeLinecap="round" />
        <mask id="path-3-inside-1_5763_19163" fill="white">
            <rect x="0.499985" y="61.1348" width="12.9235" height="36.9261" rx="1.60906" />
        </mask>
        <rect x="0.499985" y="61.1348" width="12.9235" height="36.9261" rx="1.60906" stroke="var(--svgColor)" strokeWidth="4.82718" mask="url(#path-3-inside-1_5763_19163)" />
        <g clipPath="url(#clip0_5763_19163)">
            <path d="M35.4378 49.1055L90.286 49.1055" stroke="var(--svgColor)" strokeWidth="1.84729" strokeMiterlimit="10" strokeLinecap="round" />
            <path d="M49.0217 22.5781H41.114C40.4338 22.5781 39.8824 23.1295 39.8824 23.8097V47.8369C39.8824 48.517 40.4338 49.0684 41.114 49.0684H49.0217C49.7018 49.0684 50.2532 48.517 50.2532 47.8369V23.8097C50.2532 23.1295 49.7018 22.5781 49.0217 22.5781Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="1.84729" strokeMiterlimit="10" />
            <path d="M67.1706 22.5781H59.2629C58.5827 22.5781 58.0313 23.1295 58.0313 23.8097V47.8369C58.0313 48.517 58.5827 49.0684 59.2629 49.0684H67.1706C67.8507 49.0684 68.4021 48.517 68.4021 47.8369V23.8097C68.4021 23.1295 67.8507 22.5781 67.1706 22.5781Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="1.84729" strokeMiterlimit="10" />
            <path d="M84.6712 22.5781H76.7635C76.0834 22.5781 75.532 23.1295 75.532 23.8097V47.8369C75.532 48.517 76.0834 49.0684 76.7635 49.0684H84.6712C85.3514 49.0684 85.9028 48.517 85.9028 47.8369V23.8097C85.9028 23.1295 85.3514 22.5781 84.6712 22.5781Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="1.84729" strokeMiterlimit="10" />
            <path d="M62.7161 1.33342C62.8221 1.25924 62.9631 1.25924 63.0691 1.33342L63.5987 0.57667L63.0691 1.33342L86.9475 18.0439C87.1941 18.2165 87.072 18.6041 86.771 18.6041H39.0142C38.7132 18.6041 38.5911 18.2166 38.8377 18.0439L62.7161 1.33342Z" stroke="var(--svgColor)" strokeWidth="1.84729" />
        </g>
        <defs>
            <clipPath id="clip0_5763_19163">
                <rect width="73.276" height="51.7242" fill="white" transform="translate(26.2239 0.244629)" />
            </clipPath>
        </defs>
    </svg>
);

export default OrganizationalBenefitsIcon;
