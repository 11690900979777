import React from "react";

const WorkAndTasksIcon = (props) => (
    <svg {...props} className={"icon"} width="104" height="97" viewBox="0 0 104 97" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M27.1227 69.1975H4.47852C3.37395 69.1975 2.47852 70.0929 2.47852 71.1975V92.5287C2.47852 93.6333 3.37395 94.5287 4.47852 94.5287H27.1227C28.2273 94.5287 29.1227 93.6333 29.1227 92.5287V71.1975C29.1227 70.0929 28.2273 69.1975 27.1227 69.1975Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M15.7635 42.4637C17.6353 42.4637 19.4304 43.2052 20.754 44.5249C22.0775 45.8446 22.8211 47.6345 22.8211 49.5009V68.9565H8.70587V49.5009C8.70587 47.6345 9.44944 45.8446 10.773 44.5249C12.0966 43.2052 13.8917 42.4637 15.7635 42.4637V42.4637Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M10.7692 54.1099C11.4245 54.7636 12.2026 55.2821 13.059 55.6359C13.9154 55.9896 14.8333 56.1717 15.7603 56.1717C16.6873 56.1717 17.6052 55.9896 18.4615 55.6359C19.3179 55.2821 20.096 54.7636 20.7514 54.1099L64.4413 9.85167C64.8108 9.47737 65.0179 8.97258 65.0179 8.44663V5.99033C65.0179 4.32526 63.1022 3.3893 61.7887 4.41264L10.7692 44.1628C10.1138 44.8157 9.59389 45.5911 9.23918 46.4445C8.88448 47.2979 8.7019 48.2126 8.7019 49.1364C8.7019 50.0601 8.88448 50.9749 9.23918 51.8282C9.59389 52.6816 10.1138 53.457 10.7692 54.1099Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M65.0181 3.90295C65.0181 2.79838 65.9135 1.90295 67.0181 1.90295H76.1033C77.0893 1.90295 78.0349 2.29351 78.7322 2.98871C79.4294 3.68391 79.8211 4.6268 79.8211 5.60996V5.56053C79.8211 6.54369 79.4294 7.48658 78.7322 8.18178C78.0349 8.87698 77.0893 9.26754 76.1033 9.26754H67.0181C65.9135 9.26754 65.0181 8.37211 65.0181 7.26754V3.90295Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M74.7436 9.26746H74V31.7937H74.7436V9.26746Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M98.3699 31.7937H52C50.8954 31.7937 50 32.6891 50 33.7937V58.2697C50 59.3743 50.8954 60.2697 52 60.2697H98.3699C99.4745 60.2697 100.37 59.3743 100.37 58.2697V33.7937C100.37 32.6891 99.4745 31.7937 98.3699 31.7937Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M61.3206 53.6773V38.3863C61.3206 37.7583 60.8115 37.2493 60.1835 37.2493C59.5556 37.2493 59.0465 37.7583 59.0465 38.3863V53.6773C59.0465 54.3052 59.5556 54.8143 60.1835 54.8143C60.8115 54.8143 61.3206 54.3052 61.3206 53.6773Z" stroke="var(--svgColor)" strokeMiterlimit="10" />
        <path d="M68.8245 53.6773V38.3863C68.8245 37.7583 68.3154 37.2493 67.6874 37.2493C67.0595 37.2493 66.5504 37.7583 66.5504 38.3863V53.6773C66.5504 54.3052 67.0595 54.8143 67.6874 54.8143C68.3154 54.8143 68.8245 54.3052 68.8245 53.6773Z" stroke="var(--svgColor)" strokeMiterlimit="10" />
        <path d="M76.3219 53.6773V38.3863C76.3219 37.7583 75.8128 37.2493 75.1849 37.2493C74.5569 37.2493 74.0479 37.7583 74.0479 38.3863V53.6773C74.0479 54.3052 74.5569 54.8143 75.1849 54.8143C75.8128 54.8143 76.3219 54.3052 76.3219 53.6773Z" stroke="var(--svgColor)" strokeMiterlimit="10" />
        <path d="M83.8257 53.6773V38.3863C83.8257 37.7583 83.3166 37.2493 82.6887 37.2493C82.0607 37.2493 81.5516 37.7583 81.5516 38.3863V53.6773C81.5516 54.3052 82.0607 54.8143 82.6887 54.8143C83.3166 54.8143 83.8257 54.3052 83.8257 53.6773Z" stroke="var(--svgColor)" strokeMiterlimit="10" />
        <path d="M91.3232 53.6773V38.3863C91.3232 37.7583 90.8142 37.2493 90.1862 37.2493C89.5583 37.2493 89.0492 37.7583 89.0492 38.3863V53.6773C89.0492 54.3052 89.5583 54.8143 90.1862 54.8143C90.8142 54.8143 91.3232 54.3052 91.3232 53.6773Z" stroke="var(--svgColor)" strokeMiterlimit="10" />
        <path d="M98.3699 65.7932H52C50.8954 65.7932 50 66.6886 50 67.7932V92.2692C50 93.3738 50.8954 94.2692 52 94.2692H98.3699C99.4745 94.2692 100.37 93.3738 100.37 92.2692V67.7932C100.37 66.6886 99.4745 65.7932 98.3699 65.7932Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M61.3206 87.6766V72.3857C61.3206 71.7577 60.8115 71.2487 60.1835 71.2487C59.5556 71.2487 59.0465 71.7577 59.0465 72.3857V87.6766C59.0465 88.3046 59.5556 88.8137 60.1835 88.8137C60.8115 88.8137 61.3206 88.3046 61.3206 87.6766Z" stroke="var(--svgColor)" strokeMiterlimit="10" />
        <path d="M68.8245 87.6767V72.3857C68.8245 71.7577 68.3154 71.2487 67.6874 71.2487C67.0595 71.2487 66.5504 71.7577 66.5504 72.3857V87.6767C66.5504 88.3046 67.0595 88.8137 67.6874 88.8137C68.3154 88.8137 68.8245 88.3046 68.8245 87.6767Z" stroke="var(--svgColor)" strokeMiterlimit="10" />
        <path d="M76.3219 87.6767V72.3857C76.3219 71.7577 75.8128 71.2487 75.1849 71.2487C74.5569 71.2487 74.0479 71.7577 74.0479 72.3857V87.6767C74.0479 88.3046 74.5569 88.8137 75.1849 88.8137C75.8128 88.8137 76.3219 88.3046 76.3219 87.6767Z" stroke="var(--svgColor)" strokeMiterlimit="10" />
        <path d="M83.8257 87.6767V72.3857C83.8257 71.7577 83.3166 71.2487 82.6887 71.2487C82.0607 71.2487 81.5516 71.7577 81.5516 72.3857V87.6767C81.5516 88.3046 82.0607 88.8137 82.6887 88.8137C83.3166 88.8137 83.8257 88.3046 83.8257 87.6767Z" stroke="var(--svgColor)" strokeMiterlimit="10" />
        <path d="M91.3232 87.6767V72.3857C91.3232 71.7577 90.8142 71.2487 90.1862 71.2487C89.5583 71.2487 89.0492 71.7577 89.0492 72.3857V87.6767C89.0492 88.3046 89.5583 88.8137 90.1862 88.8137C90.8142 88.8137 91.3232 88.3046 91.3232 87.6767Z" stroke="var(--svgColor)" strokeMiterlimit="10" />
    </svg>

);

export default WorkAndTasksIcon;
