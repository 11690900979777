import React from "react";

const MassEntryIcon = (props) => (

  <svg {...props} className={"icon"} width="111" height="95" viewBox="0 0 111 95" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4274_11818)">
      <path d="M4.85352 62.3586H108" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M4.85352 32.5954H108" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M30.6403 92.1218L30.6403 2.83229" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <rect x="35.5812" y="75.9185" width="15.9044" height="8.56012" rx="1" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="35.5812" y="47" width="15.9044" height="8.56012" rx="1" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="35.5812" y="17" width="15.9044" height="8.56012" rx="1" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="61.3678" y="75.9185" width="15.9044" height="8.56012" rx="1" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="61.3678" y="47" width="15.9044" height="8.56012" rx="1" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="61.3678" y="17" width="15.9044" height="8.56012" rx="1" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="87.1545" y="75.9185" width="15.9044" height="8.56012" rx="1" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="87.1545" y="47" width="15.9044" height="8.56012" rx="1" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="87.1545" y="17" width="15.9044" height="8.56012" rx="1" stroke="var(--svgColor)" strokeWidth="2" />
      <path d="M56.4268 92.1218L56.4268 2.83229" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M82.2134 92.1218L82.2134 2.83229" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M108.354 90.1217L108.354 4.83229C108.354 3.72772 107.458 2.83229 106.354 2.83229L5.97536 2.83229C4.87078 2.83229 3.97536 3.72771 3.97536 4.83228L3.97535 90.1217C3.97535 91.2263 4.87078 92.1217 5.97535 92.1217L106.354 92.1217C107.458 92.1217 108.354 91.2263 108.354 90.1217Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M12 18.9136L14.9743 22.4818L24.2677 15" stroke="#F5A506" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M12 47.9136L14.9743 51.4818L24.2677 44" stroke="#F5A506" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M12 78.9136L14.9743 82.4818L24.2677 75" stroke="#F5A506" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_4274_11818">
        <rect x="111" width="95" height="111" rx="2" transform="rotate(90 111 0)" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default MassEntryIcon;
