import React from "react";
import Icon from "./icon";

function FunctionsIcon(props) {
	return (
    <Icon viewBox="0 0 24 24" {...props}>
		<path d="M18 4H6v2l6.5 6L6 18v2h12v-3h-7l5-5-5-5h7z"/>
    </Icon>
	);
}
export default FunctionsIcon;
