import React from "react";

const SentimentGood = (props) => (
    <svg {...props} className={"icon"} width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M43.01 83.52C65.3831 83.52 83.52 65.3831 83.52 43.01C83.52 20.6369 65.3831 2.5 43.01 2.5C20.6369 2.5 2.5 20.6369 2.5 43.01C2.5 65.3831 20.6369 83.52 43.01 83.52Z" fill="#57b757" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
        <path d="M25.7 36.71C28.7375 36.71 31.2 34.2475 31.2 31.21C31.2 28.1724 28.7375 25.71 25.7 25.71C22.6624 25.71 20.2 28.1724 20.2 31.21C20.2 34.2475 22.6624 36.71 25.7 36.71Z" fill="var(--svgColor)"/>
        <path d="M61.01 36.71C64.0476 36.71 66.51 34.2475 66.51 31.21C66.51 28.1724 64.0476 25.71 61.01 25.71C57.9724 25.71 55.51 28.1724 55.51 31.21C55.51 34.2475 57.9724 36.71 61.01 36.71Z" fill="var(--svgColor)"/>
        <path d="M63.19 46.45C63.19 56.61 53.34 65.8499 43.19 65.8499C33.04 65.8499 22.8 56.61 22.8 46.45" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    </svg>
);

export default SentimentGood;
