import React from "react";

const PublicationIconDownloads = (props) => (
  <svg {...props} className={"icon"} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28.571 10H34.7039L28.571 3.86719V10Z" fill="var(--icons)"/>
    <path
      d="M4.28601 37.1429H7.14315V2.14286C7.14315 1.7484 7.46298 1.42857 7.85744 1.42857H27.8574C27.8731 1.42857 27.8874 1.43659
      27.9031 1.43799C27.9656 1.44148 28.0269 1.45368 28.0859 1.47426C28.1009 1.47914 28.1183 1.47426 28.1323 1.48438C28.1466 1.49414
      28.149 1.49588 28.1574 1.49937C28.2324 1.53285 28.3011 1.57889 28.3604 1.63574L31.4289 4.70424V0H4.28601V37.1429Z"
      fill="var(--icons)"
    />
    <path
      d="M35.7139 11.4286H27.8568C27.4623 11.4286 27.1425 11.1088 27.1425 10.7143V2.85718H8.57104V40H35.7139V11.4286ZM12.1425
      7.85718H23.571C23.9655 7.85718 24.2853 8.177 24.2853 8.57146C24.2853 8.96593 23.9655 9.28575 23.571 9.28575H12.1425C11.748
      9.28575 11.4282 8.96593 11.4282 8.57146C11.4282 8.177 11.748 7.85718 12.1425 7.85718ZM32.1425 35H12.1425C11.748 35 11.4282
      34.6802 11.4282 34.2857C11.4282 33.8913 11.748 33.5715 12.1425 33.5715H32.1425C32.5369 33.5715 32.8568 33.8913 32.8568
      34.2857C32.8568 34.6802 32.5369 35 32.1425 35ZM32.1425 28.5715H12.1425C11.748 28.5715 11.4282 28.2516 11.4282 27.8572C11.4282
      27.4627 11.748 27.1429 12.1425 27.1429H32.1425C32.5369 27.1429 32.8568 27.4627 32.8568 27.8572C32.8568 28.2516 32.5369 28.5715
      32.1425 28.5715ZM32.1425 22.1429H12.1425C11.748 22.1429 11.4282 21.8231 11.4282 21.4286C11.4282 21.0341 11.748 20.7143 12.1425
      20.7143H32.1425C32.5369 20.7143 32.8568 21.0341 32.8568 21.4286C32.8568 21.8231 32.5369 22.1429 32.1425 22.1429ZM32.1425
      15.7143H12.1425C11.748 15.7143 11.4282 15.3945 11.4282 15C11.4282 14.6056 11.748 14.2857 12.1425 14.2857H32.1425C32.5369
      14.2857 32.8568 14.6056 32.8568 15C32.8568 15.3945 32.5369 15.7143 32.1425 15.7143Z"
      fill="var(--icons)"
    />
  </svg>
);
export default PublicationIconDownloads;
