import React from "react";

const PerformanceFormDownwardIcon = (props) => (
    <svg {...props} className={"icon"} width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M14.7338 3.35547H6.51953C6.17435 3.35547 5.89453 3.63529 5.89453 3.98047V6.79236C5.89453 7.13754 6.17435 7.41736 6.51953 7.41736H14.7338C15.079 7.41736 15.3588 7.13754 15.3588 6.79236V3.98047C15.3588 3.63529 15.079 3.35547 14.7338 3.35547ZM6.51953 2.73047C5.82918 2.73047 5.26953 3.29011 5.26953 3.98047V6.79236C5.26953 7.48271 5.82918 8.04236 6.51953 8.04236H14.7338C15.4242 8.04236 15.9838 7.48271 15.9838 6.79236V3.98047C15.9838 3.29011 15.4242 2.73047 14.7338 2.73047H6.51953Z" fill="var(--svgColor)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M14.7338 3.48047H6.51953C6.24339 3.48047 6.01953 3.70433 6.01953 3.98047V6.79236C6.01953 7.0685 6.24339 7.29236 6.51953 7.29236H14.7338C15.0099 7.29236 15.2338 7.0685 15.2338 6.79236V3.98047C15.2338 3.70433 15.0099 3.48047 14.7338 3.48047ZM5.14453 3.98047C5.14453 3.22108 5.76014 2.60547 6.51953 2.60547H14.7338C15.4932 2.60547 16.1088 3.22108 16.1088 3.98047V6.79236C16.1088 7.55175 15.4932 8.16736 14.7338 8.16736H6.51953C5.76014 8.16736 5.14453 7.55175 5.14453 6.79236V3.98047ZM6.51953 3.35547H14.7338C15.079 3.35547 15.3588 3.63529 15.3588 3.98047V6.79236C15.3588 7.13754 15.079 7.41736 14.7338 7.41736H6.51953C6.17435 7.41736 5.89453 7.13754 5.89453 6.79236V3.98047C5.89453 3.63529 6.17435 3.35547 6.51953 3.35547ZM5.26953 3.98047C5.26953 3.29011 5.82918 2.73047 6.51953 2.73047H14.7338C15.4242 2.73047 15.9838 3.29011 15.9838 3.98047V6.79236C15.9838 7.48271 15.4242 8.04236 14.7338 8.04236H6.51953C5.82918 8.04236 5.26953 7.48271 5.26953 6.79236V3.98047Z" fill="var(--svgColor)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M14.7338 14.1133H6.51953C6.17435 14.1133 5.89453 14.3931 5.89453 14.7383V17.5502C5.89453 17.8953 6.17435 18.1752 6.51953 18.1752H14.7338C15.079 18.1752 15.3588 17.8953 15.3588 17.5502V14.7383C15.3588 14.3931 15.079 14.1133 14.7338 14.1133ZM6.51953 13.4883C5.82918 13.4883 5.26953 14.0479 5.26953 14.7383V17.5502C5.26953 18.2405 5.82918 18.8002 6.51953 18.8002H14.7338C15.4242 18.8002 15.9838 18.2405 15.9838 17.5502V14.7383C15.9838 14.0479 15.4242 13.4883 14.7338 13.4883H6.51953Z" fill="var(--svgColor)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M14.7338 14.2383H6.51953C6.24339 14.2383 6.01953 14.4621 6.01953 14.7383V17.5502C6.01953 17.8263 6.24339 18.0502 6.51953 18.0502H14.7338C15.0099 18.0502 15.2338 17.8263 15.2338 17.5502V14.7383C15.2338 14.4621 15.0099 14.2383 14.7338 14.2383ZM5.14453 14.7383C5.14453 13.9789 5.76014 13.3633 6.51953 13.3633H14.7338C15.4932 13.3633 16.1088 13.9789 16.1088 14.7383V17.5502C16.1088 18.3096 15.4932 18.9252 14.7338 18.9252H6.51953C5.76014 18.9252 5.14453 18.3096 5.14453 17.5502V14.7383ZM6.51953 14.1133H14.7338C15.079 14.1133 15.3588 14.3931 15.3588 14.7383V17.5502C15.3588 17.8953 15.079 18.1752 14.7338 18.1752H6.51953C6.17435 18.1752 5.89453 17.8953 5.89453 17.5502V14.7383C5.89453 14.3931 6.17435 14.1133 6.51953 14.1133ZM5.26953 14.7383C5.26953 14.0479 5.82918 13.4883 6.51953 13.4883H14.7338C15.4242 13.4883 15.9838 14.0479 15.9838 14.7383V17.5502C15.9838 18.2405 15.4242 18.8002 14.7338 18.8002H6.51953C5.82918 18.8002 5.26953 18.2405 5.26953 17.5502V14.7383Z" fill="var(--svgColor)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M10.313 11.7571L8.85769 10.3019C8.73565 10.1798 8.53779 10.1798 8.41575 10.3019C8.29371 10.4239 8.29371 10.6218 8.41575 10.7438L10.4045 12.7326C10.5265 12.8546 10.7244 12.8546 10.8464 12.7326L12.8352 10.7438C12.9572 10.6218 12.9572 10.4239 12.8352 10.3019C12.7131 10.1798 12.5153 10.1798 12.3932 10.3019L10.938 11.7571L10.938 8.04297L10.313 8.04297L10.313 11.7571Z" fill="var(--svgColor)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M10.313 8.04297L10.938 8.04297L10.938 11.7571L12.3932 10.3019C12.5153 10.1798 12.7131 10.1798 12.8352 10.3019C12.9572 10.4239 12.9572 10.6218 12.8352 10.7438L10.8464 12.7326C10.7244 12.8546 10.5265 12.8546 10.4045 12.7326L8.41575 10.7438C8.29371 10.6218 8.29371 10.4239 8.41575 10.3019C8.53779 10.1798 8.73565 10.1798 8.85769 10.3019L10.313 11.7571L10.313 8.04297ZM10.188 11.4554L8.94608 10.2135C8.77522 10.0426 8.49821 10.0426 8.32736 10.2135C8.15651 10.3843 8.15651 10.6613 8.32736 10.8322L10.3161 12.8209C10.487 12.9918 10.764 12.9918 10.9348 12.8209L12.9236 10.8322C13.0944 10.6613 13.0944 10.3843 12.9236 10.2135C12.7527 10.0426 12.4757 10.0426 12.3048 10.2135L11.063 11.4554L11.063 7.91797L10.188 7.91797L10.188 11.4554Z" fill="var(--svgColor)" />
    </svg>
);

export default PerformanceFormDownwardIcon;
