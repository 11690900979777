import React from "react";
import Icon from "./icon";

function PerformanceManagementIcon(props) {
  return (
    <Icon viewBox="0 0 299.998 299.998" {...props}>
      <g>
        <g>
          <path d="M150,0C67.162,0,0,67.159,0,150s67.162,150,150,150c82.843,0,150-67.162,150-150C300,67.157,232.843,0,150,0z
			 M113.845,190.819H96.413v20.059c0,8.785-7.12,15.902-15.899,15.902c-8.782,0-15.899-7.117-15.899-15.902v-20.059H47.183V177.08
			h17.432V93.368c0-8.782,7.117-15.899,15.899-15.899c8.779,0,15.899,7.117,15.899,15.899v83.715h17.432V190.819z M183.331,119.177
			h-17.432v91.703c0,8.785-7.12,15.902-15.899,15.902c-8.782,0-15.899-7.117-15.899-15.902v-91.703h-17.432v-13.736h17.432V93.368
			c0-8.782,7.117-15.899,15.899-15.899c8.779,0,15.899,7.117,15.899,15.899v12.073h17.432V119.177z M252.817,190.819h-17.432v20.059
			c0,8.785-7.12,15.902-15.899,15.902c-8.782,0-15.899-7.117-15.899-15.902v-20.059h-17.432V177.08h17.432V93.368
			c0-8.782,7.117-15.899,15.899-15.899c8.779,0,15.899,7.117,15.899,15.899v83.715h17.432V190.819z"/>
        </g>
      </g>
    </Icon>
  );
}
export default PerformanceManagementIcon;
