import React from "react";

const LanguagesIcon = (props) => (
  <svg {...props} className={"icon"} width="136" height="108" viewBox="0 0 136 108" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4195_11887)">
      <path d="M96.9092 2.87403L96.9083 2.87403C91.3863 2.87113 85.9368 4.15977 80.9818 6.64121C76.0265 9.12271 71.6977 12.7309 68.332 17.1883L67.1349 16.2844L68.332 17.1883C64.9661 21.6458 62.654 26.8325 61.5764 32.347C60.4987 37.8614 60.6848 43.5539 62.12 48.9833C63.5552 54.4126 66.2006 59.4315 69.8496 63.6517C73.4985 67.8718 78.0527 71.1799 83.1584 73.3217C88.2641 75.4635 93.7854 76.382 99.295 76.0069C104.805 75.6318 110.157 73.973 114.937 71.1577L115.962 70.5536L116.783 71.415C120.394 75.2019 124.963 75.9632 128.775 75.7304C129.939 75.6594 131.018 75.4956 131.956 75.3022C131.035 74.751 130.033 74.0761 129.073 73.2825C126.38 71.0549 123.605 67.5122 124.763 62.8958L124.841 62.5856L125.039 62.3341C129.272 56.948 131.924 50.4544 132.687 43.6003C133.451 36.746 132.294 29.8129 129.351 23.5993C126.408 17.3859 121.801 12.1464 116.061 8.48123C110.322 4.8162 103.684 2.87345 96.9092 2.87403Z" stroke="var(--svgColor)" strokeWidth="3" />
      <path d="M92.631 28.4721L92.679 32.1201C95.463 32.3121 97.959 32.4081 100.743 32.4081C106.359 32.4081 112.215 32.0241 117.303 31.1601V27.4641C112.455 28.4721 106.551 28.9041 100.791 28.9041C97.959 28.9041 95.367 28.7601 92.631 28.4721ZM109.431 19.8801H105.111C105.255 20.7441 105.303 21.9921 105.303 22.8561C105.303 24.5361 105.351 27.5601 105.351 30.8721C105.351 37.2081 105.975 46.1841 105.975 49.6401C105.975 52.8561 104.823 54.8721 100.791 54.8721C97.287 54.8721 94.935 53.5761 94.935 51.0801C94.935 48.7761 97.431 47.1921 101.031 47.1921C107.127 47.1921 111.975 50.4561 117.015 55.1601L119.079 51.9921C115.047 48.3441 109.431 44.2641 101.367 44.2641C95.031 44.2641 91.479 47.4321 91.479 51.4641C91.479 55.7841 94.983 58.3761 101.415 58.3761C107.895 58.3761 109.719 54.5361 109.719 50.6001C109.719 45.6561 108.999 38.1681 108.999 30.7761C108.999 27.9441 109.047 24.5361 109.143 22.7601C109.191 21.8481 109.287 20.8401 109.431 19.8801ZM85.767 20.2161L81.543 19.8321C81.495 20.8881 81.399 22.1361 81.207 23.2881C80.631 27.2241 78.999 36.3921 78.999 43.4481C78.999 49.9281 79.911 55.2081 80.823 58.6641L84.231 58.3761C84.183 57.8961 84.135 57.1761 84.087 56.6961C84.039 56.1681 84.135 55.2081 84.279 54.5361C84.759 52.1841 86.535 47.2881 87.735 43.9281L85.767 42.3921C84.951 44.3601 83.751 47.2881 82.983 49.4961C82.647 47.1441 82.503 45.1281 82.503 42.7761C82.503 37.4001 83.943 27.8961 84.903 23.4321C85.095 22.5681 85.479 20.9841 85.767 20.2161Z" fill="var(--svgColor)" />
    </g>
    <path d="M38.5897 32.8565L38.5906 32.8565C44.0387 32.8536 49.4153 34.1252 54.3042 36.5739C59.1932 39.0226 63.4642 42.5833 66.7851 46.9819C70.106 51.3807 72.3873 56.4991 73.4506 61.9409C74.5139 67.3828 74.3303 73.0004 72.9142 78.3583C71.4981 83.7161 68.888 88.6689 65.2877 92.8335C61.6875 96.998 57.1941 100.262 52.1566 102.376C47.1192 104.489 41.6717 105.396 36.2358 105.026C30.7998 104.656 25.5193 103.019 20.8033 100.24L19.7776 99.6363L18.9563 100.498C15.3969 104.231 10.8932 104.982 7.13343 104.753C6.00496 104.684 4.95742 104.527 4.04396 104.34C4.94193 103.8 5.91686 103.142 6.85033 102.369C9.51069 100.168 12.2579 96.6637 11.1115 92.0949L11.0336 91.7847L10.836 91.5332C6.65946 86.2181 4.04255 79.8101 3.28937 73.0461C2.53618 66.2821 3.67773 59.4402 6.58119 53.3084C9.48456 47.1769 14.0308 42.0065 19.6935 38.3896C25.3559 34.773 31.9058 32.8559 38.5897 32.8565Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="3" />
    <path d="M39.4375 52.0186L28.1406 83.1201H23.5234L36.5312 48.9951H39.5078L39.4375 52.0186ZM48.9062 83.1201L37.5859 52.0186L37.5156 48.9951H40.4922L53.5469 83.1201H48.9062ZM48.3203 70.4873V74.1904H29.1484V70.4873H48.3203Z" fill="var(--svgColor)" />
    <defs>
      <clipPath id="clip0_4195_11887">
        <rect width="78" height="79" fill="white" transform="matrix(-1 0 0 1 136 0)" />
      </clipPath>
    </defs>
  </svg>
);
export default LanguagesIcon;
