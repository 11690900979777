import React from "react";

const QueryDefinitionIcon = (props) => (
    <svg {...props} className={"icon"} width="73" height="92" viewBox="0 0 73 92" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M69.5076 86.551C69.5078 87.6557 68.6123 88.5514 67.5076 88.5514H5.48828C4.38371 88.5514 3.48828 87.656 3.48828 86.5514V4.51496C3.48828 3.41039 4.38371 2.51495 5.48828 2.51495H46.4918C47.0128 2.51495 47.5132 2.71825 47.8866 3.08158L68.8899 23.5196C69.2767 23.896 69.495 24.4128 69.4951 24.9526L69.5076 86.551Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round"/>
        <path d="M47.3047 2.51495V22.4403C47.3047 22.9926 47.7524 23.4403 48.3047 23.4403H69.0446" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round"/>
        <path d="M13.9996 48.3709C15.2592 48.3709 16.2804 47.3672 16.2804 46.129C16.2804 44.8909 15.2592 43.8871 13.9996 43.8871C12.7399 43.8871 11.7188 44.8909 11.7188 46.129C11.7188 47.3672 12.7399 48.3709 13.9996 48.3709Z" fill="var(--svgColor)"/>
        <path d="M21 46L40 46" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14 57L54 57" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14 67L54 67" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export default QueryDefinitionIcon;
